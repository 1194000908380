import { t } from 'ttag';

import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissions } from '@flexera/lib.permissions';
import { CLOUD__COST } from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useAccessToken } from '@flexera/auth.iam';
import { useEffect } from 'react';
import * as ids from './menuIds';
import { selfServiceHandler } from './selfServiceHandler';

export function useNav(version: number) {
	const authenticated = useAuthenticated();
	const token = useAccessToken();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [hasSelfService, hasSelfServiceLoading] = usePermissions(
		`/orgs/${orgId}`,
		Permissions.hasSelfService
	);

	const description =
		version === 1 ? t`Offer cloud services without the wait.` : null;
	const label = version === 1 ? t`Self Service` : t`Go to Self Service`;

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || hasSelfServiceLoading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!hasSelfService) return;

		return addItems([
			{
				id: ids.EXTERNAL,
				parentId: CLOUD__COST,
				label,
				description,
				category: 'Self Service',
				static: true,
				onClick: () => selfServiceHandler(token),
				external: true,
				priority: 8
			}
		]);
	}, [org, orgId, authenticated, hasSelfServiceLoading, hasSelfService, token]);
}
