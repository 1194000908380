const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Cancel"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "No favorites to display."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "No recent searches"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "No results for this search"
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Search"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Your recent search terms..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "get search results for recent search term:"
        ]
      }
    }
  }
};
export default json;
