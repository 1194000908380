import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import {
	BeaconDetails,
	Beacons,
	BeaconVersions,
	ConfigureBeacon,
	CreateGroups,
	CreateTaskLists,
	EditGroups,
	EditTaskLists,
	Groups,
	ImportDetails,
	ImportInventoryTargets,
	Imports,
	Installers,
	InventoryTargets,
	TaskLists,
	AgentConfigList,
	CreateAgentConfig,
	EditAgentConfig,
	ITVNormalizeTransparency,
	ITVNormalizeStatisticsProvider
} from './UdiApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	useEffect(() => {
		return addRoutes([
			{
				id: 'udi.bootstrap/udi/inventoryTargets',
				parentId: OrgsRouteId,
				path: '/udi/inventoryTargets',
				exact: true,
				component: InventoryTargets
			},
			{
				id: 'udi.bootstrap/udi/inventoryTargets/import',
				parentId: OrgsRouteId,
				path: '/udi/inventoryTargets/import',
				exact: true,
				component: ImportInventoryTargets
			},
			{
				id: 'udi.bootstrap/udi/groups/create',
				parentId: OrgsRouteId,
				path: '/udi/groups/create',
				exact: true,
				component: CreateGroups
			},
			{
				id: 'udi.bootstrap/udi/groups/edit',
				parentId: OrgsRouteId,
				path: '/udi/groups/:group_id',
				exact: true,
				component: EditGroups
			},
			{
				id: 'udi.bootstrap/udi/groups',
				parentId: OrgsRouteId,
				path: '/udi/groups',
				exact: true,
				component: Groups
			},
			{
				id: 'udi.bootstrap/udi/tasklist',
				parentId: OrgsRouteId,
				path: '/udi/tasklist',
				exact: true,
				component: TaskLists
			},
			{
				id: 'udi.bootstrap/udi/tasklist/create',
				parentId: OrgsRouteId,
				path: '/udi/tasklist/create',
				exact: true,
				component: CreateTaskLists
			},
			{
				id: 'udi.bootstrap/udi/tasklist/edit',
				parentId: OrgsRouteId,
				path: '/udi/tasklist/:taskList_id',
				exact: true,
				component: EditTaskLists
			},
			{
				id: 'udi.bootstrap/udi/connections',
				parentId: OrgsRouteId,
				path: '/udi/connections',
				exact: true,
				component: Imports
			},
			{
				id: 'udi.bootstrap/udi/connections/details',
				parentId: OrgsRouteId,
				path: '/udi/connections/:connectionId',
				exact: true,
				component: ImportDetails
			},
			{
				id: 'udi.bootstrap/udi/beacons',
				parentId: OrgsRouteId,
				path: '/udi/beacons',
				exact: true,
				component: Beacons
			},
			{
				id: 'udi.bootstrap/udi/beacons/details',
				parentId: OrgsRouteId,
				path: '/udi/beacons/:beacon_id/:source?',
				exact: true,
				component: BeaconDetails
			},
			{
				id: 'udi.bootstrap/udi/versionHistory',
				parentId: OrgsRouteId,
				path: '/udi/versionHistory',
				exact: false,
				component: BeaconVersions
			},
			{
				id: 'udi.bootstrap/udi/installers',
				parentId: OrgsRouteId,
				path: '/udi/installers',
				exact: false,
				component: Installers
			},
			{
				id: 'udi.bootstrap/udi/agent-configurations',
				parentId: OrgsRouteId,
				path: '/udi/agent-configurations',
				exact: true,
				component: AgentConfigList
			},
			{
				id: 'udi.bootstrap/udi/agent-configurations/create',
				parentId: OrgsRouteId,
				path: '/udi/agent-configurations/create',
				exact: true,
				component: CreateAgentConfig
			},
			{
				id: 'udi.bootstrap/udi/agent-configurations/details',
				parentId: OrgsRouteId,
				path: '/udi/agent-configurations/:agentConfigId',
				exact: true,
				component: EditAgentConfig
			},
			{
				id: 'udi.bootstrap/udi/statistics',
				parentId: OrgsRouteId,
				path: '/udi/statistics',
				exact: false,
				component: ITVNormalizeTransparency
			},
			{
				id: 'udi.bootstrap/udi/normalization-insights',
				parentId: OrgsRouteId,
				path: '/:capability/normalization-insights/:dashboard_name?',
				exact: false,
				component: ITVNormalizeStatisticsProvider
			},
			{
				id: 'udi.bootstrap/udi/configure',
				path: '/beacon/configureBeacon',
				exact: false,
				component: ConfigureBeacon
			}
		]);
	}, []);
}
