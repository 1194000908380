import { FC } from 'react';
import styled from 'styled-components';
import { BaseTheme, Flex } from '@flexera/ui.component-library';

export const CommunityWrapper: FC = styled(Flex)`
	padding: 0;
	width: fit-content;
	margin-right: ${BaseTheme.space.lg};
	margin-left: 94px;
	flex-direction: column;
	min-height: 300px;

	@media screen and (max-width: 1024px) {
		margin-right: 0;
		margin-left: 0;
		margin-top: ${BaseTheme.space.xlg};
	}
`;

export const CommunityBlurb = styled.div`
	width: 164px;
	padding: ${BaseTheme.space.lg};
	background: ${BaseTheme.colors.blue60};
	box-shadow: 0px 5px 50px rgba(40, 124, 200, 0.5);
	backdrop-filter: blur(25px);
	border-radius: 10px;
	cursor: pointer;
	position: relative;
	z-index: 5;
	margin-top: ${BaseTheme.space.lg};
	transition: all 250ms ease-in-out;
	&:hover,
	&:active {
		box-shadow: 0px 5px 50px rgba(40, 124, 200, 0.5);
		background: ${BaseTheme.colors.blue50};
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		width: 132px;
	}
`;

export const CommunityImage = styled.div`
	position: relative;
	flex: 1;
	width: 297px;
	height: 282px;

	svg {
		position: absolute;
		left: -${BaseTheme.space.lg};
	}
	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		margin-top: 74px;
		margin-left: -64px;
	}
`;
