import { VENDOR_URL } from '@flexera/lib.api';
import { fetch } from '@flexera/lib.http';

export interface ComplianceStatuses {
	complianceStatus: string;
	count: number;
}

export interface ExpiringLicenses {
	availableEntitlementCount?: number;
	complianceStatus?: string;
	consumedEntitlementCount: number;
	currentEntitlementConsumptionCount?: number;
	duration?: string;
	expiresAt: string;
	externalId?: string;
	id?: string;
	includesMaintenance?: string;
	limits?: string;
	manufacturer?: string;
	name: string;
	products?: string[];
	purchasedEntitlementCount: number;
	sourceName?: string;
	status?: string;
	type?: string;
	version?: string;
}

export interface ConsumedLicenses {
	licenseName: string;
	purchasedCount: number;
	consumedCount: number;
}

interface SoftwareLicenses {
	complianceStatuses: ComplianceStatuses[];
	expiringLicenses: ExpiringLicenses[];
	overConsumedLicenses: ConsumedLicenses[];
	underConsumedLicenses: ConsumedLicenses[];
}

export async function fetchSoftwareLicensesData(orgId: number, count = 10) {
	return fetch<SoftwareLicenses>(
		`${VENDOR_URL}orgs/${orgId}/software-license-summary?count=${count}`,
		{
			authSchemes: ['IAM'],
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Api-Version': '1.0'
			}
		}
	);
}
