const makeId = (id: string) => `saas.bootstrap${id}`;

export const BASE = makeId('');
export const LOADING = makeId('.loading');
export const DASHBOARD = makeId('/dashboard');

export const MANAGED = makeId('/applications/managed');
export const SALESFORCE_ALL_ORGS = makeId('/applications/managed/salesforce');
export const MANAGED_ADD = makeId('/applications/managed/add');
export const MANAGED_DETAILS = makeId('/applications/managed/details');
export const MANAGED_OVERVIEW = makeId('/applications/managed/overview');
export const LICENSES = makeId('/applications/licenses');
export const USAGE = makeId('/applications/usage');
export const UNSANCTIONED = makeId('/applications/unsanctioned');

export const ALL_USERS = makeId('/users/all');
export const UNDERUTILIZED_USERS = makeId('/users/underutilized');
export const SUSPICIOUS_USERS = makeId('/users/suspicious');
export const ORGANIZATION_USAGE = makeId('users/user-usage');

export const GENERAL_SETTINGS = makeId('/settings/general');
export const ORG_SETTINGS = makeId('/settings/org');
export const AUDIT_SETTINGS = makeId('/settings/audit');
export const APP_SETTINGS = makeId('/settings/app');
export const CUSTOMER_SETTINGS = makeId('/settings/customer');
export const FINANCIAL_SETTINGS = makeId('/settings/financial');
export const SSO_SETTINGS = makeId('/settings/sso');
export const VENDORS_SETTINGS = makeId('/settings/vendors');
