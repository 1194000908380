import styled from 'styled-components';
import { BaseTheme, Flex, Grid, Cell } from '@flexera/ui.component-library';

export const MarketingWrapper = styled(Flex)`
	background: ${BaseTheme.colors.light};
	box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
	margin: 0 ${BaseTheme.space.lg};
	padding: ${BaseTheme.space.lg};
	flex-direction: column;
	border-radius: 10px;
	margin-top: -130px;

	@media screen and (max-width: 1024px) {
		margin-top: -88px;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin: 0 ${BaseTheme.space.md};
		margin-top: -48px;
	}
`;

export const MarketingItemsWrapper = styled(Grid)`
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0;
	grid-column-gap: ${BaseTheme.space.lg};

	@media screen and (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
		grid-row-gap: ${BaseTheme.space.lg};
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: ${BaseTheme.space.md};
	}
`;

export const MarketingItem = styled(Cell)`
	height: auto;
	border-right: ${BaseTheme.borders.sm} ${BaseTheme.colors.blue10};
	padding-right: ${BaseTheme.space.xlg};
	&:last-of-type {
		border-right: none;
		padding-right: 0;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[2]}px) {
		&:nth-of-type(even) {
			padding-right: 0;
			border-right: none;
		}
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		padding-right: 0;
		border-right: none;
		border-bottom: ${BaseTheme.borders.sm} ${BaseTheme.colors.blue10};
		padding-bottom: ${BaseTheme.space.md};
		&:last-of-type {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
`;
