import { t } from 'ttag';

export interface HelpfulLink {
	id: number;
	overrideLinkId: string;
	overrideHideId: string;
	title: string;
	link: string;
}

export const helpfulLinks: HelpfulLink[] = [
	{
		id: 0,
		overrideLinkId: 'marketing-flexera-one-forum-link',
		overrideHideId: 'marketing-flexera-one-forum-hide',
		title: t`Flexera One Forum`,
		link: 'https://community.flexera.com/t5/Flexera-One/ct-p/FlexeraOne'
	},
	{
		id: 1,
		overrideLinkId: 'marketing-learning-center-link',
		overrideHideId: 'marketing-learning-center-hide',
		title: t`Learning Center`,
		link: 'https://learn.flexera.com/'
	},
	{
		id: 2,
		overrideLinkId: 'marketing-submit-ideas-link',
		overrideHideId: 'marketing-submit-ideas-hide',
		title: t`Submit Ideas`,
		link:
			'https://flexeracommunity.force.com/Community/s/login/?ec=302&inst=1M&startURL=%2FCommunity%2Fapex%2Fahaapp__AhaRedirector%3Fstate%3D32699377f638c76cfeb3dd3140137439-6834235340412750291%26return_to%3D%252Fideas'
	},
	{
		id: 3,
		overrideLinkId: 'marketing-product-discovery-link',
		overrideHideId: 'marketing-product-discovery-hide',
		title: t`Product Discovery`,
		link: 'https://community.flexera.com/t5/Discovery/ct-p/Discovery'
	},
	{
		id: 4,
		overrideLinkId: 'marketing-open-new-case-link',
		overrideHideId: 'marketing-open-new-case-hide',
		title: t`Open A New Case`,
		link: 'https://community.flexera.com/t5/forums/postpage/board-id/@support'
	},
	{
		id: 5,
		overrideLinkId: 'marketing-support-help-link',
		overrideHideId: 'marketing-support-help-hide',
		title: t`Support Help`,
		link:
			'https://community.flexera.com/t5/Using-the-Case-Portal/tkb-p/case-portal-help'
	},
	{
		id: 6,
		overrideLinkId: 'marketing-support-plan-link',
		overrideHideId: 'marketing-support-plan-hide',
		title: t`Support Plan Information`,
		link:
			'https://community.flexera.com/t5/Using-the-Case-Portal/Support-Maintenance-Plans/ta-p/168369'
	},
	{
		id: 7,
		overrideLinkId: 'marketing-flexera-com-link',
		overrideHideId: 'marketing-flexera-com-hide',
		title: 'Flexera.com',
		link: 'https://www.flexera.com'
	}
];
