const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.": {
        "msgid": "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.",
        "msgstr": [
          "(Maintenant) les fournisseurs de logiciels doivent réfléchir à deux fois avant de nous effrayer ou de nous intimider lors d'un audit. Flexera nous a fourni les données et accordé la confiance nécessaires pour savoir exactement quels logiciels nous possédons et ce que nous utilisons."
        ]
      },
      "Back to top": {
        "msgid": "Back to top",
        "msgstr": [
          "Retour au début"
        ]
      },
      "Case Studies": {
        "msgid": "Case Studies",
        "msgstr": [
          "Études de cas"
        ]
      },
      "Catch up on the latest news and content.": {
        "msgid": "Catch up on the latest news and content.",
        "msgstr": [
          "Soyez au courant des dernières nouvelles et du contenu le plus récent."
        ]
      },
      "Chief Procurement Officer": {
        "msgid": "Chief Procurement Officer",
        "msgstr": [
          "Chef des achats"
        ]
      },
      "Close": {
        "msgid": "Close",
        "msgstr": [
          "Fermer"
        ]
      },
      "Cloud Cloud Cost Optimization": {
        "msgid": "Cloud Cloud Cost Optimization",
        "msgstr": [
          "Optimisation des coûts du cloud"
        ]
      },
      "Cloud Operations Engineer": {
        "msgid": "Cloud Operations Engineer",
        "msgstr": [
          "Ingénieur des opérations du cloud"
        ]
      },
      "Contact Us": {
        "msgid": "Contact Us",
        "msgstr": [
          "Nous contacter"
        ]
      },
      "Contact Us Link. This link will open in a new window.": {
        "msgid": "Contact Us Link. This link will open in a new window.",
        "msgstr": [
          "Lien pour nous contacter. Ce lien s'ouvrira dans une nouvelle fenêtre."
        ]
      },
      "Data Privacy": {
        "msgid": "Data Privacy",
        "msgstr": [
          "Confidentialité des données"
        ]
      },
      "Data Privacy Link. This link will open in a new window.": {
        "msgid": "Data Privacy Link. This link will open in a new window.",
        "msgstr": [
          "Lien vers la confidentialité des données. Ce lien s'ouvrira dans une nouvelle fenêtre."
        ]
      },
      "Datasheets": {
        "msgid": "Datasheets",
        "msgstr": [
          "Fiches techniques"
        ]
      },
      "Deep dives into the features and benefit of Flexere One solutions.": {
        "msgid": "Deep dives into the features and benefit of Flexere One solutions.",
        "msgstr": [
          "Plongez dans les fonctionnalités et les avantages des solutions Flexere One."
        ]
      },
      "Desktop Services Supervisor": {
        "msgid": "Desktop Services Supervisor",
        "msgstr": [
          "Superviseur des services du bureau"
        ]
      },
      "Examining a range of IT challenges and how Flexera One solves them.": {
        "msgid": "Examining a range of IT challenges and how Flexera One solves them.",
        "msgstr": [
          "Examen d'une série de défis informatiques et de la manière dont Flexera One les résout."
        ]
      },
      "FLEXINSIGHTS": {
        "msgid": "FLEXINSIGHTS",
        "msgstr": [
          "INFORMATIONS FLEXERA"
        ]
      },
      "Find out more": {
        "msgid": "Find out more",
        "msgstr": [
          "En savoir plus"
        ]
      },
      "Flexera One Overview": {
        "msgid": "Flexera One Overview",
        "msgstr": [
          "Aperçu de Flexera One"
        ]
      },
      "Flexera One Solutions": {
        "msgid": "Flexera One Solutions",
        "msgstr": [
          "Solutions Flexera One"
        ]
      },
      "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.": {
        "msgid": "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.",
        "msgstr": [
          "Flexera One vous aide à optimiser la valeur du bureau sur le cloud, en vous fournissant une vue unifiée de votre paysage technologique."
        ]
      },
      "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.": {
        "msgid": "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.",
        "msgstr": [
          "La fonction d'optimisation des coûts du cloud de Flexera One est une solution de gestion et d'optimisation des coûts qui permet aux entreprises de réduire leurs coûts et de garantir leur rendement."
        ]
      },
      "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.": {
        "msgid": "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.",
        "msgstr": [
          "La fonction de demande de biens informatiques de Flexera One vous permet de fournir rapidement des logiciels d'entreprise à vos employés."
        ]
      },
      "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.": {
        "msgid": "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.",
        "msgstr": [
          "IT Visibility de Flexera One vous offre une source unique de données réelles pour l'ensemble de votre parc informatique."
        ]
      },
      "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.": {
        "msgid": "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.",
        "msgstr": [
          "Les fonctions SaaS de Flexera One vous donnent une visibilité et un contrôle sur votre paysage SaaS."
        ]
      },
      "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.": {
        "msgid": "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.",
        "msgstr": [
          "Flexera m'a donné la qualité de données nécessaire pour que je puisse fournir la transparence financière et l'aperçu requis dans un service mondial de gestion du cycle de vie des logiciels."
        ]
      },
      "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.": {
        "msgid": "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.",
        "msgstr": [
          "Flexera nous a permis d'obtenir des informations sur nos dépenses en matière de cloud et d'être plus efficaces dans la réduction du gaspillage."
        ]
      },
      "Flexera is such a flexible tool, allowing high-level views.": {
        "msgid": "Flexera is such a flexible tool, allowing high-level views.",
        "msgstr": [
          "Flexera est un outil très flexible qui permet d'obtenir des vues très générales."
        ]
      },
      "Flexera. All rights reserved.": {
        "msgid": "Flexera. All rights reserved.",
        "msgstr": [
          "Flexera. Tous droits réservés."
        ]
      },
      "Hardware Models": {
        "msgid": "Hardware Models",
        "msgstr": [
          "Modèles de matériel"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Demandes de biens informatiques"
        ]
      },
      "IT Security & Compliance Manager": {
        "msgid": "IT Security & Compliance Manager",
        "msgstr": [
          "Responsable de la sécurité et de la conformité informatique"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.": {
        "msgid": "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.",
        "msgstr": [
          "Cette fonction nous fait gagner un temps précieux... notre équipe a plus de temps pour se concentrer sur nos autres initiatives logicielles stratégiques."
        ]
      },
      "Join The Flexera One Community": {
        "msgid": "Join The Flexera One Community",
        "msgstr": [
          "Rejoignez la communauté Flexera One"
        ]
      },
      "Latest News": {
        "msgid": "Latest News",
        "msgstr": [
          "Dernières nouvelles"
        ]
      },
      "Latest Videos": {
        "msgid": "Latest Videos",
        "msgstr": [
          "Dernières vidéos"
        ]
      },
      "Learn more about Flexera One": {
        "msgid": "Learn more about Flexera One",
        "msgstr": [
          "En savoir plus sur Flexera One"
        ]
      },
      "Lifecycle Dates": {
        "msgid": "Lifecycle Dates",
        "msgstr": [
          "Dates du cycle de vie"
        ]
      },
      "MANUFACTURERS": {
        "msgid": "MANUFACTURERS",
        "msgstr": [
          "FABRICANTS"
        ]
      },
      "MARKETING": {
        "msgid": "MARKETING",
        "msgstr": [
          "MARKETING"
        ]
      },
      "Market Data Points": {
        "msgid": "Market Data Points",
        "msgstr": [
          "Points de données du marché"
        ]
      },
      "Maximize the business value of your IT investments": {
        "msgid": "Maximize the business value of your IT investments",
        "msgstr": [
          "Optimisez la valeur commerciale de vos investissements informatiques"
        ]
      },
      "Our Technopedia IT asset database spans:": {
        "msgid": "Our Technopedia IT asset database spans:",
        "msgstr": [
          "Portée de notre base de données de biens informatiques Technopedia :"
        ]
      },
      "PRODUCTS": {
        "msgid": "PRODUCTS",
        "msgstr": [
          "PRODUITS"
        ]
      },
      "Product Usage Rights": {
        "msgid": "Product Usage Rights",
        "msgstr": [
          "Droits d'utilisation des produits"
        ]
      },
      "Read Testimonials": {
        "msgid": "Read Testimonials",
        "msgstr": [
          "Lire les témoignages"
        ]
      },
      "Read how Flexera One solutions have benefited enterprises like yours.": {
        "msgid": "Read how Flexera One solutions have benefited enterprises like yours.",
        "msgstr": [
          "Découvrez comment les solutions Flexera One ont été bénéfiques pour des entreprises comme la vôtre."
        ]
      },
      "SIGNUP CARD": {
        "msgid": "SIGNUP CARD",
        "msgstr": [
          "CARTE DE SIGNATURE"
        ]
      },
      "SKUs": {
        "msgid": "SKUs",
        "msgstr": [
          "UGS"
        ]
      },
      "SaaS Manager": {
        "msgid": "SaaS Manager",
        "msgstr": [
          "Responsable SaaS"
        ]
      },
      "See Our Data": {
        "msgid": "See Our Data",
        "msgstr": [
          "Voir nos données"
        ]
      },
      "See the impact Flexera One Solutions can have on your IT estate.": {
        "msgid": "See the impact Flexera One Solutions can have on your IT estate.",
        "msgstr": [
          "Découvrez l'impact que les solutions Flexera One peuvent avoir sur votre parc informatique."
        ]
      },
      "Senior Director of Enterprise Data and Systems": {
        "msgid": "Senior Director of Enterprise Data and Systems",
        "msgstr": [
          "Directeur principal des données et systèmes d'entreprise"
        ]
      },
      "Senior Manager, Technical Product": {
        "msgid": "Senior Manager, Technical Product",
        "msgstr": [
          "Directeur principal, produit technique"
        ]
      },
      "Senior Software Contract Manager": {
        "msgid": "Senior Software Contract Manager",
        "msgstr": [
          "Directeur principal des contrats logiciels"
        ]
      },
      "Software Releases": {
        "msgid": "Software Releases",
        "msgstr": [
          "Publications de logiciels"
        ]
      },
      "Solutions Briefs": {
        "msgid": "Solutions Briefs",
        "msgstr": [
          "Fiches de solutions"
        ]
      },
      "TESTIMONIALS": {
        "msgid": "TESTIMONIALS",
        "msgstr": [
          "TÉMOIGNAGES"
        ]
      },
      "Technical Architect/Developer": {
        "msgid": "Technical Architect/Developer",
        "msgstr": [
          "Architecte/développeur technique"
        ]
      },
      "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.": {
        "msgid": "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.",
        "msgstr": [
          "Les outils Flexera... nous ont permis de ne plus avoir à nous casser la tête. Ils nous ont permis de collaborer avec notre partenaire de migration afin de hiérarchiser les applications, de les dimensionner correctement et de les sécuriser sur le cloud."
        ]
      },
      "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.": {
        "msgid": "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.",
        "msgstr": [
          "La possibilité de visualiser tous les logiciels et de suivre l'évaluation de toutes les vulnérabilités et de tous les correctifs en un seul endroit est essentielle pour assurer la conformité au NERC."
        ]
      },
      "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.": {
        "msgid": "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.",
        "msgstr": [
          "L'avantage de Flexera One SaaS Management est que je peux obtenir cette analyse  à la demande relativement rapidement. Je peux l'obtenir aujourd'hui, je peux l'obtenir demain ; cela ne nécessite pas d'extraction de données. Il suffit de pointer et de cliquer pour avoir un aperçu de la situation."
        ]
      },
      "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.": {
        "msgid": "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.",
        "msgstr": [
          "Les solutions Flexera One peuvent vous aider de différentes manières à tirer le meilleur parti de chaque dollar informatique. Découvrez Flexera One et consultez les ressources ci-dessous pour découvrir comment optimiser vos solutions Flexera One."
        ]
      },
      "Tour Flexera One": {
        "msgid": "Tour Flexera One",
        "msgstr": [
          "Découvrir Flexera One"
        ]
      },
      "UPDATES PER DAY": {
        "msgid": "UPDATES PER DAY",
        "msgstr": [
          "MISES À JOUR PAR JOUR"
        ]
      },
      "VIDEO LIBRARY": {
        "msgid": "VIDEO LIBRARY",
        "msgstr": [
          "BIBLIOTHÈQUE DE VIDÉOS"
        ]
      },
      "View More": {
        "msgid": "View More",
        "msgstr": [
          "Afficher plus"
        ]
      },
      "What our customers say": {
        "msgid": "What our customers say",
        "msgstr": [
          "Ce que disent nos clients"
        ]
      }
    }
  }
};
export default json;
