import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';

export const store = createStore({
	initialState: {},
	extensions: [getSagaExtension({})]
});

export const StoreProvider: FC = ({ children }: { children: JSX.Element }) => (
	<Provider store={store}>{children}</Provider>
);
StoreProvider.displayName = 'ReduxStore';
