import { ResponseSchema, ResponseData } from '@flexera/data-visualization.data';
import { Category, fetchCategoryData } from '@flexera/services.vendor';

const getSchema = () => {
	const schema: ResponseSchema[] = [
		{
			name: 'softwareCategory',
			type: 'string'
		},
		{
			name: 'count',
			type: 'number'
		}
	];

	return schema;
};

const reorderCategories = (products: Category[]) => {
	return products.sort((a, b) => {
		return b.count - a.count;
	});
};

export async function getCategoryData(orgId: number): Promise<ResponseData> {
	const response = await fetchCategoryData(orgId);
	if (response.status !== 200) {
		return {
			error: {
				statusCode: response.status.toString(),
				message: `${response.statusText}`
			}
		};
	}
	return {
		data: reorderCategories(response.data),
		schema: getSchema()
	};
}
