import { MenuItem } from '@flexera/shell.navigation';
import {
	IconMenu,
	BaseTheme,
	IconMdMoreHoriz
} from '@flexera/ui.component-library';
import React from 'react';
import { history } from '@flexera/lib.router-history';
import { t } from 'ttag';

export const OverflowBreadcrumbsComponent = ({
	breadcrumbs
}: {
	breadcrumbs: MenuItem[];
}) => {
	return (
		<div className={'breadcrumbs-mini'}>
			<span style={{ margin: '0 0.25rem 0 0' }}>{`${breadcrumbs[0]?.label}`}</span>
			<span style={{ margin: '0 0.25rem 0 0' }}>/</span>
			<IconMenu
				m={`0 ${BaseTheme.space.xxs}`}
				icon={IconMdMoreHoriz}
				variant={'minimal'}
				color={BaseTheme.colors.primary}
				title={'Breadcrumbs'}
				id={'breadcrumbs-menu'}
				options={breadcrumbs
					?.filter(
						(item, index) => !(index === 0 || index === breadcrumbs.length - 1)
					)
					.map((breadcrumb: { label?: string; path?: string; id?: string }) => {
						const { label, path } = breadcrumb;
						return {
							disabled: !path,
							subtitle: label,
							onClick: () => {
								history.push(path);
							}
						};
					})}
			/>
			<span style={{ margin: '0 0.25rem' }}>/</span>
			<span className={'breadcrumb-page-title'}>
				{breadcrumbs[breadcrumbs.length - 1]?.isPageError && (
					<span className={'error'}> {t`Error...`} </span>
				)}
				{!breadcrumbs[breadcrumbs.length - 1]?.isPageError &&
					(breadcrumbs[breadcrumbs.length - 1]?.label || t`Loading...`)}
			</span>
		</div>
	);
};
