import { useEffect, useState } from 'react';
import { useFlagContext } from '@flexera/flexera-one.feature-flags';
import { useUAT } from './useUAT';

export function useUATRestriction(key: string) {
	const [uatRestricted, setUATRestrcted] = useState<boolean>(true);
	const isUAT = useUAT();
	const flagContext = useFlagContext();

	useEffect(() => {
		try {
			const isEnabledForProd = (flagContext as any)[key] as boolean;
			const returnFeatureState = isUAT || isEnabledForProd;
			setUATRestrcted(returnFeatureState);
		} catch (error) {
			console.warn(error);
		}
	}, [isUAT, flagContext]);

	return uatRestricted;
}
