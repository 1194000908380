import styled, { css } from 'styled-components';
import { BaseTheme, Flex } from '@flexera/ui.component-library';

interface MetricProps {
	fontSize?: string;
	fontWeight?: string;
}

interface DataPointProps {
	varient: 'xl' | 'lg' | 'md' | 'sm';
}

export const InsightWrapper = styled(Flex)`
	margin: 5.5rem 4rem;
	align-items: center;
	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		flex-direction: column;
		margin: 5.5rem 1.5rem;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin: 5.5rem 1.5rem;
	}
`;

export const MetricWrapper = styled(Flex)`
	flex-direction: column;
	padding: ${BaseTheme.space.md} 0;
	border-bottom: ${BaseTheme.borders.sm} ${BaseTheme.colors.blue10};
	&:last-of-type {
		border-bottom: none;
	}
`;

export const Metric = styled.span<MetricProps>`
	font-family: ${BaseTheme.fonts.primary};
	font-size: ${({ fontSize }) => fontSize || '3.125rem'};
	color: ${BaseTheme.colors.blue70};
	font-weight: ${({ fontWeight }) => fontWeight || BaseTheme.fontWeights.bold};
	line-height: 2.75rem;
	margin-bottom: ${BaseTheme.space.xs};
	@media screen and (max-width: 1024px) {
		font-size: 25.4495px;
		line-height: 22px;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		font-size: 20px;
		line-height: 16px;
	}
`;

export const MetricTitle = styled.span<MetricProps>`
	font-family: ${BaseTheme.fonts.primary};
	font-size: ${({ fontSize }) => fontSize || BaseTheme.fontSizes.xlg};
	color: ${BaseTheme.colors.blue40};
	font-weight: ${({ fontWeight }) =>
		fontWeight || BaseTheme.fontWeights.semiBold};
	line-height: 2.75rem;
	margin-bottom: ${BaseTheme.space.xs};
	text-align: center;
	@media screen and (max-width: 1024px) {
		font-size: 19.7941px;
		line-height: 22px;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		font-size: 15px;
		line-height: 16px;
	}
`;

export const DataPointsWrapper = styled(Flex)`
	width: 505px;
	height: 505px;
	border: ${BaseTheme.borders.sm} ${BaseTheme.colors.blue10};
	border-radius: 50%;
	margin-right: 5.125rem;
	margin-left: 6rem;
	@media screen and (min-width: ${BaseTheme.breakpoints[4]}px) {
		margin-left: 17.125rem;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[3]}px) {
		margin-left: 66px;
	}

	@media screen and (max-width: 1024px) {
		width: 357px;
		height: 357px;
		margin-left: 0;
		margin-right: 0;
		div#data-point2 {
			margin-left: 170px;
			margin-top: 50px;
		}
		div#data-point3 {
			margin-left: 310px;
			margin-top: 75px;
		}

		div#data-point4 {
			margin-top: 200px;
			margin-left: 20px;
		}

		div#data-point5 {
			margin-top: 320px;
			margin-left: 80px;
		}

		div#data-point6 {
			margin-top: 250px;
			margin-left: 232px;
		}
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		width: 275px;
		height: 275px;
		margin-left: 0;
		margin-right: 0;

		div#data-point2 {
			margin-left: 136px;
			margin-top: 42px;
		}
		div#data-point3 {
			margin-left: 256px;
			margin-top: 75px;
		}

		div#data-point4 {
			margin-top: 200px;
			margin-left: 20px;
		}

		div#data-point5 {
			margin-top: 245px;
			margin-left: 65px;
		}

		div#data-point6 {
			margin-top: 180px;
			margin-left: 194px;
		}
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		margin-left: 0;
		margin-top: 74px;
	}
`;

export const DataPointsContainer = styled(Flex)`
	width: 395px;
	height: 395px;
	background: conic-gradient(
		from 53.31deg at 56.97% 57.01%,
		${BaseTheme.colors.blue10} -74.46deg,
		${BaseTheme.colors.blue25} 75.39deg,
		${BaseTheme.colors.gray10} 226.87deg,
		${BaseTheme.colors.blue10} 285.54deg,
		${BaseTheme.colors.blue25} 435.39deg
	);
	border-radius: 50%;
	margin: auto;

	@media screen and (max-width: 1024px) {
		width: 280px;
		height: 280px;
	}
	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		width: 215px;
		height: 215px;
	}
`;

export const MarketingDataPointsContainer = styled(Flex)`
	width: 285px;
	height: 285px;
	background: ${BaseTheme.colors.light};
	border-radius: 50%;
	margin: auto;

	@media screen and (max-width: 1024px) {
		width: 200px;
		height: 200px;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		width: 150px;
		height: 150px;
	}
`;

export const DataPoints = styled(Flex)<DataPointProps>`
	${({ varient }) => {
		if (varient === 'xl') {
			return css`
				width: 140px;
				height: 140px;
				font-size: 36px;
				line-height: 36px;
				@media screen and (max-width: 1024px) {
					width: 100px;
					height: 100px;
					font-size: 25px;
					line-height: 25px;
				}
				@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
					width: 76px;
					height: 76px;
					font-size: 20px;
					line-height: 20px;
				}
			`;
		}
		if (varient === 'lg') {
			return css`
				width: 125px;
				height: 125px;
				font-size: 36px;
				line-height: 36px;
				@media screen and (max-width: 1024px) {
					width: 88px;
					height: 88px;
					font-size: 25px;
					line-height: 25px;
				}

				@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
					width: 68px;
					height: 68px;
					font-size: 20px;
					line-height: 20px;
				}
			`;
		}
		if (varient === 'md') {
			return css`
				width: 80px;
				height: 80px;
				font-size: 26px;
				line-height: 26px;
				@media screen and (max-width: 1024px) {
					width: 57px;
					height: 57px;
					font-size: 18px;
					line-height: 18px;
				}

				@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
					width: 45px;
					height: 45px;
					font-size: 14px;
					line-height: 14px;
				}
			`;
		}
		if (varient === 'sm') {
			return css`
				width: 75px;
				height: 75px;
				font-size: 24px;
				line-height: 24px;
				@media screen and (max-width: 1024px) {
					width: 53px;
					height: 53px;
					font-size: 14px;
					line-height: 14px;
				}

				@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
					width: 40px;
					height: 40px;
					font-size: 12px;
					line-height: 12px;
				}
			`;
		}
	}};

	background: ${BaseTheme.colors.light};
	box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.08);
	color: ${BaseTheme.colors.text};
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	position: absolute;
	letter-spacing: 0.3px;
	flex-direction: column;
	transition: all 250ms ease-in-out;

	&:hover,
	&:active {
		background: #277bc7;
		box-shadow: 10px 10px 50px rgba(39, 92, 141, 0.25);
		font-weight: bold;
		color: #ffffff;
		${({ varient }) => {
			if (varient === 'xl') {
				return css`
					transform: scale(1.42);
				`;
			}
			if (varient === 'lg') {
				return css`
					transform: scale(1.6);
				`;
			}
			if (varient === 'md') {
				return css`
					transform: scale(2.5);
				`;
			}
			if (varient === 'sm') {
				return css`
					transform: scale(2.66);
				`;
			}
		}};
	}
	div {
		display: flex;
		flex-direction: column;
		justify-items: center;
		align-items: center;
	}

	&:hover div {
		${({ varient }) => {
			if (varient === 'xl') {
				return css`
					transform: scale(0.7);
				`;
			}
			if (varient === 'lg') {
				return css`
					transform: scale(0.625);
				`;
			}
			if (varient === 'md') {
				return css`
					transform: scale(0.4);
				`;
			}
			if (varient === 'sm') {
				return css`
					transform: scale(0.37);
				`;
			}
		}};
	}

	&:hover span.data-title {
		display: block;
	}

	&:hover span.data-point {
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
	}

	span.data-title {
		font-weight: 400;
		font-size: 28px;
		line-height: 32px;
		text-align: center;
		display: none;
	}
`;
