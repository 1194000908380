import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const UserManagement = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).UsersWrapper };
	}),
	'iam'
);

export const GroupManagement = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).GroupsWrapper };
	}),
	'iam'
);

export const AccountManagement = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).AccountsWrapper };
	}),
	'iam'
);

export const Invitations = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).InvitationsPage };
	}),
	'iam'
);

export const IdentityProviders = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).IdentityProvidersWrapper };
	}),
	'iam'
);

export const NewIdentityProvider = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).NewIdentityProvider };
	}),
	'iam'
);

export const IPAccessControl = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/admin.main');
		return { default: (await mod).SafelistWrapper };
	}),
	'iam'
);
