import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavItemChild, useBreadcrumbs } from '@flexera/shell.navigation';
import { SubList } from './Submenu.styled';
import { getChildItems } from '../../utilities';

interface SubmenuProps {
	childItems: NavItemChild[];
	searchIsActive?: boolean;
	setIsActive?: (val: boolean) => void;
	setSearchIsActive?: (val: boolean) => void;
	searchWord?: string;
	setFlyoutOpen?: (val: boolean) => void;
	submenuRef?: React.Ref<HTMLUListElement>;
}

export const Submenu = ({
	childItems,
	searchIsActive,
	setSearchIsActive,
	searchWord,
	setIsActive,
	setFlyoutOpen,
	submenuRef
}: SubmenuProps) => {
	const location = useLocation();
	const breadcrumbs = useBreadcrumbs();

	const hasPeers = childItems.length > 1;
	const submenuContent = childItems.map((child) =>
		getChildItems(
			child,
			breadcrumbs,
			hasPeers,
			searchWord,
			searchIsActive,
			setSearchIsActive,
			setIsActive,
			setFlyoutOpen,
			location?.pathname
		)
	);

	return (
		<SubList
			role={'menu'}
			tabIndex={-1}
			className={searchIsActive ? 'active-search-style' : ''}
			ref={submenuRef}
		>
			{submenuContent.map((item, index) => (
				<React.Fragment key={childItems[index].id || index}>{item}</React.Fragment>
			))}
		</SubList>
	);
};
