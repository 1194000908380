import { ResponseSchema } from '@flexera/data-visualization.data';
import { LicensesClient } from '@flexera/saas.api-client';

const schema: ResponseSchema[] = [
	{ name: 'appId', type: 'string' },
	{ name: 'appName', type: 'string' },
	{ name: 'active', type: 'number' },
	{ name: 'inactive', type: 'number' },
	{ name: 'neverActive', type: 'number' },
	{ name: 'total', type: 'number' }
];

export async function getTopApps(orgId: number) {
	const response = await LicensesClient.getLicenseUsage(orgId);
	const data = response?.values || [];

	// determine the top 5 apps by annual cost
	const topApps = data
		.sort((a, b) => {
			return (b.annualCost || 0) - (a.annualCost || 0);
		})
		.slice(0, 5);

	const result = topApps.map((app) => {
		const active = app.totalActive || 0;
		const inactive = app.totalInactive || 0;
		const neverActive = app.totalNeverActive || 0;
		return {
			appId: app.id,
			appName: app.name,
			active,
			inactive,
			neverActive,
			total: active + inactive + neverActive
		};
	});

	return {
		data: result,
		schema
	};
}
