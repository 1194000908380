import { Flex, Box, H3, P } from '@flexera/ui.component-library';
import React from 'react';
import { t } from 'ttag';
import EmptyStateIcon from './empty_state_icon.svg';

export const NoNav = () => (
	<Flex p={'2rem'}>
		<Box
			textAlign={'center'}
			bg={'light'}
			boxShadow={'small'}
			border={'sm'}
			borderColor={'grey.2'}
			borderRadius={'md'}
			width={'100%'}
		>
			<Flex isContentCentered p={'lg'} bg={'rgba(0,84,159,0.1)'}>
				<EmptyStateIcon />
			</Flex>
			<H3>{t`You do not have access to any products within this organization`}</H3>
			<P>{t`You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.`}</P>
		</Box>
	</Flex>
);
