import { useAuthenticated } from '@flexera/auth.base';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { ORGANIZATION } from '@flexera/shell.base-nav-tree';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { useEffect } from 'react';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const capabilityPath = `/orgs/${orgId}/odm`;

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasODM,
		Permissions.canSeeOdmDomains,
		Permissions.canSeeNormalizationRules,
		Permissions.canCreateNormalizationRules,
		Permissions.canUpdateNormalizationRules,
		Permissions.canDeleteNormalizationRules
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const hasODM = perms.get(Permissions.hasODM);
		const canUseDomainNormalization =
			perms.get(Permissions.canSeeOdmDomains) &&
			perms.get(Permissions.canSeeNormalizationRules) &&
			perms.get(Permissions.canCreateNormalizationRules) &&
			perms.get(Permissions.canUpdateNormalizationRules) &&
			perms.get(Permissions.canDeleteNormalizationRules);

		if (perms && hasODM && canUseDomainNormalization) {
			return addItems([
				{
					id: ids.DOMAIN_NORMALIZATION,
					parentId: ORGANIZATION,
					helpHref: `${helpDocBaseUrl}/Administration/DomainNormalization.htm`,
					label: t`Domain Normalization`,
					path: `${capabilityPath}/domain-normalization`,
					urlMatch: /^\/orgs\/\d+\/odm\/domain-normalization(\/|$|\?)/,
					priority: 20
				}
			]);
		}
	}, [org, orgId, authenticated, perms, loadingPerms]);
}
