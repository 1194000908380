import styled, { css } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import { PinButtonBox } from '../PinButton/PinButton.styled';

interface MenuContentProps {
	flyoutOpen?: boolean;
}
export const MenuContentStyled = styled.ul<MenuContentProps>`
	list-style: none;
	padding: unset;
	display: flex;
	flex-flow: column wrap;
	align-items: flex-start;
	width: 100%;
	margin: 0px;
	&::after {
		display: block;
		margin: 6px auto 0px;
		content: '';
		background: #3b4050;
		height: 1px;
		width: calc(100% - ${BaseTheme.space.lg});
	}

	${({ flyoutOpen }) => {
		if (flyoutOpen) {
			return css`
				div.flyout-drawer {
					transition: left 200ms;
				}
				${PinButtonBox} {
					transition: none;
				}
			`;
		}
	}}
`;
