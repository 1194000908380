const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Adjustment Program"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Billing Centers"
        ]
      },
      "Billing Centers (Beta)": {
        "msgid": "Billing Centers (Beta)",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Billing Centers (Beta)"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "comments": {
          "extracted": "Administration -> Cloud Settings"
        },
        "msgstr": [
          "Billing Configuration"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Budgets"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "comments": {
          "extracted": "Dashboards -> Cloud"
        },
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Beta": {
        "msgid": "Cloud Beta",
        "comments": {
          "extracted": "Dashboards -> Cloud"
        },
        "msgstr": [
          "Cloud Beta"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Cloud Cost Anomalies"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Cloud Migration Planning"
        ]
      },
      "Coming Soon": {
        "msgid": "Coming Soon",
        "comments": {
          "extracted": "Dashboards -> Cloud"
        },
        "msgstr": [
          "Coming Soon"
        ]
      },
      "Custom Tags": {
        "msgid": "Custom Tags",
        "comments": {
          "extracted": "Administration -> Cloud Settings"
        },
        "msgstr": [
          "Custom Tags"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Optimization"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Reserved Instances"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "comments": {
          "extracted": "Cloud -> Cost Optimization"
        },
        "msgstr": [
          "Total Potential Savings"
        ]
      }
    }
  }
};
export default json;
