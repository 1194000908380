import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const FNMSIframe = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/fnms.core');
		return { default: (await mod).FNMSIframeComponent };
	}),
	'fnms'
);
