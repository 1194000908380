import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

/*
	Legacy
	Dashboards -> Cloud
	Cloud -> Cost Optimization -> Billing Centers
	Administration -> Cloud Settings -> Settings
*/
// Billing Centers -> Allocation Rules
export const AllocationRules = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).AllocationRulesPage };
	}),
	'optima'
);

// Dashboards -> Cloud
export const CcoDashboards = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).DashboardsPage };
	}),
	'optima'
);

// Dashboards -> Recommendation sets
export const CreateRecommendationSet = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).CreateRecommendationsSet };
	}),
	'optima'
);

export const RecommendationSets = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).RecommendationSets };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Reserved Instances
// Cloud -> Commitments -> Reserved Instances
export const CcoReservedInstances = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).ReservedInstancesPage };
	}),
	'optima'
);

// Cloud -> Commitments -> Savings Plans
export const CcoSavingsPlans = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).SavingsPlansPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Billing Centers
export const CcoBillingCenters = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).BillingCentersPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Tabular View
export const CcoTabularView = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).TabularViewPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Adjustment Program
export const CcoProgramAdjustments = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).AdjustmentProgramPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Budget
export const CcoBudgets = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).BudgetsListPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Budget
export const OptimaBudgetOverview = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).BudgetOverviewPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cost Planning
export const CcoCostPlanning = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).PlansListPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cost Planning -> New
export const CcoCostPlanningNew = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).NewPlanPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cost Planning -> {Plan Detail}
export const CcoCostPlanningDetail = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).PlanDetailPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Anomaly
export const CcoAnomalies = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).AnomaliesOverviewPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cloud -> Optimization -> Dashboard
export const CcoOptimizationDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).DashboardPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cloud -> Optimization -> Recommendations
export const CcoOptimizationRecommendations = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).RecommendationsPage };
	}),
	'optima'
);

// Cloud -> Cost Optimization -> Cloud Migration Planning
export const CcoCloudMigration = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).CloudMigrationPage };
	}),
	'optima'
);

// Administration -> Cloud Settings -> Tag Dimensions
export const CcoTagDimensions = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).CustomTagsPage };
	}),
	'optima'
);

// Administration -> Cloud Settings -> Billing Configuration
export const CcoBillingConfig = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).BillingConfigPage };
	}),
	'optima'
);

// Administration -> Cloud Settings -> Bill Processing
export const CcoBillProcessing = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).BillProcessingPage };
	}),
	'optima'
);

export const RuleBasedDimensions = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/optima.main');
		return { default: (await mod).RuleBasedDimensions };
	}),
	'optima'
);
