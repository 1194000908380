import { useNavItems } from '@flexera/shell.navigation';
import { useEffect } from 'react';
import { useAuthenticated } from '@flexera/auth.base';
import { items } from './items';

export function useBaseNav() {
	const { addItems } = useNavItems();
	const authenticated = useAuthenticated();

	useEffect(() => {
		if (!authenticated) return;

		return addItems(items);
	}, [authenticated]);
}
