import { VENDOR_URL } from '@flexera/lib.api';
import { fetch } from '@flexera/lib.http';

export interface Product {
	productName: string;
	count: number;
}

export async function fetchProductData(orgId: number) {
	return fetch<Product[]>(`${VENDOR_URL}orgs/${orgId}/product-summary`, {
		authSchemes: ['IAM'],
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Api-Version': '1.0'
		}
	});
}
