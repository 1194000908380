import React, { FC, useEffect } from 'react';
import { TrackJS, TrackJSErrorPayload } from 'trackjs';
import { getCookie } from '@flexera/lib.utilities';
import { useUserId } from '@flexera/auth.base';
import { Environment, environment } from '@flexera/lib.environment';
import { sanitizers } from './sanitizers';

const application = {
	[Environment.Local]: null,
	[Environment.Dev]: null,
	[Environment.Staging]: 'fusion---staging',
	[Environment.Production]: 'fusion---production',
	[Environment.EUProduction]: 'fusion-production-eu',
	[Environment.AUProduction]: 'fusion-production-au'
}[environment];

if (application) {
	TrackJS.install({
		token: 'bab2a7dd35364db5aabad7dfff2ceb6b',
		application,
		onError(payload: TrackJSErrorPayload) {
			sanitizers.forEach((sanitize) => {
				sanitize(payload);
			});

			const regex = /\/orgs\/(\d+)\/([\w-]+)/;
			const pathString = window.location.pathname;
			const match = regex.exec(pathString);
			let orgId: string;
			let capability: string;

			if (match) {
				[, orgId, capability] = match;

				payload.metadata.push({
					key: 'orgId',
					value: orgId
				});

				payload.metadata.push({
					key: 'capability',
					value: capability
				});
			}

			return true;
		}
	});
}

export const TrackJs: FC = () => {
	const branch = getCookie('x-flexera-branch') || 'master';
	const userId = useUserId();

	useEffect(() => {
		if (userId && application) {
			TrackJS.addMetadata('userId', userId.toString());
		}
	}, [userId]);

	if (application) {
		TrackJS.addMetadata('x-flexera-branch', branch);
	}

	return <></>;
};
