import { Environment, environment } from '@flexera/lib.environment';

enum ConsumerEnvironment {
	Local = 'local',
	Dev = 'dev',
	Staging = 'staging',
	Production = 'production',
	NonFusion = 'NonFusion'
}

interface EnvConstObject {
	[key: string]: string;
}

const SS_FORM_ACTION_VALUES: EnvConstObject = {
	[ConsumerEnvironment.Local]: 'https://login.test.rightscale.com/set-cookie',
	[ConsumerEnvironment.Dev]: 'https://login.test.rightscale.com/set-cookie',
	[ConsumerEnvironment.Staging]: 'https://login.test.rightscale.com/set-cookie',
	[ConsumerEnvironment.Production]: 'https://login.rightscale.com/set-cookie'
};

const SS_DOMAIN_VALUES: EnvConstObject = {
	[ConsumerEnvironment.Local]: 'test.rightscale.com',
	[ConsumerEnvironment.Dev]: 'test.rightscale.com',
	[ConsumerEnvironment.Staging]: 'test.rightscale.com',
	[ConsumerEnvironment.Production]: 'rightscale.com'
};

const SS_REDIRECT_URI_VALUES: EnvConstObject = {
	[ConsumerEnvironment.Local]: 'https://selfservice-93.test.rightscale.com',
	[ConsumerEnvironment.Dev]: 'https://selfservice-93.test.rightscale.com',
	[ConsumerEnvironment.Staging]: 'https://selfservice-93.test.rightscale.com',
	[ConsumerEnvironment.Production]: 'https://selfservice-3.rightscale.com'
};

const buildRedirectUri = (redirectUriInputValue: string) => {
	return `${redirectUriInputValue}/manager?fromFusion=true&flow_return_to=%3FfromFusion%3Dtrue`;
};

const setAttributes = (
	el: HTMLElement,
	attrs: {
		method?: string;
		target?: string;
		encType?: string;
		class?: string;
		action?: string;
		type?: string;
		name?: string;
		value?: string;
	}
) => {
	for (const key in attrs) {
		if (attrs[key]) {
			el.setAttribute(key, attrs[key]);
		}
	}
};

const buildDOMElement = (
	name: string,
	description: string,
	formAction: string,
	domainInputValue: string,
	redirectUriInputValue: string,
	oktaAccessToken: string,
	orgId?: string
) => {
	const form = document.createElement('form');
	setAttributes(form, {
		method: 'POST',
		target: '_blank',
		encType: 'application/x-www-form-urlencoded',
		class: 'self-service-form',
		action: formAction
	});
	const oktaTokenInput = document.createElement('input');
	setAttributes(oktaTokenInput, {
		type: 'hidden',
		name: 'oktaToken',
		value: oktaAccessToken
	});
	const domainInput = document.createElement('input');
	setAttributes(domainInput, {
		type: 'hidden',
		name: 'domain',
		value: domainInputValue
	});
	const redirectUriInput = document.createElement('input');
	setAttributes(redirectUriInput, {
		type: 'hidden',
		name: 'redirectUri',
		value: buildRedirectUri(redirectUriInputValue)
	});

	// we may not always need to attach an orgId field to the form
	// it will depend on the capability that is being linked to
	if (orgId) {
		const orgIdInput = document.createElement('input');
		setAttributes(orgIdInput, {
			type: 'hidden',
			name: 'orgId',
			value: orgId
		});
		form.appendChild(orgIdInput);
	}

	const submitButton = document.createElement('button');
	submitButton.className = '-form-button';
	const buttonTitle = document.createElement('p');
	const buttonDescription = document.createElement('p');
	buttonTitle.className = '-item-title';
	buttonTitle.innerHTML = name;
	buttonDescription.className = 'item-description';
	buttonDescription.innerHTML = description;
	submitButton.appendChild(buttonTitle);
	submitButton.appendChild(buttonDescription);
	form.appendChild(oktaTokenInput);
	form.appendChild(domainInput);
	form.appendChild(redirectUriInput);
	form.appendChild(submitButton);

	return form;
};

export const selfServiceHandler = (oktaAccessToken: string) => {
	const env =
		(Environment[environment].toLowerCase() as ConsumerEnvironment) ||
		ConsumerEnvironment.Production;
	const name = 'Self Service';
	const description = 'Offer cloud services without the wait.';
	const formAction = SS_FORM_ACTION_VALUES[env];
	const redirectUriInputValue = SS_REDIRECT_URI_VALUES[env];
	const domainInputValue = SS_DOMAIN_VALUES[env];

	const form = buildDOMElement(
		name,
		description,
		formAction,
		domainInputValue,
		redirectUriInputValue,
		oktaAccessToken,
		null
	);
	document.body.append(form);
	form.submit();
	form.parentElement.removeChild(form);

	return false;
};
