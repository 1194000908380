const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.": {
        "msgid": "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.",
        "msgstr": [
          "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use."
        ]
      },
      "Back to top": {
        "msgid": "Back to top",
        "msgstr": [
          "Back to top"
        ]
      },
      "Case Studies": {
        "msgid": "Case Studies",
        "msgstr": [
          "Case Studies"
        ]
      },
      "Catch up on the latest news and content.": {
        "msgid": "Catch up on the latest news and content.",
        "msgstr": [
          "Catch up on the latest news and content."
        ]
      },
      "Chief Procurement Officer": {
        "msgid": "Chief Procurement Officer",
        "msgstr": [
          "Chief Procurement Officer"
        ]
      },
      "Close": {
        "msgid": "Close",
        "msgstr": [
          "Close"
        ]
      },
      "Cloud Cloud Cost Optimization": {
        "msgid": "Cloud Cloud Cost Optimization",
        "msgstr": [
          "Cloud Cloud Cost Optimization"
        ]
      },
      "Cloud Operations Engineer": {
        "msgid": "Cloud Operations Engineer",
        "msgstr": [
          "Cloud Operations Engineer"
        ]
      },
      "Contact Us": {
        "msgid": "Contact Us",
        "msgstr": [
          "Contact Us"
        ]
      },
      "Contact Us Link. This link will open in a new window.": {
        "msgid": "Contact Us Link. This link will open in a new window.",
        "msgstr": [
          "Contact Us Link. This link will open in a new window."
        ]
      },
      "Data Privacy": {
        "msgid": "Data Privacy",
        "msgstr": [
          "Data Privacy"
        ]
      },
      "Data Privacy Link. This link will open in a new window.": {
        "msgid": "Data Privacy Link. This link will open in a new window.",
        "msgstr": [
          "Data Privacy Link. This link will open in a new window."
        ]
      },
      "Datasheets": {
        "msgid": "Datasheets",
        "msgstr": [
          "Datasheets"
        ]
      },
      "Deep dives into the features and benefit of Flexere One solutions.": {
        "msgid": "Deep dives into the features and benefit of Flexere One solutions.",
        "msgstr": [
          "Deep dives into the features and benefit of Flexere One solutions."
        ]
      },
      "Desktop Services Supervisor": {
        "msgid": "Desktop Services Supervisor",
        "msgstr": [
          "Desktop Services Supervisor"
        ]
      },
      "Examining a range of IT challenges and how Flexera One solves them.": {
        "msgid": "Examining a range of IT challenges and how Flexera One solves them.",
        "msgstr": [
          "Examining a range of IT challenges and how Flexera One solves them."
        ]
      },
      "FLEXINSIGHTS": {
        "msgid": "FLEXINSIGHTS",
        "msgstr": [
          "FLEXINSIGHTS"
        ]
      },
      "Find out more": {
        "msgid": "Find out more",
        "msgstr": [
          "Find out more"
        ]
      },
      "Flexera One Overview": {
        "msgid": "Flexera One Overview",
        "msgstr": [
          "Flexera One Overview"
        ]
      },
      "Flexera One Solutions": {
        "msgid": "Flexera One Solutions",
        "msgstr": [
          "Flexera One Solutions"
        ]
      },
      "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.": {
        "msgid": "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.",
        "msgstr": [
          "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape."
        ]
      },
      "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.": {
        "msgid": "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.",
        "msgstr": [
          "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies."
        ]
      },
      "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.": {
        "msgid": "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.",
        "msgstr": [
          "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees."
        ]
      },
      "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.": {
        "msgid": "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.",
        "msgstr": [
          "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate."
        ]
      },
      "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.": {
        "msgid": "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.",
        "msgstr": [
          "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape."
        ]
      },
      "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.": {
        "msgid": "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.",
        "msgstr": [
          "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department."
        ]
      },
      "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.": {
        "msgid": "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.",
        "msgstr": [
          "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste."
        ]
      },
      "Flexera is such a flexible tool, allowing high-level views.": {
        "msgid": "Flexera is such a flexible tool, allowing high-level views.",
        "msgstr": [
          "Flexera is such a flexible tool, allowing high-level views."
        ]
      },
      "Flexera. All rights reserved.": {
        "msgid": "Flexera. All rights reserved.",
        "msgstr": [
          "Flexera. All rights reserved."
        ]
      },
      "Hardware Models": {
        "msgid": "Hardware Models",
        "msgstr": [
          "Hardware Models"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "IT Security & Compliance Manager": {
        "msgid": "IT Security & Compliance Manager",
        "msgstr": [
          "IT Security & Compliance Manager"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.": {
        "msgid": "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.",
        "msgstr": [
          "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives."
        ]
      },
      "Join The Flexera One Community": {
        "msgid": "Join The Flexera One Community",
        "msgstr": [
          "Join The Flexera One Community"
        ]
      },
      "Latest News": {
        "msgid": "Latest News",
        "msgstr": [
          "Latest News"
        ]
      },
      "Latest Videos": {
        "msgid": "Latest Videos",
        "msgstr": [
          "Latest Videos"
        ]
      },
      "Learn more about Flexera One": {
        "msgid": "Learn more about Flexera One",
        "msgstr": [
          "Learn more about Flexera One"
        ]
      },
      "Lifecycle Dates": {
        "msgid": "Lifecycle Dates",
        "msgstr": [
          "Lifecycle Dates"
        ]
      },
      "MANUFACTURERS": {
        "msgid": "MANUFACTURERS",
        "msgstr": [
          "MANUFACTURERS"
        ]
      },
      "MARKETING": {
        "msgid": "MARKETING",
        "msgstr": [
          "MARKETING"
        ]
      },
      "Market Data Points": {
        "msgid": "Market Data Points",
        "msgstr": [
          "Market Data Points"
        ]
      },
      "Maximize the business value of your IT investments": {
        "msgid": "Maximize the business value of your IT investments",
        "msgstr": [
          "Maximize the business value of your IT investments"
        ]
      },
      "Our Technopedia IT asset database spans:": {
        "msgid": "Our Technopedia IT asset database spans:",
        "msgstr": [
          "Our Technopedia IT asset database spans:"
        ]
      },
      "PRODUCTS": {
        "msgid": "PRODUCTS",
        "msgstr": [
          "PRODUCTS"
        ]
      },
      "Product Usage Rights": {
        "msgid": "Product Usage Rights",
        "msgstr": [
          "Product Usage Rights"
        ]
      },
      "Read Testimonials": {
        "msgid": "Read Testimonials",
        "msgstr": [
          "Read Testimonials"
        ]
      },
      "Read how Flexera One solutions have benefited enterprises like yours.": {
        "msgid": "Read how Flexera One solutions have benefited enterprises like yours.",
        "msgstr": [
          "Read how Flexera One solutions have benefited enterprises like yours."
        ]
      },
      "SIGNUP CARD": {
        "msgid": "SIGNUP CARD",
        "msgstr": [
          "SIGNUP CARD"
        ]
      },
      "SKUs": {
        "msgid": "SKUs",
        "msgstr": [
          "SKUs"
        ]
      },
      "SaaS Manager": {
        "msgid": "SaaS Manager",
        "msgstr": [
          "SaaS Manager"
        ]
      },
      "See Our Data": {
        "msgid": "See Our Data",
        "msgstr": [
          "See Our Data"
        ]
      },
      "See the impact Flexera One Solutions can have on your IT estate.": {
        "msgid": "See the impact Flexera One Solutions can have on your IT estate.",
        "msgstr": [
          "See the impact Flexera One Solutions can have on your IT estate."
        ]
      },
      "Senior Director of Enterprise Data and Systems": {
        "msgid": "Senior Director of Enterprise Data and Systems",
        "msgstr": [
          "Senior Director of Enterprise Data and Systems"
        ]
      },
      "Senior Manager, Technical Product": {
        "msgid": "Senior Manager, Technical Product",
        "msgstr": [
          "Senior Manager, Technical Product"
        ]
      },
      "Senior Software Contract Manager": {
        "msgid": "Senior Software Contract Manager",
        "msgstr": [
          "Senior Software Contract Manager"
        ]
      },
      "Software Releases": {
        "msgid": "Software Releases",
        "msgstr": [
          "Software Releases"
        ]
      },
      "Solutions Briefs": {
        "msgid": "Solutions Briefs",
        "msgstr": [
          "Solutions Briefs"
        ]
      },
      "TESTIMONIALS": {
        "msgid": "TESTIMONIALS",
        "msgstr": [
          "TESTIMONIALS"
        ]
      },
      "Technical Architect/Developer": {
        "msgid": "Technical Architect/Developer",
        "msgstr": [
          "Technical Architect/Developer"
        ]
      },
      "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.": {
        "msgid": "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.",
        "msgstr": [
          "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud."
        ]
      },
      "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.": {
        "msgid": "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.",
        "msgstr": [
          "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance."
        ]
      },
      "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.": {
        "msgid": "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.",
        "msgstr": [
          "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight."
        ]
      },
      "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.": {
        "msgid": "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.",
        "msgstr": [
          "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions."
        ]
      },
      "Tour Flexera One": {
        "msgid": "Tour Flexera One",
        "msgstr": [
          "Tour Flexera One"
        ]
      },
      "UPDATES PER DAY": {
        "msgid": "UPDATES PER DAY",
        "msgstr": [
          "UPDATES PER DAY"
        ]
      },
      "VIDEO LIBRARY": {
        "msgid": "VIDEO LIBRARY",
        "msgstr": [
          "VIDEO LIBRARY"
        ]
      },
      "View More": {
        "msgid": "View More",
        "msgstr": [
          "View More"
        ]
      },
      "What our customers say": {
        "msgid": "What our customers say",
        "msgstr": [
          "What our customers say"
        ]
      }
    }
  }
};
export default json;
