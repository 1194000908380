import React from 'react';
import { Link } from 'react-router-dom';

import {
	useAuthorizedPolicy,
	WhiteLabeledNavLogoLarge,
	WhiteLabeledNavLogoSmall,
	useLogoFlags
} from '@flexera/shell.whitelabel';

import { NavigationContext } from '../navigationContext';
import { XLogo, FleLogo, EraLogo } from '../../assets';

const FlexeraFullLogo = () => (
	<Link className={'flexera-logo'} to={'/'}>
		<span className={'expanded-logo'}>
			<img src={FleLogo} alt={'Flexera logo'} />
		</span>
		<img src={XLogo} alt={'Flexera logo'} />
		<span className={'expanded-logo'}>
			<img src={EraLogo} alt={'Flexera logo'} />
		</span>
	</Link>
);

const OnlyLargeWhiteLabeledLogo = ({ imageUrl }: { imageUrl: string }) => {
	const { isExpanded } = React.useContext(NavigationContext);
	return (
		<>
			{isExpanded && (
				<Link className={'whitelabel-logo'} to={'/'}>
					<WhiteLabeledNavLogoLarge src={imageUrl} />
				</Link>
			)}
			{!isExpanded && (
				<Link className={'flexera-logo'} to={'/'}>
					<img src={XLogo} alt={'Flexera logo'} />
				</Link>
			)}
		</>
	);
};

const OnlySmallWhiteLabeledLogo = ({ imageUrl }: { imageUrl: string }) => {
	const { isExpanded } = React.useContext(NavigationContext);
	return (
		<>
			{isExpanded && <FlexeraFullLogo />}
			{!isExpanded && (
				<Link className={'whitelabel-logo'} to={'/'}>
					<WhiteLabeledNavLogoSmall src={imageUrl} />
				</Link>
			)}
		</>
	);
};

const WhiteLabeledBothLogos = ({
	imageUrlLarge,
	imageUrlSmall
}: {
	imageUrlLarge: string;
	imageUrlSmall: string;
}) => {
	const { isExpanded } = React.useContext(NavigationContext);
	return (
		<Link className={'whitelabel-logo'} to={'/'}>
			{isExpanded && <WhiteLabeledNavLogoLarge src={imageUrlLarge} />}
			{!isExpanded && <WhiteLabeledNavLogoSmall src={imageUrlSmall} />}
		</Link>
	);
};

export const Logo = () => {
	const { authorizedPolicy } = useAuthorizedPolicy();
	const {
		noWhiteLabeledLogo,
		whiteLabeledBothLogos,
		onlyWhiteLabeledLargeLogo,
		onlyWhiteLabeledSmallLogo
	} = useLogoFlags();

	return (
		<>
			{whiteLabeledBothLogos && (
				<WhiteLabeledBothLogos
					imageUrlLarge={authorizedPolicy?.navLogo}
					imageUrlSmall={authorizedPolicy?.navLogoSmall}
				/>
			)}
			{noWhiteLabeledLogo && <FlexeraFullLogo />}
			{onlyWhiteLabeledLargeLogo && (
				<OnlyLargeWhiteLabeledLogo imageUrl={authorizedPolicy?.navLogo} />
			)}
			{onlyWhiteLabeledSmallLogo && (
				<OnlySmallWhiteLabeledLogo imageUrl={authorizedPolicy?.navLogoSmall} />
			)}
		</>
	);
};
