import { lazyLoaded } from '@flexera/lib.utilities';

export const SettingsInvitations = lazyLoaded(async () => {
	const mod = import('@flexera/settings.main');
	return { default: (await mod).UserInvitations };
});

export const SettingsAPICreds = lazyLoaded(async () => {
	const mod = import('@flexera/settings.main');
	return { default: (await mod).ApiCredentialsPage };
});
