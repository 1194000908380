const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Clear the branch and go back to the production build?": {
        "msgid": "Clear the branch and go back to the production build?",
        "msgstr": [
          "¿Borrar la sucursal y volver a la compilación de producción?"
        ]
      },
      "Click here to get help via our Support Center.": {
        "msgid": "Click here to get help via our Support Center.",
        "msgstr": [
          "Haga clic aquí para obtener ayuda de nuestro centro de soporte técnico. "
        ]
      },
      "Error...": {
        "msgid": "Error...",
        "msgstr": [
          "Error..."
        ]
      },
      "Loading...": {
        "msgid": "Loading...",
        "msgstr": [
          "Cargando..."
        ]
      },
      "Log out": {
        "msgid": "Log out",
        "msgstr": [
          "Cerrar sesión"
        ]
      },
      "Organizations": {
        "msgid": "Organizations",
        "msgstr": [
          "Organizaciones"
        ]
      },
      "Privacy Policy": {
        "msgid": "Privacy Policy",
        "msgstr": [
          "Política de privacidad"
        ]
      },
      "Profile details": {
        "msgid": "Profile details",
        "msgstr": [
          "Detalles del perfil"
        ]
      },
      "Select an Organization": {
        "msgid": "Select an Organization",
        "msgstr": [
          "Seleccionar una organización"
        ]
      },
      "User Settings": {
        "msgid": "User Settings",
        "msgstr": [
          "Configuración de usuario"
        ]
      },
      "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?": {
        "msgid": "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?",
        "msgstr": [
          "Está ejecutando una versión de Flexera One con marca blanca dentro del alcance del socio ${ 0 }. ¿Desea borrar esta configuración y volver a la compilación de producción principal?"
        ]
      }
    }
  }
};
export default json;
