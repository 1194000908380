import React from 'react';

export const addHighlightAroundSearchTerm = (
	label: string,
	searchWord: string
) => {
	if (searchWord && label.toLowerCase().includes(searchWord.toLowerCase())) {
		const indexOfInstance = label.toLowerCase().indexOf(searchWord.toLowerCase());
		const indexOfLastChar = indexOfInstance + searchWord.length;
		const firstBit = label.slice(0, indexOfInstance);
		const match = (
			<span className={'matched-search'}>
				{label.slice(indexOfInstance, indexOfLastChar)}
			</span>
		);
		const lastBit = label.slice(indexOfLastChar);
		return (
			<>
				{firstBit}
				{match}
				{lastBit}
			</>
		);
	}

	return label;
};
