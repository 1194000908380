import { VENDOR_URL } from '@flexera/lib.api';
import { fetch } from '@flexera/lib.http';

export interface OperatingSystem {
	operatingSystem: string;
	count: number;
}

export async function fetchOperatingSystemData(orgId: number) {
	return fetch<OperatingSystem[]>(
		`${VENDOR_URL}orgs/${orgId}/operating-system-summary`,
		{
			authSchemes: ['IAM'],
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Api-Version': '1.0'
			}
		}
	);
}
