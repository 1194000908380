import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const SbomBucketsGrid = withCapability(
	lazyLoaded(async () => {
		const mod = await import('@flexera/sbom-management.main');
		return { default: mod.BucketsWrapper };
	}),
	'sbom'
);

export const SbomPartsGrid = withCapability(
	lazyLoaded(async () => {
		const mod = await import('@flexera/sbom-management.main');
		return { default: mod.PartsWrapper };
	}),
	'sbom'
);

export const SbomJobsGrid = withCapability(
	lazyLoaded(async () => {
		const mod = await import('@flexera/sbom-management.main');
		return { default: mod.JobsWrapper };
	}),
	'sbom'
);

export const SbomPreferences = withCapability(
	lazyLoaded(async () => {
		const mod = await import('@flexera/sbom-management.main');
		return { default: mod.PreferencesPage };
	}),
	'sbom'
);
