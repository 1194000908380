import React from 'react';
import { t } from 'ttag';
import { recentSearchText, recentSearchLinkDescription } from '../../constants';
import { RecentSearchList, RecentSearchLink } from './RecentSearches.styled';
import { getUniqueKey, toggleEnterKey } from '../../../utilities';
import { NoResultsMessage } from '../Search.styled';

interface recentSearchesProps {
	recentSearches: string[];
	setSearchWord: React.Dispatch<React.SetStateAction<string>>;
}

export const RecentSearches = ({
	recentSearches,
	setSearchWord
}: recentSearchesProps) => {
	if (!recentSearches) {
		return <NoResultsMessage>{t`No recent searches`}</NoResultsMessage>;
	}
	return (
		<RecentSearchList role={'listbox'} aria-label={recentSearchLinkDescription}>
			{recentSearchText}
			{recentSearches &&
				recentSearches.length > 0 &&
				recentSearches.map((term) => (
					<li key={getUniqueKey(term)}>
						<RecentSearchLink
							key={getUniqueKey(`${term}-link`)}
							onClick={() => setSearchWord(term)}
							onKeyDown={(e) => toggleEnterKey(e, setSearchWord, term)}
							title={`${recentSearchLinkDescription} ${term}`}
							aria-label={`${recentSearchLinkDescription} ${term}`}
							tabIndex={0}
							role={'option'}
						>
							{term}
						</RecentSearchLink>
					</li>
				))}
		</RecentSearchList>
	);
};
