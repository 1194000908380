import { t } from 'ttag';

export const documentationItems = [
	{
		id: 0,
		title: t`IT Visibility`,
		link: 'https://docs.flexera.com/flexera/EN/ITVisibility/ITVisibilityGS.htm',
		icon: 'IconMdITV',
		hasPermissionCheck: 'hasITVisibility'
	},
	{
		id: 1,
		title: t`IT Asset Management`,
		link:
			'https://docs.flexera.com/flexera/EN/ITAssets/GettingStartedITAssets.htm',
		icon: 'IconMdITManagement',
		hasPermissionCheck: 'hasFNMS'
	},
	{
		id: 2,
		title: t`Inventory Beacons`,
		link: 'https://docs.flexera.com/flexera/EN/Beacons/GetStartBeacons.htm',
		icon: 'IconFxBeacon',
		hasPermissionCheck: 'hasITVisibility'
	},
	{
		id: 3,
		title: t`SaaS`,
		link: 'https://docs.flexera.com/flexera/EN/SaaSManager/GettingStarted.htm',
		icon: 'IconMdSaas',
		hasPermissionCheck: 'hasSaasManager'
	},
	{
		id: 4,
		title: t`Cloud`,
		link: 'https://docs.flexera.com/flexera/EN/Optima/GettingStarted.htm',
		icon: 'IconMdCloud',
		hasPermissionCheck: 'hasOptima'
	},
	{
		id: 5,
		title: t`IT Asset Requests`,
		link:
			'https://docs.flexera.com/flexera/EN/ITAssetRequests/GettingStarted.htm',
		icon: 'IconMdAssetRequests',
		hasPermissionCheck: 'hasAppBroker'
	},
	{
		id: 6,
		title: t`Technology Spend`,
		link:
			'https://docs.flexera.com/flexera/EN/UnifiedSpendAnalytics/spenddata.htm',
		icon: 'IconMdTechSpend',
		hasPermissionCheck: 'hasSpendAnalytics'
	},
	{
		id: 7,
		title: t`Automation`,
		link: 'https://docs.flexera.com/flexera/EN/Automation/AutomationGS.htm',
		icon: 'IconMdPolicies',
		hasPermissionCheck: 'hasPolicies'
	},
	{
		id: 8,
		title: t`Using Flexera One APIs`,
		link: 'https://docs.flexera.com/flexera/EN/FlexeraAPI/GetStartedAPI.htm',
		icon: 'IconMdAPI'
	},
	{
		id: 9,
		title: t`Administration`,
		link:
			'https://docs.flexera.com/flexera/EN/Administration/AdminGettingStarted.htm',
		icon: 'IconMdSettings'
	}
];
