import ssRegularEot from './SourceSansPro-Regular.eot';
import ssRegularTtf from './SourceSansPro-Regular.ttf';
import ssRegularWoff from './SourceSansPro-Regular.woff';
import ssRegularWoff2 from './SourceSansPro-Regular.woff2';

export const SourceSansRegular = `
  @font-face {
    font-family: 'Source Sans Pro';
    src: url(${ssRegularEot});
    src: url(${ssRegularTtf}) format('truetype'),
         url(${ssRegularWoff}) format('woff'),
         url(${ssRegularWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
`;
