
import { TTag } from 'ttag';
import { resolveLanguage } from '@flexera/lib.i18n';
import de from './translation_de.po';
import en from './translation_en.po';
import es from './translation_es.po';
import fr from './translation_fr.po';

const instance = new TTag();

instance.setDefaultLang('en');
instance.addLocale('de', de);
instance.addLocale('en', en);
instance.addLocale('es', es);
instance.addLocale('fr', fr);
instance.useLocale(resolveLanguage(["en","de","en","es","fr"]))

module.exports = instance;
