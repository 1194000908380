import { t } from 'ttag';
import { img1, img2, img3, img4, img5, img6, img7, img8 } from './logos';

export const TestimonialsData = [
	{
		id: 1,
		testimonial: t`The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.`,
		userName: 'John Wagenleitner',
		title: t`Technical Architect/Developer`,
		logo: img1.default
	},
	{
		id: 2,
		testimonial: t`Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.`,
		userName: 'Melanie Nash',
		title: t`Senior Software Contract Manager`,
		logo: img2.default
	},
	{
		id: 3,
		testimonial: t`It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.`,
		userName: 'Mary McDonald',
		title: t`Desktop Services Supervisor`,
		logo: img3.default
	},
	{
		id: 4,
		testimonial: t`Flexera is such a flexible tool, allowing high-level views.`,
		userName: 'Houston Hutchinson',
		title: t`Cloud Operations Engineer`,
		logo: img4.default
	},
	{
		id: 5,
		testimonial: t`Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.`,
		userName: 'JayKay Selvaraj',
		title: t`Senior Manager, Technical Product`,
		logo: img5.default
	},
	{
		id: 6,
		testimonial: t`(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.`,
		userName: 'Donna Trowbridge',
		title: t`Chief Procurement Officer`,
		logo: img6.default
	},
	{
		id: 7,
		testimonial: t`The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.`,
		userName: 'Curtis Chong ',
		title: t`Senior Director of Enterprise Data and Systems`,
		logo: img7.default
	},
	{
		id: 8,
		testimonial: t`The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.`,
		userName: 'Lisa Capristo',
		title: t`IT Security & Compliance Manager`,
		logo: img8.default
	}
];
