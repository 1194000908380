import React from 'react';
import { Box, P, H5, Button } from '@flexera/ui.component-library';
import { t } from 'ttag';
import {
	SummaryWrapper,
	SummaryTitle,
	SignupCard,
	SignupCardTitle,
	SignupCardContent,
	SummaryContent,
	SummaryIllustrations,
	SummaryIllustrationWrapper
} from './Styled';
import {
	IllustrationIcon,
	DataIcon,
	VideosIcon,
	TestimonialsIcon
} from '../assets';

interface Props {
	onClick: (
		area: 'tour-flexera' | 'testimonials' | 'video-playlist' | 'insights'
	) => void;
}

export const Summary = ({ onClick }: Props) => {
	return (
		<SummaryWrapper>
			<SummaryContent>
				<SummaryTitle>
					{t`Maximize the business value of your IT investments`}
				</SummaryTitle>
				<P mt={'md'} variant={'large'}>
					{t`There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.`}
				</P>
				<Button
					id={'tour-flexera'}
					type={'button'}
					title={'Tour Flexera One'}
					onClick={() => onClick('tour-flexera')}
				>
					{t`Tour Flexera One`}
				</Button>
			</SummaryContent>

			<SummaryIllustrations flexBasis={'50%'}>
				<SummaryIllustrationWrapper
					id={'testimonial'}
					title={'testimonial'}
					width={'166px'}
					height={'113px'}
					tabIndex={0}
					onClick={() => onClick('testimonials')}
					svgMargin={'0 1.5rem -1rem'}
					className={'testimonial'}
				>
					<H5 color={'light'} m={'1.5rem 1.5rem 0.5rem'}>
						{t`Read Testimonials`}
					</H5>
					<TestimonialsIcon />
				</SummaryIllustrationWrapper>

				<SummaryIllustrationWrapper
					id={'video'}
					title={'video'}
					onClick={() => onClick('video-playlist')}
					marginTop={'345px'}
					width={'188px'}
					height={'154px'}
					tabIndex={0}
					className={'video'}
				>
					<H5 color={'light'} m={'1.5rem 1.5rem 0.5rem'}>
						{t`Latest Videos`}
					</H5>
					<VideosIcon />
				</SummaryIllustrationWrapper>
				<SignupCard
					id={'community'}
					title={'community'}
					position={'absolute'}
					onClick={() => {
						const communityTab = window.open(
							'https://community.flexera.com',
							'_blank'
						);
						communityTab.focus();
					}}
				>
					<SignupCardTitle>{t`SIGNUP CARD`}</SignupCardTitle>
					<SignupCardContent>{t`Join The Flexera One Community`}</SignupCardContent>
				</SignupCard>
				<Box mt={'lg'} className={'illustration'}>
					<IllustrationIcon />
				</Box>
				<SummaryIllustrationWrapper
					id={'data'}
					title={'data'}
					onClick={() => onClick('insights')}
					marginTop={'270px'}
					marginLeft={'460px'}
					width={'154px'}
					height={'168px'}
					tabIndex={0}
					className={'data'}
				>
					<H5 color={'light'} m={'1.5rem 1.5rem 0.5rem'}>
						{t`See Our Data`}
					</H5>
					<DataIcon />
				</SummaryIllustrationWrapper>
			</SummaryIllustrations>
		</SummaryWrapper>
	);
};
