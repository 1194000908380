import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import {
	useCcoRecommendationSet,
	useCloudCostPlanning
} from '@flexera/flexera-one.feature-flags';
import { useEffect } from 'react';

import {
	CcoAnomalies,
	CcoBillingConfig,
	CcoBillProcessing,
	CcoBudgets,
	OptimaBudgetOverview,
	CcoCostPlanning,
	CcoCostPlanningDetail,
	CcoCloudMigration,
	CcoTagDimensions,
	CcoDashboards,
	CcoOptimizationDashboard,
	CcoOptimizationRecommendations,
	CcoProgramAdjustments,
	CcoReservedInstances,
	CcoBillingCenters,
	CcoTabularView,
	RuleBasedDimensions,
	CcoCostPlanningNew,
	CreateRecommendationSet,
	RecommendationSets,
	CcoSavingsPlans
} from './OptimaApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	// Recommendation sets feature flag
	const canUseRecommendationSets = useCcoRecommendationSet();
	const hasCloudCostPlanning = useCloudCostPlanning();

	const ccoRoutes = [
		{
			// Redirect from legacy Dashboards -> Cloud
			id: 'optima.bootstrap/dashboard',
			parentId: OrgsRouteId,
			path: '/optima/dashboard/:legacyDashboardId?',
			exact: true,
			component: CcoDashboards
		},
		{
			// Dashboards -> Cloud
			id: 'optima.bootstrap/dashboards',
			parentId: OrgsRouteId,
			path: '/optima/dashboards',
			exact: false,
			component: CcoDashboards
		},
		{
			// Cloud -> Cost Optimization -> Dashboards or Tabular View -> Cost Anomalies
			id: 'optima.bootstrap/anomalies',
			parentId: OrgsRouteId,
			path: '/optima/anomalies',
			exact: true,
			component: CcoAnomalies
		},
		{
			// Cloud -> Cost Optimization -> Billing Centers
			id: 'optima.bootstrap/billing-centers',
			parentId: OrgsRouteId,
			path: '/optima/billing/:view?',
			exact: true,
			component: CcoBillingCenters
		},
		{
			// Cloud -> Cost Optimization -> Billing Center Detail
			id: 'optima.bootstrap/billing-centers-child',
			parentId: OrgsRouteId,
			path: '/optima/billing/billing-centers/:billingCenterId?/:view?',
			exact: true,
			component: CcoBillingCenters
		},
		{
			// Cloud -> Cost Optimization -> Tabular View
			id: 'optima.bootstrap/tabular-view',
			parentId: OrgsRouteId,
			path: '/optima/tabular-view',
			exact: true,
			component: CcoTabularView
		},
		{
			// Cloud -> Cost Optimization  -> Reserved Instances
			id: 'optima.bootstrap/reserved-instances',
			parentId: OrgsRouteId,
			path: '/optima/reserved-instances/:cloudId',
			exact: true,
			component: CcoReservedInstances
		},
		{
			// Cloud -> Commitments -> Savings Plans
			id: 'optima.bootstrap/savings-plans',
			parentId: OrgsRouteId,
			path: '/optima/savings-plans/:cloudId',
			exact: true,
			component: CcoSavingsPlans
		},
		{
			// Cloud -> Cost Optimization -> Adjustment Programs
			id: 'optima.bootstrap/adjustments',
			parentId: OrgsRouteId,
			path: '/optima/adjustments',
			exact: true,
			component: CcoProgramAdjustments
		},
		{
			// Cloud -> Cost Optimization ->  Optimization
			id: 'optima.bootstrap/optimization',
			parentId: OrgsRouteId,
			path: '/optima/optimization',
			exact: true,
			component: CcoOptimizationDashboard
		},
		{
			// Cloud -> Cost Optimization ->  Optimization -> Recommendations
			id: 'optima.bootstrap/optimization/total-potential-savings',
			parentId: OrgsRouteId,
			path: '/optima/optimization/total-potential-savings',
			exact: false,
			component: CcoOptimizationRecommendations
		},
		{
			// Cloud -> Cost Optimization -> Budgets
			id: 'optima.bootstrap/budgets',
			parentId: OrgsRouteId,
			path: '/optima/budgets',
			exact: true,
			component: hasCloudCostPlanning ? CcoCostPlanning : CcoBudgets
		},
		{
			// Cloud -> Cost Optimization -> Budgets -> new
			id: 'optima.bootstrap/budgets/new-plan',
			parentId: OrgsRouteId,
			path: '/optima/budgets/new-plan',
			exact: true,
			component: CcoCostPlanningNew
		},
		{
			id: 'optima.bootstrap/budgets/:budgetId',
			parentId: OrgsRouteId,
			path: '/optima/budgets/:budgetId',
			exact: true,
			component: hasCloudCostPlanning
				? CcoCostPlanningDetail
				: OptimaBudgetOverview
		},
		{
			// Cloud -> Cost Optimization -> Cloud Migration Planning
			id: 'optima.bootstrap/cloud-migration-planning',
			parentId: OrgsRouteId,
			path: '/optima/cloud-migration-planning',
			exact: true,
			component: CcoCloudMigration
		},
		{
			// Adminstration -> Cloud Settings -> Tag Dimensions
			id: 'optima.bootstrap/tag-dimensions',
			parentId: OrgsRouteId,
			path: '/optima/cloud-settings/tag-dimensions',
			exact: true,
			component: CcoTagDimensions
		},
		{
			// Adminstration -> Cloud Settings -> Billing Configuration
			id: 'optima.bootstrap/billing-config',
			parentId: OrgsRouteId,
			path: '/optima/cloud-settings/billing-config/:billId?/:tenants?',
			exact: true,
			component: CcoBillingConfig
		},
		{
			// Adminstration -> Cloud Settings -> Bill Processing
			id: 'optima.bootstrap/bill-processing',
			parentId: OrgsRouteId,
			path: '/optima/cloud-settings/bill-processing',
			exact: true,
			component: CcoBillProcessing
		},
		{
			// Adminstration -> Cloud Settings -> Rule Based Dimensions
			id: 'optima.bootstrap/admin/rule-based-dimensions',
			parentId: OrgsRouteId,
			path: '/optima/cloud-settings/rule-based-dimensions',
			exact: true,
			component: RuleBasedDimensions
		}
	];
	if (canUseRecommendationSets) {
		// Adminstration -> Cloud Settings -> Recommendation sets
		ccoRoutes.push(
			{
				id: 'optima.bootstrap/recommendation-set/create',
				parentId: OrgsRouteId,
				path: '/optima/create-recommendation-set',
				exact: true,
				component: CreateRecommendationSet
			},
			{
				id: 'optima.bootstrap/optima/recommendation-sets',
				parentId: OrgsRouteId,
				path: '/optima/recommendation-sets',
				exact: true,
				component: RecommendationSets
			}
		);
	}

	useEffect(() => {
		return addRoutes(ccoRoutes);
	}, [canUseRecommendationSets]);
}
