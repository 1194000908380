import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { AllPurchases } from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	// TODO: fill out routes with actual paths and components
	useEffect(() => {
		return addRoutes([
			{
				id: 'purchases.bootstrap/page',
				parentId: OrgsRouteId,
				path: '/purchases/dashboard',
				exact: true,
				component: AllPurchases
			}
		]);
	}, []);
}
