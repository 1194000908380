import { generateNavigationMenuFromSavedFavorites } from './generateNavigationMenuFromSavedFavorites';
import { defaultFavoritesMenu } from '../src/constants';

export const getFavoritesMenu = (items, savedFavorites, setFavoritesMenu) => {
	const favesMenu = generateNavigationMenuFromSavedFavorites(
		items,
		savedFavorites
	);
	setFavoritesMenu(favesMenu.length > 0 ? favesMenu : defaultFavoritesMenu);
};
