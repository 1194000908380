import { BaseTheme } from '@flexera/ui.component-library';

const AdditionsToTheme = {
	colors: {
		...BaseTheme.colors,
		lightGrey: '#E6E6E6'
	}
};

export const theme = {
	...BaseTheme,
	...AdditionsToTheme
};
