const makeId = (id: string) => `admin.bootstrap${id}`;

export const IDENTITY_MANAGEMENT_CATEGORY = makeId(
	'/identity-management-category'
);
export const IT_ASSET_MANAGEMENT_SETTINGS_CATEGORY = makeId(
	'/it-asset-management-settings-category'
);
export const SAAS_SETTINGS_CATEGORY = makeId('/saas-settings-category');
export const CLOUD_SETTINGS_CATEGORY = makeId('/cloud-settings-category');

export const LOADING = makeId('.loading');
export const BASE = makeId('');
export const INVITATIONS = makeId('/invitations');
export const USER_MANAGEMENT = makeId('/user-management');
export const GROUP_MANAGEMENT = makeId('/group-management');
export const ACCOUNT_MANAGEMENT = makeId('/account-management');
export const IDPS = makeId('/idps');
export const IPSAFELIST = makeId('/ipsafelist');
