import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { Workspace, WorkspacesLayout } from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'workspaces.bootstrap/workspaces/view',
				parentId: OrgsRouteId,
				path: '/workspaces/:workspace_id',
				exact: true,
				component: Workspace
			},
			{
				id: 'workspaces.bootstrap/workspaces/view/tab',
				parentId: OrgsRouteId,
				path: '/workspaces/:workspace_id/:tab_id',
				exact: true,
				component: Workspace
			},
			{
				id: 'workspaces.bootstrap/workspaces',
				parentId: OrgsRouteId,
				path: '/workspaces',
				exact: true,
				component: WorkspacesLayout
			}
		]);
	}, []);
}
