import React, { useState, useRef, useEffect } from 'react';
import { useBreadcrumbs } from '@flexera/shell.navigation';
import { HeaderBreadcrumbsStyled } from '../styles/HeaderBreadcrumbs';
import {
	OverflowBreadcrumbsComponent,
	BreadcrumbsComponent
} from './Breadcrumbs';

export const Breadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs();
	const [windowWidth, setWindowWidth] = useState(null);
	const [showOverflow, setShowOverflow] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		function onResize() {
			if (ref?.current && breadcrumbs && breadcrumbs.length > 0) {
				if (!windowWidth) {
					if (
						ref.current.clientWidth < ref.current.scrollWidth &&
						breadcrumbs.length > 2
					) {
						setWindowWidth(ref.current.scrollWidth);
						setShowOverflow(true);
					}
				} else if (
					ref.current.clientWidth < windowWidth &&
					breadcrumbs.length > 2
				) {
					setShowOverflow(true);
				} else {
					setShowOverflow(false);
				}
			}
		}
		window.addEventListener('resize', onResize);
		onResize();
		return () => window.removeEventListener('resize', onResize);
	}, [breadcrumbs, ref]);

	if (!breadcrumbs) return null;

	return (
		<HeaderBreadcrumbsStyled>
			<div ref={ref} style={{ width: '100%' }}>
				{showOverflow ? (
					<OverflowBreadcrumbsComponent breadcrumbs={breadcrumbs} />
				) : (
					<BreadcrumbsComponent breadcrumbs={breadcrumbs} />
				)}
			</div>
		</HeaderBreadcrumbsStyled>
	);
};
