import { HeaderEnvironmentIndicator } from '@flexera/ui.component-library';
import { t } from 'ttag';
import React from 'react';

// Get the value of the x-flexera-branch cookie, if present.
// The regular expression comes from MDN so it ought to be good...
export const branchName =
	document.cookie.match('(^|;)\\s*x-flexera-branch\\s*=\\s*([^;]+)')?.pop() ||
	'';

function clearCookie(domain: string) {
	return `x-flexera-branch=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}`;
}

function clearBranch() {
	if (
		// eslint-disable-next-line no-alert
		!window.confirm(t`Clear the branch and go back to the production build?`)
	) {
		return;
	}
	// First, clear the cookie...
	document.cookie = clearCookie(window.location.hostname);
	document.cookie = clearCookie(window.location.hostname.replace('app.', '')); // This is for legacy cookies
	// Then, reload the app. Go to the root because it's possible we're on a page that doesn't exist in the other branch.
	window.location.href = `https://${window.location.host}`;
}
const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
	if (e.key === 'Enter') clearBranch();
};

export const BranchIndicator = () => {
	return (
		<>
			{branchName && (
				<HeaderEnvironmentIndicator
					id={'branch-indicator'}
					environment={branchName}
					onClick={clearBranch}
					onKeyPress={onKeyPress}
				/>
			)}
		</>
	);
};
