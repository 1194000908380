import React from 'react';
import { PlaceholderProps, Icon, Label } from './Placeholder.styled';
import { NavigationContext } from '../navigationContext';

export const Placeholder = ({ seed }: PlaceholderProps) => {
	const { isExpanded } = React.useContext(NavigationContext);
	return (
		<>
			<Icon isExpanded={isExpanded} />
			{isExpanded && <Label seed={seed} />}
		</>
	);
};
