import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import * as Pages from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'automation.bootstrap/dashboards-org',
				parentId: OrgsRouteId,
				path: '/automation/dashboards',
				exact: true,
				component: Pages.Dashboards
			},
			{
				id: 'automation.bootstrap/dashboards-project',
				parentId: OrgsRouteId,
				path: '/automation/dashboards/projects/:project',
				exact: true,
				component: Pages.Dashboards
			},
			{
				id: 'automation.bootstrap/catalog',
				parentId: OrgsRouteId,
				path: '/automation/catalog',
				exact: true,
				component: Pages.Catalog
			},
			{
				id: 'automation.bootstrap/policy-project-incidents',
				parentId: OrgsRouteId,
				path: '/automation/incidents/projects/:project',
				exact: false,
				component: Pages.Incidents
			},
			{
				id: 'automation.bootstrap/policy-org-incidents',
				parentId: OrgsRouteId,
				path: '/automation/incidents',
				exact: false,
				component: Pages.Incidents
			},
			{
				id: 'automation.bootstrap/applied-policies-org',
				parentId: OrgsRouteId,
				path: '/automation/applied-policies',
				exact: true,
				component: Pages.AppliedPolicies
			},
			{
				id: 'automation.bootstrap/applied-policies-project',
				parentId: OrgsRouteId,
				path: '/automation/applied-policies/projects/:project',
				exact: true,
				component: Pages.AppliedPolicies
			},
			{
				id: 'automation.bootstrap/policy-templates',
				parentId: OrgsRouteId,
				path: '/automation/policy-templates',
				exact: true,
				component: Pages.Templates
			},
			{
				id: 'automation.bootstrap/policy-templates-project',
				parentId: OrgsRouteId,
				path: '/automation/projects/:project/policy-templates/:templateId?',
				exact: true,
				component: Pages.Templates
			},
			{
				id: 'automation.bootstrap/policy-credentials',
				parentId: OrgsRouteId,
				path: '/automation/credentials',
				exact: true,
				component: Pages.Credentials
			},
			{
				id: 'automation.bootstrap/policy-credentials-project',
				parentId: OrgsRouteId,
				path: '/automation/projects/:project/credentials/:credentialId?',
				exact: true,
				component: Pages.Credentials
			}
		]);
	}, []);
}
