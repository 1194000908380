const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Catalog": {
        "msgid": "Catalog",
        "msgstr": [
          "Catálogo"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Conexiones"
        ]
      },
      "Edit Catalog Form": {
        "msgid": "Edit Catalog Form",
        "msgstr": [
          "Editar formulario de catálogo"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Solicitudes de activos de TI"
        ]
      },
      "Manage Catalog": {
        "msgid": "Manage Catalog",
        "msgstr": [
          "Administrar catálogo"
        ]
      },
      "My Requests": {
        "msgid": "My Requests",
        "msgstr": [
          "Mis solicitudes"
        ]
      },
      "New Catalog Form": {
        "msgid": "New Catalog Form",
        "msgstr": [
          "Nuevo formulario de catálogo"
        ]
      },
      "Requests": {
        "msgid": "Requests",
        "msgstr": [
          "Solicitudes"
        ]
      }
    }
  }
};
export default json;
