import { useWhitelabelPolicy } from '@flexera/shell.whitelabel';
import { useEffect, useState } from 'react';
import { helpfulLinks, HelpfulLink } from '../../data';

export const useHelpfulLinks = () => {
	const [links, setLinks] = useState([]);
	const { authorizedCustomizations } = useWhitelabelPolicy();
	useEffect(() => {
		if (authorizedCustomizations) {
			const updatedLinks = (helpfulLinks as []).flatMap((link: HelpfulLink) => {
				const hideLink = authorizedCustomizations?.find(
					(customization) => link.overrideHideId === customization.id
				);
				if (hideLink && hideLink.booleanValue) {
					return [];
				}
				const overrideLink = authorizedCustomizations?.find(
					(customization) => link.overrideLinkId === customization.id
				);
				if (overrideLink && overrideLink.urlValue) {
					const newLink = {
						...link,
						link: overrideLink.urlValue
					};
					return newLink;
				}
				return link;
			});
			setLinks(updatedLinks);
		} else {
			setLinks(helpfulLinks);
		}
	}, [authorizedCustomizations]);

	return links;
};
