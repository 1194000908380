import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { InternalAdmin, OrgDetails, SearchResultsGrid } from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'internal-admin.bootstrap',
				parentId: OrgsRouteId,
				path: '/internal-admin/',
				exact: true,
				component: InternalAdmin
			},
			{
				id: 'internal-admin.bootstrap/search-results',
				parentId: OrgsRouteId,
				path: '/internal-admin/search/:searchTerm',
				exact: true,
				component: SearchResultsGrid
			},
			{
				id: 'internal-admin.bootstrap/org-details',
				parentId: OrgsRouteId,
				path: '/internal-admin/orgs/:id',
				exact: true,
				component: OrgDetails
			}
		]);
	}, []);
}
