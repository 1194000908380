const makeId = (id: string) => `automation${id}`;

export const LOADING = makeId('.loading');
export const ROOT = makeId('/root');
export const APPLIEDPOLICIES = makeId('/applied-policies');
export const DASHBOARDS = makeId('/dashboards');
export const CATALOG = makeId('/catalog');
export const POLICY_TEMPLATES = makeId('/templates');
export const INCIDENTS = makeId('/incidents');
export const CREDENTIALS = makeId('/credentials');
