import React from 'react';
import { Flex, Link } from '@flexera/ui.component-library';
import { t } from 'ttag';
import {
	Permissions,
	Permission,
	PermissionSummary
} from '@flexera/lib.permissions';
import { SubTitle } from '../../Styled';
import { documentationItems } from '../../data';

interface PermissionKey {
	[key: string]: Permission;
}

interface DocumentationProps {
	permsEnabled: boolean;
	perms: PermissionSummary;
}

export const DocumentationLibrary = ({
	permsEnabled,
	perms
}: DocumentationProps) => {
	return (
		<Flex
			flexDirection={'column'}
			pl={[0, 0, 'lg']}
			pr={'lg'}
			minWidth={'264px'}
			mb={'2rem'}
			pb={'2rem'}
		>
			<SubTitle>{t`Documentation Library`}</SubTitle>
			{documentationItems.map((item) => {
				if (
					(item.hasPermissionCheck &&
						perms.get((Permissions as PermissionKey)[item.hasPermissionCheck])) ||
					!item.hasPermissionCheck ||
					!permsEnabled
				) {
					return (
						<Link
							key={item.id}
							title={item.title}
							id={item.title.toLowerCase().split(' ').join('-')}
							href={item.link}
							target={'_blank'}
							showExtIcon={false}
							mb={'xxs'}
							lineHeight={'lg'}
						>
							{item.title}
						</Link>
					);
				}

				return null;
			})}
		</Flex>
	);
};
