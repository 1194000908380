/**
 * Scrolls div horizontally
 * @param id - element id of the div
 * @param direction - right or left
 * @param callback - function to call when scrolling finished
 */
export const scrollCarousel = (
	id: string,
	direction: 'left' | 'right',
	callback: (data: { isLeft: boolean; isRight: boolean }) => void
): void => {
	let isScrolling: number = null;
	const carousel = document.getElementById(id);
	const currentOffset = carousel.scrollLeft;
	const defaultScrollValue = id === 'get-started-carousel' ? 364 : 224;

	const scrollListener = () => {
		if (isScrolling) {
			window.clearTimeout(isScrolling);
		}

		isScrolling = setTimeout(() => {
			const newOffset = carousel.scrollLeft;
			const { scrollWidth, clientWidth } = carousel;
			callback({
				isLeft: newOffset === 0,
				isRight: scrollWidth - newOffset - clientWidth === 0
			});
		}, 50);
	};

	carousel.removeEventListener('scroll', scrollListener);
	carousel.addEventListener('scroll', scrollListener, false);

	if (direction === 'right') {
		carousel.scrollTo({
			left: currentOffset + defaultScrollValue,
			behavior: 'smooth'
		});
	} else {
		carousel.scrollTo({
			left: currentOffset - defaultScrollValue,
			behavior: 'smooth'
		});
	}
};

/**
 * Checks to see if the carousel is overflowing
 * the current client width
 * @param id - id of the carousel to check
 * @returns
 */
export const isCarouselOverflown = (id: string): boolean => {
	const carousel = document.getElementById(id);
	return carousel
		? carousel.scrollHeight > carousel.clientHeight ||
				carousel.scrollWidth > carousel.clientWidth
		: false;
};
