import styled from 'styled-components';
import { theme } from './theme';

export const HeaderBreadcrumbsStyled = styled.ul`
	display: flex;
	font-size: ${theme.fontSizes.xs};
	line-height: ${theme.lineHeights.md};
	padding-left: 0;
	color: ${theme.colors.text};

	li {
		align-items: center;
		display: flex;
		list-style: none;

		a {
			text-decoration: none;
			margin: 0 ${theme.space.xxs};
			color: ${theme.colors.primary};
			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}

		&:first-child a {
			margin-left: 0;
		}
	}

	span.error {
		color: ${theme.colors.error};
	}

	span.breadcrumb-page-title {
		font-weight: ${theme.fontWeights.semiBold};
	}

	.breadcrumbs-mini {
		display: flex;
		align-items: center;
	}

	.breadcrumbs {
		display: -webkit-box;
	}
`;
