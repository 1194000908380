import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { Contracts } from './ContractsApp';
import * as ids from './menuIds';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: ids.DASHBOARD,
				parentId: OrgsRouteId,
				path: '/contracts/dashboard',
				exact: true,
				component: Contracts
			},
			{
				id: ids.NEW,
				parentId: OrgsRouteId,
				path: '/contracts/new',
				exact: true,
				component: Contracts
			},
			{
				id: ids.DETAILS,
				parentId: OrgsRouteId,
				path: '/contracts/:contractId',
				exact: true,
				component: Contracts
			}
		]);
	}, []);
}
