const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Catalog": {
        "msgid": "Catalog",
        "msgstr": [
          "Catalogue"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Connexions"
        ]
      },
      "Edit Catalog Form": {
        "msgid": "Edit Catalog Form",
        "msgstr": [
          "Formulaire de modification du catalogue"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Demandes de biens informatiques"
        ]
      },
      "Manage Catalog": {
        "msgid": "Manage Catalog",
        "msgstr": [
          "Gérer le catalogue"
        ]
      },
      "My Requests": {
        "msgid": "My Requests",
        "msgstr": [
          "Mes demandes"
        ]
      },
      "New Catalog Form": {
        "msgid": "New Catalog Form",
        "msgstr": [
          "Formulaire du nouveau catalogue"
        ]
      },
      "Requests": {
        "msgid": "Requests",
        "msgstr": [
          "Demandes"
        ]
      }
    }
  }
};
export default json;
