import {
	useDataSourceItems,
	FilterOption
} from '@flexera/data-visualization.data';
import { UsageMetricFilter } from '@flexera/saas.api-client';
import { getSaasMetrics, SaasMetricsFilter } from './getSaasMetrics';
import { getManagedApps, SaasManagedAppsFilter } from './getManagedApps';
import { getAppUsage } from './getAppUsage';
import { getTopApps } from './getTopApps';
import { getTopDepts } from './getTopDepts';
import { getRenewals } from './getRenewals';
import { getUsageHistory } from './getUsageHistory';

export const useDataSources = () => {
	const { addDataSources } = useDataSourceItems();
	return addDataSources([
		{
			id: 'saas.metrics',
			name: 'SaaS Metrics',
			getData: (
				orgId: number,
				filterOption: FilterOption,
				filter: SaasMetricsFilter
			) => {
				return getSaasMetrics(orgId, filterOption, filter);
			}
		},
		{
			id: 'saas.apps',
			name: 'SaaS Managed Apps',
			getData: (
				orgId: number,
				filterOption: FilterOption,
				filter: SaasManagedAppsFilter
			) => {
				return getManagedApps(orgId, filterOption, filter);
			}
		},
		{
			id: 'saas.app-usage',
			name: 'SaaS Managed App Usage',
			getData: (orgId: number) => {
				return getAppUsage(orgId);
			}
		},
		{
			id: 'saas.top-apps',
			name: 'Top 5 SaaS Managed Apps',
			getData: (orgId: number) => {
				return getTopApps(orgId);
			}
		},
		{
			id: 'saas.top-depts',
			name: 'Top 12 SaaS Departments',
			getData: (orgId: number) => {
				return getTopDepts(orgId);
			}
		},
		{
			id: 'saas.upcoming-renewals',
			name: 'Saas Upcoming Renewals',
			getData: (orgId: number) => {
				return getRenewals(orgId);
			}
		},
		{
			id: 'saas.usage-group-history',
			name: 'Usage Over Time',
			getData: (
				orgId: number,
				filterOption: FilterOption,
				filter: UsageMetricFilter
			) => {
				return getUsageHistory(orgId, filterOption, filter);
			}
		}
	]);
};
