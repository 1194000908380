import {
	ResponseSchema,
	ResponseData,
	FilterOption
} from '@flexera/data-visualization.data';
import {
	fetchSoftwareLicensesData,
	ExpiringLicenses,
	ComplianceStatuses,
	ConsumedLicenses
} from '@flexera/services.vendor';

// ComplianceLicense charts
const complianceSchema: ResponseSchema[] = [
	{
		name: 'complianceStatus',
		type: 'string'
	},
	{
		name: 'count',
		type: 'number'
	}
];

// Under or Over Consumed License charts
const consumedLicensesSchema: ResponseSchema[] = [
	{
		name: 'licenseName',
		type: 'string'
	},
	{
		name: 'purchasedCount',
		type: 'number'
	},
	{
		name: 'consumedCount',
		type: 'number'
	}
];

// Top Expiring License table
const expiringLicensesSchema: ResponseSchema[] = [
	{
		name: 'name',
		type: 'string'
	},
	{
		name: 'expiresAt',
		type: 'string'
	},
	{
		name: 'purchasedEntitlementCount',
		type: 'number'
	},
	{
		name: 'consumedEntitlementCount',
		type: 'number'
	}
];

const reorderConsumed = (consumedStatuses: ConsumedLicenses[]) => {
	return consumedStatuses.sort((a, b) => {
		const lastCount = a.purchasedCount + a.consumedCount;
		const nextCount = b.purchasedCount + b.consumedCount;
		return nextCount - lastCount;
	});
};

const reorderCompliant = (complianceStatuses: ComplianceStatuses[]) => {
	return complianceStatuses.sort((a, b) => {
		return b.count - a.count;
	});
};

export async function getSoftwareLicensesData(
	orgId: number,
	filterOption: FilterOption,
	filter: string
): Promise<ResponseData> {
	const maxResultCount = 10;
	const response = await fetchSoftwareLicensesData(orgId, maxResultCount);
	if (response.status !== 200) {
		return {
			error: {
				statusCode: response.status.toString(),
				message: `${response.statusText}`
			}
		};
	}

	if (!response.data[filter] || response.data[filter].length === 0) {
		return {
			error: {
				statusCode: '200',
				message: `No ${filter} data available`
			}
		};
	}

	/*
	Order bar charts by ascending order.
	If barchart is of type stacked, order
	by accumulative ammount.
	*/
	let data: ComplianceStatuses[] | ConsumedLicenses[] | ExpiringLicenses[];
	// Schema specific to entitlements chart
	let schema;
	switch (filter) {
		case 'complianceStatuses':
			data = reorderCompliant(response.data[filter]);
			schema = complianceSchema;
			break;
		case 'expiringLicenses':
			data = response.data[filter];
			schema = expiringLicensesSchema;
			break;
		default:
			data = reorderConsumed(response.data[filter]);
			schema = consumedLicensesSchema;
			break;
	}

	return {
		data,
		schema
	};
}
