/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, FC } from 'react';
import {
	useClearCurrentOrg,
	useCurrentOrg,
	useOrgs,
	useOrgId
} from '@flexera/shell.orgs';
import { useUAT } from '@flexera/environments.uat';
import { HostIndicator as FNMSHostIndicator } from '@flexera/fnms.core/src/HostIndicator';
import { history } from '@flexera/lib.router-history';
import { useUserProfile, useLogout } from '@flexera/auth.base';
import { Org } from '@flexera/services.grs';
import {
	HeaderOrgSwitcher,
	HeaderSubmitFeedback,
	HeaderProfile,
	HeaderProfileProps,
	Pill
	// Icon,
	// IconMdNotificationsDeactivated
} from '@flexera/ui.component-library';
import { useLocation } from 'react-router-dom';
import { t } from 'ttag';
import { useUserPreferences } from '@flexera/shell.user-preferences';
import {
	HeaderStyled,
	HeaderLeft,
	StyledHeaderRight,
	HeaderItem
} from '../styles/HeaderWrapper';
import { Breadcrumbs } from './HeaderBreadcrumbs';
import { BranchIndicator } from './BranchIndicator';
import { HelpNavLink } from './HelpNavLink';

export const Header: FC = () => {
	const user = useUserProfile();
	const logout = useLogout();
	const organisations = useOrgs();
	const currentOrg = useCurrentOrg();
	const clearOrg = useClearCurrentOrg();
	const orgId = useOrgId();
	const { getUserPreference } = useUserPreferences();
	const [orgsWithLabel, setOrgsWithLabel] = useState([]);
	const showUATIndicator = useUAT();
	const location = useLocation();
	const [showSubmitFeedbackButton, setShowSubmitFeedbackButton] = useState(
		false
	);
	const { getUserPreferences, preferences } = useUserPreferences();

	const [preferenceValue, setPreferenceValue] = useState(false);

	async function fetchUserPreferences() {
		await getUserPreferences();
	}

	useEffect(() => {
		if (!preferences) fetchUserPreferences();
		if (preferences) {
			const cookiePref = getUserPreference('cookie_settings');
			if (cookiePref?.value === 'true') {
				setPreferenceValue(true);
			}
		}
	}, [preferences]);

	const openInNewTab = (url: string) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	useEffect(() => {
		if (organisations && Object.keys(currentOrg).length > 2) {
			setOrgsWithLabel(
				organisations
					.map((org: Org) => ({
						...org,
						hasDivider: false,
						isHeading: false,
						isSelected: currentOrg.id === org.id,
						label: org.name,
						onClick: () => {
							if (org.id === currentOrg.id) return;
							clearOrg();
							history.push(`/orgs/${org.id}`);

							// Push Google Tag Manager event
							(window as any).dataLayer = (window as any).dataLayer || [];
							(window as any).dataLayer.push({ event: 'org_changed' });
						}
					}))
					.sort((orgOne, orgTwo) => {
						return orgOne.label.localeCompare(orgTwo.label);
					})
			);
		}
	}, [organisations, currentOrg]);

	const headerProfileMenuItems: HeaderProfileProps['menuItems'] = [
		{
			onClick: () =>
				openInNewTab('https://www.flexera.com/legal/privacy-policy.html'),
			target: '_blank',
			text: t`Privacy Policy`,
			itemicon: true
		},
		{
			onClick: (): void => {
				history.push('/settings/invitations');
			},
			text: t`User Settings`,
			hasdivider: true
		},
		{
			isLogout: true,
			onClick: () => logout(),
			text: t`Log out`
		}
	];
	const showFeedbackPaths = ['platform/reports/', 'viz', 'hif', 'vis'];

	useEffect(() => {
		const shouldShowFeedbackButton = showFeedbackPaths.some((path) =>
			location.pathname.includes(path)
		);
		setShowSubmitFeedbackButton(shouldShowFeedbackButton);
	}, [location.pathname]);

	return (
		<HeaderStyled currentPath={location.pathname}>
			<HeaderLeft>
				<Breadcrumbs />
			</HeaderLeft>
			<StyledHeaderRight>
				{orgId && orgsWithLabel && orgsWithLabel.length > 0 && (
					<HeaderItem currentPath={location.pathname}>
						{showUATIndicator && (
							<Pill id={'PageLayoutHeader-UAT-pill'} variant={'warning'}>
								🚧 UAT 🚧
							</Pill>
						)}

						<HeaderOrgSwitcher
							id={'header-org-switcher'}
							title={t`Selected Organization ${currentOrg ? currentOrg.name : ''}`}
							label={t`Select an Organization`}
							headingLabel={currentOrg ? currentOrg.name : ''}
							orgs={orgsWithLabel}
						/>
					</HeaderItem>
				)}
				<FNMSHostIndicator />
				<BranchIndicator />
				<HeaderItem
					currentPath={location.pathname}
					minimalButton
					defaultLeftPadding
				>
					<HeaderProfile
						noWrapping
						right
						id={'header-profile'}
						menuItems={headerProfileMenuItems}
						title={t`Profile details`}
						name={user && `${user.firstName} ${user.lastName}`}
					/>
				</HeaderItem>

				{/* To be added at a later stage */}
				{/* <HeaderItem tabIndex={0}>
					<Icon
					src={IconMdNotificationsDeactivated}
					opacity={0.5}
					title={'Coming Soon'}
					/>
					</HeaderItem> */}

				{showSubmitFeedbackButton && preferenceValue && (
					<HeaderItem currentPath={location.pathname} minimalButton>
						<HeaderSubmitFeedback />
					</HeaderItem>
				)}

				<HeaderItem currentPath={location.pathname} minimalButton>
					<HelpNavLink right noWrapping customId />
				</HeaderItem>
			</StyledHeaderRight>
		</HeaderStyled>
	);
};
