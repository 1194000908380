import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import {
	AppBrokerCatalog,
	AppBrokerCatalogManagement,
	AppBrokerConnections,
	AppBrokerDashboard,
	AppBrokerEditCatalog,
	AppBrokerManageRequests,
	AppBrokerMyRequests,
	AppBrokerNewCatalog
} from './AppBrokerApp';

const packageName = 'appBroker.bootstrap';
const capabilityPath = '/appBroker';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: `${packageName}/dashboard`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/dashboard`,
				exact: true,
				component: AppBrokerDashboard
			},
			{
				id: `${packageName}/catalog`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/catalog`,
				exact: true,
				component: AppBrokerCatalog
			},
			{
				id: `${packageName}/my-requests`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/my-requests`,
				exact: true,
				component: AppBrokerMyRequests
			},
			{
				id: `${packageName}/admin/catalog-management`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/admin/catalog-management`,
				exact: true,
				component: AppBrokerCatalogManagement
			},
			{
				id: `${packageName}/admin/catalog-management/new`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/admin/catalog-management/new`,
				exact: true,
				component: AppBrokerNewCatalog
			},
			{
				id: `${packageName}/admin/catalog-management/edit`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/admin/catalog-management/edit/:catalog_id`,
				exact: true,
				component: AppBrokerEditCatalog
			},
			{
				id: `${packageName}/admin/manage-requests`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/admin/manage-requests`,
				exact: true,
				component: AppBrokerManageRequests
			},
			{
				id: `${packageName}/admin/connections`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/admin/connections`,
				exact: true,
				component: AppBrokerConnections
			}
		]);
	}, []);
}
