const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Programme d'ajustement"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Centres de facturation"
        ]
      },
      "Billing Centers (Beta)": {
        "msgid": "Billing Centers (Beta)",
        "msgstr": [
          "Centres de facturation (bêta)"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Configuration de la facturation"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Budgets"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Beta": {
        "msgid": "Cloud Beta",
        "msgstr": [
          "Version bêta du cloud"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Anomalies des coûts du cloud"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planification de la migration vers le cloud"
        ]
      },
      "Coming Soon": {
        "msgid": "Coming Soon",
        "msgstr": [
          "Bientôt disponible"
        ]
      },
      "Custom Tags": {
        "msgid": "Custom Tags",
        "msgstr": [
          "Balises personnalisées"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimisation"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Instances réservées"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Total des économies potentielles"
        ]
      }
    }
  }
};
export default json;
