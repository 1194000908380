import styled, { css } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

interface SearchFormProps {
	isExpanded: boolean;
	searchIsActive?: boolean;
}

export const SearchForm = styled.form<SearchFormProps>`
	width: 100%;
	transition: padding 200ms;
	padding: ${({ isExpanded, searchIsActive }) =>
		isExpanded && !searchIsActive
			? `0 10px ${BaseTheme.space.sm} ${BaseTheme.space.sm}`
			: `0 .75rem ${BaseTheme.space.sm}`};
	box-sizing: border-box;
	display: flex;

	button.cancelSearchButton {
		width: 0px;
		cursor: pointer;
		padding: 0px;
		opacity: 0;
		border: none;
		background: none;
		color: ${BaseTheme.colors.primaryActive};
		transition: transform 200ms, width 200ms, opacity 200ms, padding 200ms;
		transform: translate(150%, 0%);
		${({ searchIsActive }) =>
			searchIsActive &&
			css`
				opacity: 1;
				width: 25%;
				padding: 0px ${BaseTheme.space.xxs};
				transform: translate(0%, 0%);
			`}
	}
`;

export const HiddenLabelForSearch = styled.label`
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
`;

export const SearchContainer = styled.div<SearchFormProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	transition: border 200ms, width 200ms, padding 200ms;
	width: 100%;
	color: #9b9b9b;
	background: #23283a;
	border-radius: ${BaseTheme.space.xxs};
	position: relative;
	padding: ${BaseTheme.space.xxs};
	box-sizing: border-box;
	border: 0px;
	font-size: ${BaseTheme.fontSizes.xs};
	input {
		height: 100%;
	}
	button.searchButton {
		border: none;
		background: none;
		padding: 0px;
		cursor: pointer;
		width: 20px;
		height: 20px;
		transition: padding 200ms, transform 400ms;
		svg {
			width: 20px;
			height: 20px;
			padding: 0px;
		}
	}
	${({ searchIsActive }) =>
		searchIsActive &&
		css`
			width: 75%;
			border: ${BaseTheme.space.xxs} solid rgba(0, 161, 222, 0.4);
			padding: 0px;
			outline: none;
			button.searchButton {
				transform: rotate(90deg);
			}
		`}

	svg {
		transition: margin 200ms;
		margin: auto;
		padding: 0px;
		path[fill='#000'] {
			fill: ${BaseTheme.colors.primaryActive};
		}
		${({ isExpanded }) =>
			isExpanded &&
			css`
				margin: 0 0 0 auto;
			`}
	}

	input {
		width: 0px;
		padding: 0px;
		border: none;
		background: none;
		outline: none;
		transition: width 200ms;
		::placeholder {
			transition: opacity 200ms;
			opacity: 0;
			color: rgb(154, 154, 154);
		}
		${({ isExpanded }) =>
			isExpanded &&
			css`
				width: 90%;
				padding: 0 0 0 7px;
				::placeholder {
					opacity: 1;
				}
			`}
	}

	${({ searchIsActive }) => {
		if (searchIsActive) {
			return css`
				color: #00a0df;
				font-size: 0.75rem;

				input {
					color: #fff;
				}
			`;
		}
	}}
`;
export const NoResultsMessage = styled.p`
	grid-column: 1;
	grid-row: 2;
	font-size: ${BaseTheme.fontSizes.xs};
	margin-top: ${BaseTheme.space.xs};
	text-align: center;
`;
