import { lazyLoaded } from '@flexera/lib.utilities';

export const BarChart = lazyLoaded(async () => {
	const mod = import('./components/BarCharts/BarChart');
	return { default: (await mod).BarChart };
});

export const BarChartStacked = lazyLoaded(async () => {
	const mod = import('./components/BarCharts/BarChartStacked');
	return { default: (await mod).BarChartStacked };
});

export const LineChart = lazyLoaded(async () => {
	const mod = import('./components/LineChart');
	return { default: (await mod).LineChart };
});

export const BubbleChart = lazyLoaded(async () => {
	const mod = import('./components/BubbleChart');
	return { default: (await mod).BubbleChart };
});

export const DonutChart = lazyLoaded(async () => {
	const mod = import('./components/DonutChart');
	return { default: (await mod).DonutChart };
});
