import Illustration from './illustration.svg';
import Data from './data.svg';
import Testimonials from './testimonials.svg';
import Videos from './videos.svg';
import Stars from './stars.svg';
import Play from './iconPlay.svg';

export const IllustrationIcon = Illustration;
export const DataIcon = Data;
export const TestimonialsIcon = Testimonials;
export const VideosIcon = Videos;
export const StarsIcon = Stars;
export const PlayIcon = Play;
