import { MenuItem } from '@flexera/shell.navigation';
import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'ttag';

export const BreadcrumbsComponent = ({
	breadcrumbs
}: {
	breadcrumbs: MenuItem[];
}) => {
	return (
		<div className={'breadcrumbs'}>
			{breadcrumbs.map(
				(
					breadcrumb: {
						label?: string;
						path?: string;
						id?: string;
						isPageError?: boolean;
					},
					index: number
				): JSX.Element => {
					const { label, path, id, isPageError } = breadcrumb;
					return (
						<li key={id}>
							{path && breadcrumbs.length !== index + 1 ? (
								<Link to={path}>{label || t`Loading...`}</Link>
							) : (
								<span
									style={{ margin: index === 0 ? '0 0.25rem 0 0' : '0 0.25rem' }}
									className={
										breadcrumbs.length === index + 1 ? 'breadcrumb-page-title' : ''
									}
								>
									{isPageError ? (
										<span className={'error'}> {t`Error...`} </span>
									) : (
										label || t`Loading...`
									)}
								</span>
							)}
							{breadcrumbs.length !== index + 1 && `/`}
						</li>
					);
				}
			)}
		</div>
	);
};
