import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { SpendDashboard, SpendDataMashup } from './UnifiedSpendApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'spend.bootstrap/dashboard',
				parentId: OrgsRouteId,
				path: '/spend/analytics/:dashboardId?',
				exact: false,
				component: SpendDashboard
			},
			{
				id: 'spend.bootstrap/add-custom-dashboard',
				parentId: OrgsRouteId,
				path: '/spend/analytics/add',
				exact: false,
				component: SpendDashboard
			},
			{
				id: 'spend.bootstrap/explorer-custom-dashboard',
				parentId: OrgsRouteId,
				path: '/spend/analytics/explorer',
				exact: false,
				component: SpendDashboard
			},
			{
				id: 'spend.bootstrap/data-mashup',
				parentId: OrgsRouteId,
				path: '/spend/data-mashup',
				exact: false,
				component: SpendDataMashup
			}
		]);
	}, []);
}
