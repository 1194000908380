const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Programa de ajuste"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Centros de facturación"
        ]
      },
      "Billing Centers (Beta)": {
        "msgid": "Billing Centers (Beta)",
        "msgstr": [
          "Centros de facturación (beta)"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Configuración de facturación"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Presupuestos"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "En la nube"
        ]
      },
      "Cloud Beta": {
        "msgid": "Cloud Beta",
        "msgstr": [
          "Beta en la nube"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Anomalías en el coste de la nube"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planificación de migración a la nube"
        ]
      },
      "Coming Soon": {
        "msgid": "Coming Soon",
        "msgstr": [
          "Próximamente"
        ]
      },
      "Custom Tags": {
        "msgid": "Custom Tags",
        "msgstr": [
          "Etiquetas personalizadas"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimización"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Instancias reservadas"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Ahorro potencial total"
        ]
      }
    }
  }
};
export default json;
