import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { ADMIN__PLUGINS, IT_VISIBILITY } from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const cloudManagementId = 'cloud-management.bootstrap/cloud-management';
	const cloudManagementPath = `/orgs/${orgId}/cloud`;
	const adminPath = `/orgs/${orgId}/admin`;
	const { addItems } = useNavItems();

	// Permissions
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeeCMResource,
		Permissions.canSeeCMPlugins,
		Permissions.canSeeCMRegistrations,
		Permissions.hasCloudManagement
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasCloudManagement)) return;

		return addItems(
			[
				perms.get(Permissions.canSeeCMResource) && {
					id: ids.RESOURCES,
					parentId: IT_VISIBILITY,
					path: `${cloudManagementPath}/resources`,
					urlMatch: /^\/orgs\/\d+\/cloud\/resources(\/|$|\?)/,
					label: t`Cloud Inventory`,
					priority: 41
				},
				perms.get(Permissions.canSeeCMPlugins) && {
					parentId: ADMIN__PLUGINS,
					id: `${cloudManagementId}/plugins/catalog`,
					path: `${adminPath}/plugins/catalog`,
					helpHref: `${helpDocBaseUrl}`,
					urlMatch: /^\/orgs\/\d+\/admin\/plugins\/(catalog|list|register)*(\/|$|\?)/,
					label: t`Plugin Catalog`
				},
				perms.get(Permissions.canSeeCMRegistrations) && {
					parentId: ADMIN__PLUGINS,
					id: `${cloudManagementId}/plugins/registered`,
					path: `${adminPath}/plugins/registered`,
					helpHref: `${helpDocBaseUrl}`,
					urlMatch: /^\/orgs\/\d+\/admin\/plugins\/registered(\/|$|\?)/,
					label: t`Registered Plugins`
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, perms, loading]);
}
