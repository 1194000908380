import styled from 'styled-components';

interface GridLayoutProps {
	hideUntilReady: boolean;
}

export const GridLayout = styled.div<GridLayoutProps>`
	width: 100%;
	height: 100%;
	display: ${(props) => (props.hideUntilReady ? `none` : `grid`)};
	grid-template-columns: auto 1fr;
	grid-template-rows: 64px 1fr;
	gap: 0px 0px;
	grid-template-areas:
		'Navigation Header'
		'Navigation Content';
	overflow: hidden;
	& .gov-styles-wrapper .fusion-layout {
		height: calc(100vh - 46px);
	}

	@media screen and (max-width: 480px) {
		grid-template-rows: 100px 1fr;
		grid-row-gap: 2rem;
	}
`;
