const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Applied Policies": {
        "msgid": "Applied Policies",
        "msgstr": [
          "Aktive Richtlinien"
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisierung"
        ]
      },
      "Catalog": {
        "msgid": "Catalog",
        "msgstr": [
          "Katalog"
        ]
      },
      "Credentials": {
        "msgid": "Credentials",
        "msgstr": [
          "Anmeldedaten"
        ]
      },
      "Incidents": {
        "msgid": "Incidents",
        "msgstr": [
          "Ereignisse"
        ]
      },
      "Templates": {
        "msgid": "Templates",
        "msgstr": [
          "Vorlagen"
        ]
      }
    }
  }
};
export default json;
