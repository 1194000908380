import { useAuthenticated } from '@flexera/auth.base';
import { SVR } from '@flexera/shell.base-nav-tree';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useMarketingCapability } from '@flexera/flexera-one.feature-flags';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hasFeatureAccess = useMarketingCapability();
	const org = useCurrentOrg();
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canViewITVisibility
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.canViewITVisibility)) return;

		if (!hasFeatureAccess) return;

		return addItems([
			{
				id: ids.SVR,
				parentId: SVR,
				label: t`Software Vulnerability Research`,
				description: t`Software Vulnerability Research`,
				priority: 35,
				path: `/orgs/${orgId}/svr`,
				urlMatch: /^\/orgs\/\d+\/svr(\/|$|\?)/
			}
		]);
	}, [org, orgId, hasFeatureAccess, authenticated, perms, loadingPerms]);
}
