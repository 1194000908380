import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	html,
	body {
		h1,
		h2,
		h3,
		h4 {
			margin-top: 0;
		}
	}

	html,
	body,
	#shell-root {
		background-color: #f8f9fb;
		font-family: ${(props) => props.theme.fonts.primary};
		height: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
		overflow-y: hidden;
	}

	.ui-fusion {
		.legacy_optima_wrapper {
			height: auto;
			&.is-fusion-explorer {
				height: calc(100vh - 64px);
			}
		}
	}

	#header-org-switcher {
		margin-right: 0;
	}

	#org-switcher-popover {
		margin-left: -4.5rem;
	}

	#profile-text-dropdown {
		margin-left: -5.1875rem;
	}

	#subcategory-slideout-panel {
        top: 0;
        height: 100%;
    }

    #network-slideout-panel {
        top: 0;
        height: 100%;
	}

    #analytical-slideout-panel,
	#new-dashboard-slideout-panel {
        top: 0;
        height: 100%;
	}

	#edit-group-beacon-details-slideout{
		top: 0;
        height: 100%;
	}

	#timeout-warning {
		.modal-close-icon {
			display: none;
		}
	}
`;
