import { VENDOR_URL } from '@flexera/lib.api';
import { fetch } from '@flexera/lib.http';

export interface Category {
	softwareCategory: string;
	count: number;
}

export async function fetchCategoryData(orgId: number) {
	return fetch<Category[]>(
		`${VENDOR_URL}orgs/${orgId}/software-category-summary`,
		{
			authSchemes: ['IAM'],
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Api-Version': '1.0'
			}
		}
	);
}
