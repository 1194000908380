import { ResponseSchema } from '@flexera/data-visualization.data';
import { LicensesClient } from '@flexera/saas.api-client';

const schema: ResponseSchema[] = [
	{ name: 'name', type: 'string' },
	{ name: 'cost', type: 'number' },
	{ name: 'expiration', type: 'string' }
];

const yearMs = 365 * 24 * 60 * 60 * 1000;

interface Renewal {
	name: string;
	cost: number;
	expiration: string;
}

export async function getRenewals(orgId: number) {
	const renewals: { [id: string]: Renewal[] } = {};

	const response = await LicensesClient.getLicenseUsage(orgId);
	const data = response?.values || [];

	const startDate = Date.now();
	const endDate = startDate + yearMs;

	data.forEach((app) => {
		app.licenses?.forEach((license) => {
			license?.terms?.forEach((term) => {
				if (term.registered && term.endsAt) {
					const expiration = term.endsAt.substr(0, 10);
					const expTime = new Date(expiration).getTime();

					// only show renewals for upcoming year
					if (expTime > startDate && expTime < endDate) {
						let target = renewals[app.id];
						if (!target) {
							target = [];
							renewals[app.id] = target;
						}

						// group reneals by expiration
						const item = target.find((x) => x.expiration === expiration);
						if (item) {
							item.cost += term.annualCost || 0;
						} else {
							target.push({
								name: app.name,
								cost: term.annualCost || 0,
								expiration
							});
						}
					}
				}
			});
		});
	});

	const result = Object.keys(renewals).reduce((out, id) => {
		return out.concat(renewals[id]);
	}, []);

	return {
		data: result,
		schema
	};
}
