import { Loader } from '@flexera/lib.loader';
import { useMenu } from '@flexera/shell.navigation';
import { useCurrentOrg } from '@flexera/shell.orgs';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { migrateLocalStorageItems } from '@flexera/lib.setting-store';
import { useUserId } from '@flexera/auth.base';
import { NoNav } from './NoNav';

/**
 * This is the "org home page" that users will be sent to when switching org
 */
export const OrgsRoot = (): JSX.Element => {
	const menu = useMenu().filter((i) => i.label && i.path && !i.static);
	const [menuReady, setMenuReady] = useState(false);
	const userId = useUserId();
	const org = useCurrentOrg();
	const history = useHistory();
	const isInitialMount = useRef(true);

	useEffect(() => {
		migrateLocalStorageItems(userId, org.id);
		history.push(`/orgs/${org.id}/landing`);
	}, [org]);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			setMenuReady(true);
		}
	}); // Consider the mounted hook values as a loading state

	// If there is any loading item, we wait for them to clear before rendering because things could change.
	const loading = !!menu?.find((i) => i.path === 'loading') || !menuReady;
	if (loading) return <Loader />;

	switch (menu.length) {
		case 0:
			return <NoNav />;
		case 1: {
			const { path } = menu[0];
			return <Redirect to={path} />;
		}
		default:
			return <Redirect to={`/orgs/${org.id}/landing`} />;
	}
};
