import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { TechnopediaApp } from './TechnopediaApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'technopedia.bootstrap/dashboard',
				parentId: OrgsRouteId,
				path: '/technopedia/dashboard',
				exact: false,
				component: TechnopediaApp
			}
		]);
	}, []);
}
