const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Add Application": {
        "msgid": "Add Application",
        "msgstr": [
          "Ajouter une application"
        ]
      },
      "All SaaS Licenses": {
        "msgid": "All SaaS Licenses",
        "msgstr": [
          "Toutes les licences SaaS"
        ]
      },
      "All SaaS Users": {
        "msgid": "All SaaS Users",
        "msgstr": [
          "Tous les utilisateurs de l'application SaaS"
        ]
      },
      "Applications": {
        "msgid": "Applications",
        "msgstr": [
          "Applications"
        ]
      },
      "Audit Logs": {
        "msgid": "Audit Logs",
        "msgstr": [
          "Journaux d'audit"
        ]
      },
      "Customers": {
        "msgid": "Customers",
        "msgstr": [
          "Clients"
        ]
      },
      "Financial QA": {
        "msgid": "Financial QA",
        "msgstr": [
          "Assurance qualité financière"
        ]
      },
      "General": {
        "msgid": "General",
        "msgstr": [
          "Général"
        ]
      },
      "Managed SaaS Applications": {
        "msgid": "Managed SaaS Applications",
        "msgstr": [
          "Applications SaaS gérées"
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organisation"
        ]
      },
      "Organization SaaS Usage": {
        "msgid": "Organization SaaS Usage",
        "msgstr": [
          "Utilisation de l'application SaaS par l'organisation"
        ]
      },
      "SSO Naming": {
        "msgid": "SSO Naming",
        "msgstr": [
          "Donner un nom à l'authentification unique"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS License Usage": {
        "msgid": "SaaS License Usage",
        "msgstr": [
          "Utilisation des licences SaaS"
        ]
      },
      "Suspicious SaaS Activities": {
        "msgid": "Suspicious SaaS Activities",
        "msgstr": [
          "Activités SaaS suspectes"
        ]
      },
      "Underutilized SaaS Accounts": {
        "msgid": "Underutilized SaaS Accounts",
        "msgstr": [
          "Comptes SaaS sous-utilisés"
        ]
      },
      "Unsanctioned SaaS Applications": {
        "msgid": "Unsanctioned SaaS Applications",
        "msgstr": [
          "Applications SaaS non sanctionnées"
        ]
      },
      "Vendors": {
        "msgid": "Vendors",
        "msgstr": [
          "Fournisseurs"
        ]
      }
    }
  }
};
export default json;
