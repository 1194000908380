import { ResponseSchema } from '@flexera/data-visualization.data';
import { MetricsClient, OrgStats } from '@flexera/saas.api-client';

const schema: ResponseSchema[] = [
	{ name: 'totalAppCount', type: 'number' },
	{ name: 'totalAppSpend', type: 'number' },
	{ name: 'totalLicenseCount', type: 'number' },
	{ name: 'underutilizedCount', type: 'number' }
];

// cache request within a small time frame to avoid making
// multiple calls for each widget on the same dashboard
type CachedResult = { value: Promise<OrgStats>; time: number };
const cache: { [orgId: string]: CachedResult } = {};

export async function getAppUsage(orgId: number) {
	let request: Promise<OrgStats>;
	const cachedResult = cache[`${orgId}`];

	if (cachedResult) {
		const diff = Date.now() - cachedResult.time;
		if (diff < 5000) {
			// 5 second cache, these calls on a single dashboard
			// will be made almost immediately one after another
			request = cachedResult.value;
		}
	}

	if (!request) {
		const newCache: CachedResult = { value: null, time: Date.now() };
		newCache.value = MetricsClient.getOrgStats(orgId).then((result) => {
			return result;
		});

		cache[`${orgId}`] = newCache;
		request = newCache.value;
	}

	const data = await request;

	return {
		data,
		schema
	};
}
