/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { useVisualizationTypes } from '@flexera/data-visualization.visualization/src/hooks/useVisualizationTypes';
import { LineChart, BubbleChart, DonutChart } from './components';
import { BarChart, BarChartStacked } from './components/BarCharts';

const useRegisterVisualizationTypes = () => {
	const { addVisualizationTypes } = useVisualizationTypes();
	return addVisualizationTypes([
		{
			id: 'amBarChart',
			name: 'AmCharts Bar Chart',
			getVisualization: () => {
				return <BarChart />;
			}
		},
		{
			id: 'amBarChartStacked',
			name: 'AmCharts Stacked Bar Chart',
			getVisualization: () => {
				return <BarChartStacked />;
			}
		},
		{
			id: 'amLineChart',
			name: 'AmCharts Line Chart',
			getVisualization: () => {
				return <LineChart />;
			}
		},
		{
			id: 'amBubbleChart',
			name: 'AmCharts Bubble Chart',
			getVisualization: () => {
				return <BubbleChart />;
			}
		},
		{
			id: 'amDonutChart',
			name: 'AmCharts Donut Chart',
			getVisualization: () => {
				return <DonutChart />;
			}
		}
	]);
};

export const AmChartsVisualizations: FC = () => {
	useRegisterVisualizationTypes();
	return null;
};
