import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const HIFPowerBiDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/hif.main');
		return { default: (await mod).HIFPowerBiDashboard };
	}),
	'hif'
);
