import { RouterProvider } from '@flexera/lib.router';
import { history } from '@flexera/lib.router-history';
import { StoreProvider } from '@flexera/shell.store';
import { DataProvider } from '@flexera/data-visualization.data';
import React, { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';
import { Router, Route } from 'react-router-dom';
import { VisualizationTypeProvider } from '@flexera/data-visualization.visualization/src/VisualizationTypeProvider';
import { QueryParamProvider } from 'use-query-params';
import { DefaultRoute } from './DefaultRoute';

/**
 * Renders the application shell
 * @param Layout A layout component (see @flexera/shell.app-layout for an example)
 * @param theme A theme object for the UI components
 * @param rootComponents Optional list of components to render at the root of the app
 */
export function renderShell(rootComponents: ReactNode) {
	const div = document.createElement('div');
	div.id = 'shell-root';
	document.body.appendChild(div);
	document.getElementById('shell-root').style.height = '100%';

	const popoverRoot = document.createElement('div');
	popoverRoot.id = 'popover-root';
	document.body.appendChild(popoverRoot);

	const slideoutRoot = document.createElement('div');
	slideoutRoot.id = 'slideout-root';
	document.body.appendChild(slideoutRoot);

	const modalDiv = document.createElement('div');
	modalDiv.id = 'modal-root';
	document.body.appendChild(modalDiv);

	const root = createRoot(div);

	root.render(
		<StoreProvider>
			<Router history={history}>
				<QueryParamProvider ReactRouterRoute={Route}>
					<RouterProvider>
						<DataProvider>
							<VisualizationTypeProvider>
								<DefaultRoute />
								{rootComponents}
							</VisualizationTypeProvider>
						</DataProvider>
					</RouterProvider>
				</QueryParamProvider>
			</Router>
		</StoreProvider>
	);
}
