const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add Application": {
        "msgid": "Add Application",
        "msgstr": [
          "Anwendung hinzufügen"
        ]
      },
      "All SaaS Licenses": {
        "msgid": "All SaaS Licenses",
        "msgstr": [
          "Alle SaaS-Lizenzen"
        ]
      },
      "All SaaS Users": {
        "msgid": "All SaaS Users",
        "msgstr": [
          "Alle SaaS-Benutzer"
        ]
      },
      "Applications": {
        "msgid": "Applications",
        "msgstr": [
          "Anwendungen"
        ]
      },
      "Audit Logs": {
        "msgid": "Audit Logs",
        "msgstr": [
          "Audit-Protokolle"
        ]
      },
      "Customers": {
        "msgid": "Customers",
        "msgstr": [
          "Kunden"
        ]
      },
      "Financial QA": {
        "msgid": "Financial QA",
        "msgstr": [
          "QA der Finanzen"
        ]
      },
      "General": {
        "msgid": "General",
        "msgstr": [
          "Allgemeine Daten"
        ]
      },
      "Managed SaaS Applications": {
        "msgid": "Managed SaaS Applications",
        "msgstr": [
          "Verwaltete SaaS-Anwendungen"
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Unternehmen"
        ]
      },
      "Organization SaaS Usage": {
        "msgid": "Organization SaaS Usage",
        "msgstr": [
          "SaaS-Nutzung im Unternehmen"
        ]
      },
      "SSO Naming": {
        "msgid": "SSO Naming",
        "msgstr": [
          "SSO-Benennung"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS License Usage": {
        "msgid": "SaaS License Usage",
        "msgstr": [
          "Nutzung von SaaS-Lizenzen"
        ]
      },
      "Suspicious SaaS Activities": {
        "msgid": "Suspicious SaaS Activities",
        "msgstr": [
          "Verdächtige SaaS-Aktivitäten"
        ]
      },
      "Underutilized SaaS Accounts": {
        "msgid": "Underutilized SaaS Accounts",
        "msgstr": [
          "Nicht ausgelastete SaaS-Konten"
        ]
      },
      "Unsanctioned SaaS Applications": {
        "msgid": "Unsanctioned SaaS Applications",
        "msgstr": [
          "Nicht genehmigte SaaS-Anwendungen"
        ]
      },
      "Vendors": {
        "msgid": "Vendors",
        "msgstr": [
          "Anbieter"
        ]
      }
    }
  }
};
export default json;
