import { ResponseSchema, ResponseData } from '@flexera/data-visualization.data';
import {
	fetchOperatingSystemData,
	OperatingSystem
} from '@flexera/services.vendor';

const getSchema = () => {
	const schema: ResponseSchema[] = [
		{
			name: 'operatingSystem',
			type: 'string'
		},
		{
			name: 'count',
			type: 'number'
		}
	];

	return schema;
};

const reorderOperatingSystems = (products: OperatingSystem[]) => {
	return products.sort((a, b) => {
		return b.count - a.count;
	});
};

export async function getOperatingSystemData(
	orgId: number
): Promise<ResponseData> {
	const response = await fetchOperatingSystemData(orgId);
	if (response.status !== 200) {
		return {
			error: {
				statusCode: response.status.toString(),
				message: `${response.statusText}`
			}
		};
	}
	return {
		data: reorderOperatingSystems(response.data),
		schema: getSchema()
	};
}
