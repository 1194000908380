export const someItemsAreLoading = (items) => {
	return items.some((item) => item.label && item.label.includes('loading'));
};

export const getCleanItems = (items) => {
	if (someItemsAreLoading(items)) {
		return new Array(items.length).fill({ label: 'loading' });
	}

	return items;
};
