import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

/*
 * Jobs and Settings pages are not required
 */

export const AppBrokerDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).AdminDashboard };
	}),
	'ab'
);

export const AppBrokerCatalog = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).Catalog };
	}),
	'ab'
);

export const AppBrokerCatalogManagement = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).CatalogManagement };
	}),
	'ab'
);

export const AppBrokerConnections = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).Connections };
	}),
	'ab'
);

export const AppBrokerEditCatalog = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).EditCatalog };
	}),
	'ab'
);

export const AppBrokerManageRequests = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).ManageRequests };
	}),
	'ab'
);

export const AppBrokerMyRequests = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).MyRequests };
	}),
	'ab'
);

export const AppBrokerNewCatalog = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/appBroker.main');
		return { default: (await mod).NewCatalog };
	}),
	'ab'
);
