const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.": {
        "msgid": "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.",
        "msgstr": [
          "(Ahora,) los proveedores de software tienen que pensárselo dos veces antes de asustarnos o intimidarnos durante una auditoría. Flexera nos ha proporcionado los datos y la confianza para saber exactamente qué software tenemos y utilizamos."
        ]
      },
      "Back to top": {
        "msgid": "Back to top",
        "msgstr": [
          "Volver al principio"
        ]
      },
      "Case Studies": {
        "msgid": "Case Studies",
        "msgstr": [
          "Estudios de casos"
        ]
      },
      "Catch up on the latest news and content.": {
        "msgid": "Catch up on the latest news and content.",
        "msgstr": [
          "Conozca las últimas noticias y otros contenidos."
        ]
      },
      "Chief Procurement Officer": {
        "msgid": "Chief Procurement Officer",
        "msgstr": [
          "Jefa de Compras"
        ]
      },
      "Close": {
        "msgid": "Close",
        "msgstr": [
          "Cerrar"
        ]
      },
      "Cloud Cloud Cost Optimization": {
        "msgid": "Cloud Cloud Cost Optimization",
        "msgstr": [
          "Cloud Cost Optimization"
        ]
      },
      "Cloud Operations Engineer": {
        "msgid": "Cloud Operations Engineer",
        "msgstr": [
          "Ingeniero de operaciones en la nube"
        ]
      },
      "Contact Us": {
        "msgid": "Contact Us",
        "msgstr": [
          "Póngase en contacto con nosotros"
        ]
      },
      "Contact Us Link. This link will open in a new window.": {
        "msgid": "Contact Us Link. This link will open in a new window.",
        "msgstr": [
          "Vínculo de contacto. Este vínculo se abrirá en otra ventana."
        ]
      },
      "Data Privacy": {
        "msgid": "Data Privacy",
        "msgstr": [
          "Privacidad de datos"
        ]
      },
      "Data Privacy Link. This link will open in a new window.": {
        "msgid": "Data Privacy Link. This link will open in a new window.",
        "msgstr": [
          "Vínculo de privacidad de datos. Este vínculo se abrirá en otra ventana."
        ]
      },
      "Datasheets": {
        "msgid": "Datasheets",
        "msgstr": [
          "Hojas de datos"
        ]
      },
      "Deep dives into the features and benefit of Flexere One solutions.": {
        "msgid": "Deep dives into the features and benefit of Flexere One solutions.",
        "msgstr": [
          "Sumérjase en las características y ventajas de las soluciones Flexera One."
        ]
      },
      "Desktop Services Supervisor": {
        "msgid": "Desktop Services Supervisor",
        "msgstr": [
          "Supervisora de servicios de escritorio"
        ]
      },
      "Examining a range of IT challenges and how Flexera One solves them.": {
        "msgid": "Examining a range of IT challenges and how Flexera One solves them.",
        "msgstr": [
          "Examen de una serie de retos informáticos y modo en que Flexera One los resuelve."
        ]
      },
      "FLEXINSIGHTS": {
        "msgid": "FLEXINSIGHTS",
        "msgstr": [
          "DATOS DE FLEX"
        ]
      },
      "Find out more": {
        "msgid": "Find out more",
        "msgstr": [
          "Más información"
        ]
      },
      "Flexera One Overview": {
        "msgid": "Flexera One Overview",
        "msgstr": [
          "Información general de Flexera One"
        ]
      },
      "Flexera One Solutions": {
        "msgid": "Flexera One Solutions",
        "msgstr": [
          "Soluciones Flexera One"
        ]
      },
      "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.": {
        "msgid": "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.",
        "msgstr": [
          "Flexera One le ayuda a maximizar el valor del escritorio en la nube, proporcionando una visión unificada de su entorno tecnológico."
        ]
      },
      "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.": {
        "msgid": "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.",
        "msgstr": [
          "La funcionalidad «Cloud Cost Optimization» de Flexera One es una solución para administrar y optimizar los costes, lo que permite a las empresas reducir los costes y garantizar el rendimiento."
        ]
      },
      "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.": {
        "msgid": "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.",
        "msgstr": [
          "Con la funcionalidad «Solicitudes de activos de TI» de Flexera One podrá entregar rápidamente el software de la empresa a sus empleados."
        ]
      },
      "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.": {
        "msgid": "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.",
        "msgstr": [
          "La visibilidad de TI de Flexera One le ofrece una única fuente fiable para todo su parque informático."
        ]
      },
      "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.": {
        "msgid": "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.",
        "msgstr": [
          "Las funcionalidades de SaaS de Flexera One proporcionan visibilidad y control sobre su panorama de SaaS."
        ]
      },
      "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.": {
        "msgid": "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.",
        "msgstr": [
          "Flexera me ha proporcionado la calidad de datos necesaria para poder ofrecer la transparencia financiera así como la información general que requiere un departamento global responsable del ciclo de vida de los distintos softwares."
        ]
      },
      "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.": {
        "msgid": "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.",
        "msgstr": [
          "Flexera nos ha ayudado a obtener información sobre nuestro gasto en la nube y a ser más eficientes en la reducción de residuos."
        ]
      },
      "Flexera is such a flexible tool, allowing high-level views.": {
        "msgid": "Flexera is such a flexible tool, allowing high-level views.",
        "msgstr": [
          "Flexera es una herramienta muy flexible que nos ofrece visiones de alto nivel."
        ]
      },
      "Flexera. All rights reserved.": {
        "msgid": "Flexera. All rights reserved.",
        "msgstr": [
          "Flexera. Todos los derechos reservados."
        ]
      },
      "Hardware Models": {
        "msgid": "Hardware Models",
        "msgstr": [
          "Modelos de hardware"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Solicitudes de activos de TI"
        ]
      },
      "IT Security & Compliance Manager": {
        "msgid": "IT Security & Compliance Manager",
        "msgstr": [
          "Administradora de conformidad y seguridad TI"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "Visibilidad de TI"
        ]
      },
      "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.": {
        "msgid": "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.",
        "msgstr": [
          "Nos ayuda a ahorrar un tiempo muy valioso. Nuestro equipo tiene más tiempo para centrarse en otras iniciativas estratégicas de software."
        ]
      },
      "Join The Flexera One Community": {
        "msgid": "Join The Flexera One Community",
        "msgstr": [
          "Únase a Flexera One Community"
        ]
      },
      "Latest News": {
        "msgid": "Latest News",
        "msgstr": [
          "Últimas noticias"
        ]
      },
      "Latest Videos": {
        "msgid": "Latest Videos",
        "msgstr": [
          "Últimos vídeos"
        ]
      },
      "Learn more about Flexera One": {
        "msgid": "Learn more about Flexera One",
        "msgstr": [
          "Más información sobre Flexera One"
        ]
      },
      "Lifecycle Dates": {
        "msgid": "Lifecycle Dates",
        "msgstr": [
          "Fechas del ciclo de vida"
        ]
      },
      "MANUFACTURERS": {
        "msgid": "MANUFACTURERS",
        "msgstr": [
          "FABRICANTES"
        ]
      },
      "MARKETING": {
        "msgid": "MARKETING",
        "msgstr": [
          "MARKETING"
        ]
      },
      "Market Data Points": {
        "msgid": "Market Data Points",
        "msgstr": [
          "Puntos de datos del mercado"
        ]
      },
      "Maximize the business value of your IT investments": {
        "msgid": "Maximize the business value of your IT investments",
        "msgstr": [
          "Maximice el valor empresarial de sus inversiones TI"
        ]
      },
      "Our Technopedia IT asset database spans:": {
        "msgid": "Our Technopedia IT asset database spans:",
        "msgstr": [
          "Nuestra base de datos de activos de TI Technopedia incluye lo siguiente:"
        ]
      },
      "PRODUCTS": {
        "msgid": "PRODUCTS",
        "msgstr": [
          "PRODUCTOS"
        ]
      },
      "Product Usage Rights": {
        "msgid": "Product Usage Rights",
        "msgstr": [
          "Derechos de uso del producto"
        ]
      },
      "Read Testimonials": {
        "msgid": "Read Testimonials",
        "msgstr": [
          "Leer testimonios"
        ]
      },
      "Read how Flexera One solutions have benefited enterprises like yours.": {
        "msgid": "Read how Flexera One solutions have benefited enterprises like yours.",
        "msgstr": [
          "Descubra los beneficios que las soluciones Flexera One han proporcionado a empresas como la suya."
        ]
      },
      "SIGNUP CARD": {
        "msgid": "SIGNUP CARD",
        "msgstr": [
          "TARJETA DE SUSCRIPCIÓN"
        ]
      },
      "SKUs": {
        "msgid": "SKUs",
        "msgstr": [
          "SKU"
        ]
      },
      "SaaS Manager": {
        "msgid": "SaaS Manager",
        "msgstr": [
          "Administrador de SaaS"
        ]
      },
      "See Our Data": {
        "msgid": "See Our Data",
        "msgstr": [
          "Ver nuestras cifras"
        ]
      },
      "See the impact Flexera One Solutions can have on your IT estate.": {
        "msgid": "See the impact Flexera One Solutions can have on your IT estate.",
        "msgstr": [
          "Conozca el impacto que las soluciones Flexera One pueden tener en su parque informático."
        ]
      },
      "Senior Director of Enterprise Data and Systems": {
        "msgid": "Senior Director of Enterprise Data and Systems",
        "msgstr": [
          "Director general de sistemas y datos empresariales"
        ]
      },
      "Senior Manager, Technical Product": {
        "msgid": "Senior Manager, Technical Product",
        "msgstr": [
          "Director ejecutivo, producto técnico"
        ]
      },
      "Senior Software Contract Manager": {
        "msgid": "Senior Software Contract Manager",
        "msgstr": [
          "Director ejecutivo de contratos de software"
        ]
      },
      "Software Releases": {
        "msgid": "Software Releases",
        "msgstr": [
          "Versiones de software"
        ]
      },
      "Solutions Briefs": {
        "msgid": "Solutions Briefs",
        "msgstr": [
          "Resúmenes de soluciones"
        ]
      },
      "TESTIMONIALS": {
        "msgid": "TESTIMONIALS",
        "msgstr": [
          "TESTIMONIOS"
        ]
      },
      "Technical Architect/Developer": {
        "msgid": "Technical Architect/Developer",
        "msgstr": [
          "Desarrollador/arquitecto técnico"
        ]
      },
      "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.": {
        "msgid": "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.",
        "msgstr": [
          "Las herramientas de Flexera han eliminado las conjeturas de la ecuación. Nos han permitido trabajar con nuestro socio de migración para priorizar las aplicaciones, dimensionarlas y asegurarlas correctamente en la nube."
        ]
      },
      "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.": {
        "msgid": "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.",
        "msgstr": [
          "La capacidad de ver todo el software y de hacer un seguimiento para evaluar todas las vulnerabilidades y parches en un solo lugar es esencial para garantizar el cumplimiento de NERC."
        ]
      },
      "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.": {
        "msgid": "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.",
        "msgstr": [
          "La ventaja de Flexera One SaaS Management es que puedo obtener el análisis solicitado con cierta rapidez. Puedo obtenerlo hoy o mañana; no se necesita extraer los datos. Basta con apuntar y hacer clic para obtener la información."
        ]
      },
      "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.": {
        "msgid": "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.",
        "msgstr": [
          "Son muchas las formas en que las soluciones de Flexera One pueden ayudarle a sacar el máximo partido a su inversión en TI. Para saber cómo maximizar sus soluciones Flexera One, haga el recorrido guiado por Flexera One y consulte los recursos que aparecen a continuación."
        ]
      },
      "Tour Flexera One": {
        "msgid": "Tour Flexera One",
        "msgstr": [
          "Recorrido guiado por Flexera One"
        ]
      },
      "UPDATES PER DAY": {
        "msgid": "UPDATES PER DAY",
        "msgstr": [
          "ACTUALIZACIONES DIARIAS"
        ]
      },
      "VIDEO LIBRARY": {
        "msgid": "VIDEO LIBRARY",
        "msgstr": [
          "VIDEOTECA"
        ]
      },
      "View More": {
        "msgid": "View More",
        "msgstr": [
          "Ver más"
        ]
      },
      "What our customers say": {
        "msgid": "What our customers say",
        "msgstr": [
          "Testimonios de nuestros clientes"
        ]
      }
    }
  }
};
export default json;
