import { useAuthenticated } from '@flexera/auth.base';
import { SBOM } from '@flexera/shell.base-nav-tree';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	MenuItemDef,
	loadingItem,
	useNavItems
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const org = useCurrentOrg();
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasSbom,
		Permissions.canViewSbomBuckets,
		Permissions.canViewSbomCatalogs,
		Permissions.canViewSbomFiles,
		Permissions.canViewMetadataIndex,
		Permissions.canViewSbomParts,
		Permissions.canViewSbomPreferences,
		Permissions.canViewSbomSearchFilters,
		Permissions.canViewSbomWorkerJobs,
		Permissions.canViewSbomJobs
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasSbom)) return;

		const sbomMenuItems: MenuItemDef[] = [
			perms.get(Permissions.canViewSbomBuckets) && {
				id: ids.MANAGEBUCKETS,
				parentId: SBOM,
				label: t`Manage Buckets`,
				description: t`Manage SBOM Buckets`,
				priority: 37,
				path: `/orgs/${orgId}/sbom-management/manage-buckets`,
				urlMatch: /^\/orgs\/\d+\/sbom-management\/manage-buckets(\/|$|\?)/
			},
			perms.get(Permissions.canViewSbomParts) && {
				id: ids.MANAGEPARTS,
				parentId: SBOM,
				label: t`Manage SBOM Parts`,
				description: t`Manage SBOM Parts`,
				priority: 38,
				path: `/orgs/${orgId}/sbom-management/manage-parts`,
				urlMatch: /^\/orgs\/\d+\/sbom-management\/manage-parts(\/|$|\?)/
			},
			perms.get(Permissions.canViewSbomJobs) && {
				id: ids.SBOMJOBS,
				parentId: SBOM,
				label: t`Jobs`,
				description: t`SBOM Jobs`,
				priority: 39,
				path: `/orgs/${orgId}/sbom-management/jobs`,
				urlMatch: /^\/orgs\/\d+\/sbom-management\/jobs(\/|$|\?)/
			},
			perms.get(Permissions.canViewSbomPreferences) && {
				id: ids.SBOMPREFERENCES,
				parentId: SBOM,
				label: t`SBOM Preferences`,
				description: t`SBOM Preferences`,
				priority: 40,
				path: `/orgs/${orgId}/sbom-management/preferences`,
				urlMatch: /^\/orgs\/\d+\/sbom-management\/preferences(\/|$|\?)/
			}
		];

		return addItems([...sbomMenuItems].filter(Boolean));
	}, [org, orgId, authenticated, perms, loadingPerms]);
}
