import styled from 'styled-components';
import { collapsedNavWidth, expandedNavWidth } from '../constants/style';

interface NavMenuProps {
	isExpanded: boolean;
}

export const NavMenu = styled.nav<NavMenuProps>`
	grid-area: menu;
	grid-row: 2;
	grid-column: 1;
	transition: width 200ms;
	overflow-y: auto;
	width: ${(props) =>
		props.isExpanded
			? `${expandedNavWidth}`
			: `
					${collapsedNavWidth}
			  `};
`;
