import { lazyLoaded } from '@flexera/lib.utilities';

export const Dashboards = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).Dashboards };
});

export const Catalog = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).AutomationCatalog };
});

export const Templates = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).AutomationTemplates };
});

export const Incidents = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).AutomationIncidents };
});

export const AppliedPolicies = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).AppliedPolicies };
});

export const Credentials = lazyLoaded(async () => {
	const mod = import('@flexera/automation.main');
	return { default: (await mod).AutomationCredentials };
});
