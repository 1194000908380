import { ResponseSchema, ResponseData } from '@flexera/data-visualization.data';
import { fetchProductData, Product } from '@flexera/services.vendor';

const getSchema = () => {
	const schema: ResponseSchema[] = [
		{
			name: 'productName',
			type: 'string'
		},
		{
			name: 'count',
			type: 'number'
		}
	];

	return schema;
};

const reorderProducts = (products: Product[]) => {
	return products.sort((a, b) => {
		return b.count - a.count;
	});
};

export async function getProductData(orgId: number): Promise<ResponseData> {
	const response = await fetchProductData(orgId);
	if (response.status !== 200) {
		return {
			error: {
				statusCode: response.status.toString(),
				message: `${response.statusText}`
			}
		};
	}

	return {
		data: reorderProducts(response.data),
		schema: getSchema()
	};
}
