import { useOrgId } from '@flexera/shell.orgs';
import { useEffect, useState } from 'react';
import { useCapabilities } from '@flexera/lib.permissions';
import { UATContextValue } from './UATContextValue';

export function useFetchUAT() {
	const [uat, setUAT] = useState<UATContextValue>({ loading: true });
	const orgId = useOrgId();
	const [currentCapabilities, loadingCapabilities] = useCapabilities(
		`/orgs/${orgId}`
	);

	useEffect(() => {
		if (!orgId) {
			setUAT({ ...uat, loading: false });
			return;
		}

		if (currentCapabilities.length === 0 && !loadingCapabilities) {
			setUAT({ ...uat, loading: false });
			return;
		}

		setUAT({ ...uat, loading: true });

		if (currentCapabilities.length === 0 || loadingCapabilities) return;

		const mappedCapabilities = currentCapabilities.map((item) =>
			item.split('-').includes('uat')
		);

		const atLeastOneCapabilityIsUAT = mappedCapabilities.includes(true);

		setUAT({
			loading: false,
			isUAT: atLeastOneCapabilityIsUAT
		});
	}, [orgId, currentCapabilities, loadingCapabilities]);

	return uat;
}
