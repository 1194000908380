import { FC } from 'react';
import styled from 'styled-components';
import { Flex, BaseTheme, Button } from '@flexera/ui.component-library';

interface ButtonProps {
	disabled?: boolean;
}

interface CarousalProps {
	padding?: string;
	margin?: string;
}

interface CarouselItemsWrapperProps {
	isStacked?: boolean;
}

export const Carousel = styled.div<CarousalProps>`
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-x: auto;
	padding: ${({ padding }) =>
		padding || `${BaseTheme.space.xlg} 0 5rem ${BaseTheme.space.md}`};
	margin: ${({ margin }) =>
		margin || `-${BaseTheme.space.xlg} 0 -5rem ${BaseTheme.space.xs}`};
`;

export const CarouselItemsWrapper = styled(Flex)<CarouselItemsWrapperProps>`
	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		flex-direction: ${({ isStacked }) => (isStacked ? 'row' : 'column')};
		flex-wrap: ${({ isStacked }) => (isStacked ? 'wrap' : 'unset')};
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		flex-direction: column;
	}
`;

export const Actions: FC = styled(Flex)`
	padding-right: ${BaseTheme.space.lg};
	align-items: center;
	margin: ${BaseTheme.space.xlg} 0 ${BaseTheme.space.md};

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		display: none;
	}
`;

export const IconButton = styled(Button)<ButtonProps>`
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	background: unset !important;
	${(props) =>
		props.disabled &&
		`svg {
		path[fill='#000'] {
			fill: ${BaseTheme.colors.gray20};
		}
	}`};

	&:first-of-type {
		margin-right: ${BaseTheme.space.xs};
	}
	&:focus {
		border: ${({ disabled }) =>
			disabled
				? 'unset'
				: `${BaseTheme.borders.sm} ${BaseTheme.colors.blue30}`} !important;
		border-radius: ${({ disabled }) =>
			disabled ? 'unset' : BaseTheme.radii.button};
	}

	&:hover {
		background: ${({ disabled }) =>
			disabled ? 'unset' : BaseTheme.colors.gray20} !important;
		border-radius: ${({ disabled }) =>
			disabled ? 'unset' : BaseTheme.radii.button};
	}

	&:active {
		background: ${({ disabled }) =>
			disabled ? 'unset' : BaseTheme.colors.gray15} !important;
		border-radius: ${({ disabled }) =>
			disabled ? 'unset' : BaseTheme.radii.button};
	}
`;

export const ScrollButton = styled(Button)<ButtonProps>`
	${(props) =>
		props.disabled &&
		`svg {
	path[fill='#000'] {
		fill: ${BaseTheme.colors.gray30};
	}
}`};
`;
