const makeId = (id: string) => `appBroker.bootstrap${id}`;

export const LOADING = makeId('.loading');
export const BASE = makeId('');

export const PORTAL_MANAGEMENT = makeId('/manage');
export const DASHBOARD = makeId('/manage/dashboard');
export const CATALOG_MANAGEMENT = makeId('/manage/catalog');
export const CATALOG_MANAGEMENT_CREATE = makeId('/manage/catalog/create');
export const CATALOG_MANAGEMENT_EDIT = makeId('/manage/catalog/new');
export const REQUEST_MANAGEMENT = makeId('/manage/requests');
export const CONNECTIONS_MANAGEMENT = makeId('/manage/connections');

export const CATALOG = makeId('/request/catalog');
export const REQUESTS = makeId('/request/requests');
