/* eslint-disable react/display-name */
import React from 'react';
import { lazyLoaded } from '@flexera/lib.utilities';

// Dashboard

export const SaasDashboard = lazyLoaded(async () => {
	const mod = import('@flexera/saas.dashboard');
	return { default: (await mod).SaasDashboard };
});

// Applications section

export const SaasAppCatalog = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).AppCatalog };
});

export const SaasAppDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).AppDetail };
});

export const SaasAppSetup = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).AppSetup };
});

export const SaasAppLicenses = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).LicensesWrapper };
});

export const SaasOverview = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).OverviewSalesforce };
});

export const SaasOverviewLicenses = lazyLoaded(async () => {
	const mod = import('@flexera/saas.managed-apps');
	return { default: (await mod).OverviewLicensesWrapper };
});

export const SaasLicenses = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'licenses'} /> };
});

export const SaasLicenseDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'license/:id'} /> };
});

export const SaasAppUsage = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'app-usage'} /> };
});

export const SaasDiscoveredProducts = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'discovered-products'} /> };
});

// Users section

export const SaasCustomAgents = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer-agents'} /> };
});

export const SaasCustomAgentCreate = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer-agent'} /> };
});

export const SaasCustomAgentDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer-agent/:id'} /> };
});

export const SaasInactiveUsers = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'inactive-users'} /> };
});

export const SaasSuspiciousUsers = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'suspicious-users'} /> };
});

export const SaasUserUsage = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'user-usage'} /> };
});

// Settings section

export const SaasCustomerSettings = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer-settings'} /> };
});

export const SaasOrgStructure = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'org-structure-edit'} /> };
});

export const SaasAuditLogs = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'audit-logs'} /> };
});

// Admin section

export const SaasProductList = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'products'} /> };
});

export const SaasProductCreate = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'product'} /> };
});

export const SaasProductDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'product/:id'} /> };
});

export const SaasCustomerList = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customers'} /> };
});

export const SaasCustomerCreate = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer'} /> };
});

export const SaasCustomerDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'customer/:id'} /> };
});

export const SaasFinancialDiscovery = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'discovered-product-qa'} /> };
});

export const SaasSsoMapping = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'sso-mapping'} /> };
});

export const SaasVendorList = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'vendors'} /> };
});

export const SaasVendorCreate = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'vendor'} /> };
});

export const SaasVendorDetail = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'vendor/:id'} /> };
});

// Misc

export const SaasIntegrationReturn = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	return { default: (await mod).SaasIntegration };
});

export const SaasReclamationResponse = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	return { default: (await mod).SaasReclamation };
});

export const SaasIntegrationAgent = lazyLoaded(async () => {
	const mod = import('@flexera/saas.core');
	const View = (await mod).SaasViewer;
	return { default: () => <View id={'integration-agent'} /> };
});
