import {
	Flex,
	Box,
	H3,
	Button,
	BaseTheme
} from '@flexera/ui.component-library';
import { useUserId } from '@flexera/auth.base';
import React from 'react';
import { t } from 'ttag';
import { history } from '@flexera/lib.router-history';

export const OrgMissingMessage = () => {
	const userId = useUserId();
	return (
		<Flex p={'2rem'}>
			<Box
				textAlign={'center'}
				bg={'light'}
				boxShadow={'small'}
				border={'sm'}
				borderColor={'grey.2'}
				borderRadius={'md'}
				width={'100%'}
				maxHeight={'9rem'}
			>
				<H3>{t`This org does not exist or you do not have access to this org.`}</H3>
				<Button
					id={'refresh-org-storage'}
					type={'button'}
					title={'Click to return'}
					onClick={() => {
						localStorage.removeItem('gtm.currentOrg.id');
						localStorage.removeItem('gtm.currentOrg.name');
						localStorage.removeItem(`org.current.${userId}`);
						history.push('/');
					}}
					mb={BaseTheme.space.xlg}
				>
					Return to Home
				</Button>
			</Box>
		</Flex>
	);
};
