import { FilterOption, ResponseSchema } from '@flexera/data-visualization.data';
import { ManagedAppsClient } from '@flexera/saas.api-client';

/**
 * The available filters for SaaS metrics data
 */
export interface SaasManagedAppsFilter {
	/** Query only active or inactive apps */
	active?: boolean;
	/** Filters apps by product */
	product?: string;
}

const schema: ResponseSchema[] = [
	{ name: 'annualCost', type: 'number' },
	{ name: 'totalActive', type: 'number' },
	{ name: 'totalLicenses', type: 'number' },
	{ name: 'totalUsers', type: 'number' }
];

export async function getManagedApps(
	orgId: number,
	filterOption: FilterOption,
	filter: SaasManagedAppsFilter
) {
	const result = await ManagedAppsClient.getManagedApps(orgId, {
		active: filter.active,
		product: filter.product
	});

	const data = (result?.values || []).map((app) => {
		// ensure valid data, to be fixed in API later
		return {
			...app,
			...{
				annualCost: app.annualCost || 0,
				totalActive: app.totalActive || 0,
				totalLicenses: app.totalLicenses || 0,
				totalUsers: app.totalUsers || 0
			}
		};
	});

	return {
		data,
		schema
	};
}
