import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { BusinessLayout } from './components';
import * as ids from './menuIds';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: ids.BUSINESS,
				parentId: OrgsRouteId,
				path: '/business-services',
				exact: true,
				component: BusinessLayout
			}
		]);
	}, []);
}
