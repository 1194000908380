const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Anpassungsprogramm"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Kostenstellen"
        ]
      },
      "Billing Centers (Beta)": {
        "msgid": "Billing Centers (Beta)",
        "msgstr": [
          "Kostenstellen (Beta)"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Konfiguration der Rechnungsstellung"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Budgets"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Beta": {
        "msgid": "Cloud Beta",
        "msgstr": [
          "Cloud Beta"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Kostenanomalien der Cloud"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planung der Cloud-Migration"
        ]
      },
      "Coming Soon": {
        "msgid": "Coming Soon",
        "msgstr": [
          "Demnächst verfügbar"
        ]
      },
      "Custom Tags": {
        "msgid": "Custom Tags",
        "msgstr": [
          "Benutzerdefinierte Tags"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimierung"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Reservierte Instanzen"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Gesamteinsparpotenzial"
        ]
      }
    }
  }
};
export default json;
