import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	ADMIN__SAAS,
	DASHBOARDS,
	ORGANIZATION,
	SAAS__APPLICATIONS,
	SAAS__LICENSE_MANAGEMENT
} from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const { addItems } = useNavItems();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const orgScope = `/orgs/${orgId}`;

	const [perms, loadingPerms] = usePermissionSummary(
		orgScope,
		Permissions.canShowSaasDashboard,
		Permissions.canShowSaasManagedProducts,
		Permissions.canShowSaasLicenses,
		Permissions.canShowSaasUnsanctionedProducts,
		Permissions.canShowSaasProductActivityStats,
		Permissions.canShowSaasCustomerAgents,
		Permissions.canShowSaasAbandonedUserStats,
		Permissions.canShowSaasSuspiciousAgents,
		Permissions.canShowSaasGroupActivityStats,
		Permissions.canEditSaasCustomerSettings,
		Permissions.canShowSaasCustomerGroups,
		Permissions.canShowSaasAuditLogs,
		Permissions.canEditSaasProduct,
		Permissions.canShowSaasCustomers,
		Permissions.canQaSaasUnsanctionedProducts,
		Permissions.canEditSaasSsoMappings,
		Permissions.canEditSaasVendor,
		Permissions.hasSaasManager
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const canViewSaasDashboard = perms.get(Permissions.canShowSaasDashboard);
		const canViewSaasManagedProductList = perms.get(
			Permissions.canShowSaasManagedProducts
		);
		const canViewSaasLicenseList = perms.get(Permissions.canShowSaasLicenses);
		const canViewSaasAppUsage = perms.get(
			Permissions.canShowSaasProductActivityStats
		);
		const canViewSaasDiscoveredProductList = perms.get(
			Permissions.canShowSaasUnsanctionedProducts
		);
		const canViewSaasCustomerAgentList = perms.get(
			Permissions.canShowSaasCustomerAgents
		);
		const canViewSaasInactiveUserList = perms.get(
			Permissions.canShowSaasAbandonedUserStats
		);
		const canViewSaasSuspiciousUserList = perms.get(
			Permissions.canShowSaasSuspiciousAgents
		);
		const canViewSaasOrgStructure = perms.get(
			Permissions.canShowSaasCustomerGroups
		);
		const canViewSaasAuditLog = perms.get(Permissions.canShowSaasAuditLogs);
		const canViewSaasProductList = perms.get(Permissions.canEditSaasProduct);
		const canViewSaasFinancialDiscovery = perms.get(
			Permissions.canQaSaasUnsanctionedProducts
		);
		const canViewSaasSsoMapping = perms.get(Permissions.canEditSaasSsoMappings);
		const canViewSaasVendorList = perms.get(Permissions.canEditSaasVendor);
		const canViewSaasCustomerList = perms.get(Permissions.canShowSaasCustomers);
		const canViewSaasUserUsage = perms.get(
			Permissions.canShowSaasGroupActivityStats
		);
		const canViewSaasCustomerSettings = perms.get(
			Permissions.canEditSaasCustomerSettings
		);

		const canViewAnyApplicationItems =
			canViewSaasManagedProductList ||
			canViewSaasLicenseList ||
			canViewSaasAppUsage ||
			canViewSaasDiscoveredProductList;

		const canViewAnyUserItems =
			canViewSaasCustomerAgentList ||
			canViewSaasInactiveUserList ||
			canViewSaasSuspiciousUserList ||
			canViewSaasUserUsage;

		const canViewAnySettingItems =
			canViewSaasCustomerSettings ||
			canViewSaasOrgStructure ||
			canViewSaasAuditLog;

		const canViewAnyAdminItems =
			canViewSaasProductList ||
			canViewSaasCustomerList ||
			canViewSaasFinancialDiscovery ||
			canViewSaasSsoMapping ||
			canViewSaasVendorList;

		const navItems = []; // : MenuItem[] = [];

		if (perms.get(Permissions.hasSaasManager)) {
			if (canViewSaasDashboard) {
				navItems.push({
					id: ids.DASHBOARD,
					parentId: DASHBOARDS,
					path: `/orgs/${org.id}/saas/dashboard`,
					urlMatch: /^\/orgs\/\d+\/saas\/dashboard(\/|$|\?)/,
					label: t`SaaS`,
					priority: 20
				});
			}

			if (canViewAnyApplicationItems) {
				if (canViewSaasManagedProductList) {
					navItems.push({
						parentId: SAAS__APPLICATIONS,
						id: ids.MANAGED,
						path: `/orgs/${orgId}/saas/managed-products`,
						urlMatch: /^\/orgs\/\d+\/saas\/managed-products$/,
						label: t`Managed SaaS Applications`,
						priority: 7
					});

					navItems.push({
						parentId: ids.MANAGED,
						id: ids.MANAGED_ADD,
						path: `/orgs/${orgId}/saas/managed-products`,
						urlMatch: /^\/orgs\/\d+\/saas\/managed-products\/new$/,
						label: t`Add Application`,
						hidden: true
					});

					navItems.push({
						parentId: ids.MANAGED,
						id: ids.MANAGED_DETAILS,
						path: `/orgs/${orgId}/saas/managed-products/:saas_id`,
						urlMatch: /^\/orgs\/\d+\/saas\/managed-products\/(|$|\?)/,
						hidden: true,
						variableTitle: true
					});

					navItems.push({
						parentId: ids.MANAGED,
						id: ids.MANAGED_OVERVIEW,
						path: `/orgs/${orgId}/saas/managed-products/overview/product/:productId`,
						urlMatch: /^\/orgs\/\d+\/saas\/managed-products\/overview\/products\/(|$|\?)/,
						hidden: true,
						variableTitle: true
					});
				}

				if (canViewSaasLicenseList) {
					navItems.push({
						parentId: SAAS__LICENSE_MANAGEMENT,
						id: ids.LICENSES,
						path: `/orgs/${orgId}/saas/licenses`,
						urlMatch: /^\/orgs\/\d+\/saas\/licenses(\/|$|\?)/,
						label: t`All SaaS Licenses`,
						priority: 1
					});
				}

				if (canViewSaasAppUsage) {
					navItems.push({
						parentId: SAAS__LICENSE_MANAGEMENT,
						id: ids.USAGE,
						path: `/orgs/${orgId}/saas/app-usage`,
						urlMatch: /^\/orgs\/\d+\/saas\/app-usage(\/|$|\?)/,
						label: t`SaaS License Usage`,
						priority: 2
					});
				}

				if (canViewSaasDiscoveredProductList) {
					navItems.push({
						parentId: SAAS__APPLICATIONS,
						id: ids.UNSANCTIONED,
						path: `/orgs/${orgId}/saas/discovered-products`,
						urlMatch: /^\/orgs\/\d+\/saas\/discovered-products(\/|$|\?)/,
						label: t`Unsanctioned SaaS Applications`,
						priority: 8
					});
				}
			}

			if (canViewAnyUserItems) {
				if (canViewSaasCustomerAgentList) {
					navItems.push({
						parentId: ORGANIZATION,
						id: ids.ALL_USERS,
						path: `/orgs/${orgId}/saas/customer-agents`,
						urlMatch: /^\/orgs\/\d+\/saas\/customer-agents(\/|$|\?)/,
						label: t`All SaaS Users`,
						priority: 10
					});
				}

				if (canViewSaasInactiveUserList) {
					navItems.push({
						parentId: SAAS__LICENSE_MANAGEMENT,
						id: ids.UNDERUTILIZED_USERS,
						path: `/orgs/${orgId}/saas/inactive-users`,
						urlMatch: /^\/orgs\/\d+\/saas\/inactive-users(\/|$|\?)/,
						label: t`Underutilized SaaS Accounts`,
						priority: 3
					});
				}

				if (canViewSaasSuspiciousUserList) {
					navItems.push({
						parentId: SAAS__LICENSE_MANAGEMENT,
						id: ids.SUSPICIOUS_USERS,
						path: `/orgs/${orgId}/saas/suspicious-users`,
						urlMatch: /^\/orgs\/\d+\/saas\/suspicious-users(\/|$|\?)/,
						label: t`Suspicious SaaS Activities`,
						priority: 4
					});
				}

				if (canViewSaasUserUsage) {
					navItems.push({
						parentId: SAAS__LICENSE_MANAGEMENT,
						id: ids.ORGANIZATION_USAGE,
						path: `/orgs/${orgId}/saas/user-usage`,
						urlMatch: /^\/orgs\/\d+\/saas\/user-usage(\/|$|\?)/,
						label: t`Organization SaaS Usage`,
						priority: 5
					});
				}
			}

			if (canViewAnySettingItems || canViewAnyAdminItems) {
				if (canViewSaasCustomerSettings) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.GENERAL_SETTINGS,
						path: `/orgs/${orgId}/saas/customer-settings`,
						urlMatch: /^\/orgs\/\d+\/saas\/customer-settings(\/|$|\?)/,
						label: t`General`
					});
				}

				if (canViewSaasOrgStructure) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.ORG_SETTINGS,
						path: `/orgs/${orgId}/saas/org-structure-edit`,
						urlMatch: /^\/orgs\/\d+\/saas\/org-structure-edit(\/|$|\?)/,
						label: t`Organization`
					});
				}

				if (canViewSaasAuditLog) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.AUDIT_SETTINGS,
						path: `/orgs/${orgId}/saas/audit-logs`,
						urlMatch: /^\/orgs\/\d+\/saas\/audit-logs(\/|$|\?)/,
						label: t`Audit Logs`
					});
				}

				if (canViewSaasProductList) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.APP_SETTINGS,
						path: `/orgs/${orgId}/saas/products`,
						urlMatch: /^\/orgs\/\d+\/saas\/products(\/|$|\?)/,
						label: t`Applications`
					});
				}

				if (canViewSaasCustomerList) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.CUSTOMER_SETTINGS,
						path: `/orgs/${orgId}/saas/customers`,
						urlMatch: /^\/orgs\/\d+\/saas\/customers(\/|$|\?)/,
						label: t`Customers`
					});
				}

				if (canViewSaasFinancialDiscovery) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.FINANCIAL_SETTINGS,
						path: `/orgs/${orgId}/saas/discovered-product-qa`,
						urlMatch: /^\/orgs\/\d+\/saas\/discovered-product-qa(\/|$|\?)/,
						label: t`Financial QA`
					});
				}

				if (canViewSaasSsoMapping) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.SSO_SETTINGS,
						path: `/orgs/${orgId}/saas/sso-mapping`,
						urlMatch: /^\/orgs\/\d+\/saas\/sso-mapping(\/|$|\?)/,
						label: t`SSO Naming`
					});
				}

				if (canViewSaasVendorList) {
					navItems.push({
						parentId: ADMIN__SAAS,
						id: ids.VENDORS_SETTINGS,
						path: `/orgs/${orgId}/saas/vendors`,
						urlMatch: /^\/orgs\/\d+\/saas\/vendors(\/|$|\?)/,
						label: t`Vendors`
					});
				}
			}
		}

		return addItems(navItems);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
