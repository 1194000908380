const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Business Service Mapping": {
        "msgid": "Business Service Mapping",
        "msgstr": [
          "Asignación de servicios empresariales"
        ]
      },
      "Connect to RISC Networks": {
        "msgid": "Connect to RISC Networks",
        "msgstr": [
          "Conectar con redes RISC"
        ]
      }
    }
  }
};
export default json;
