import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { SvrLayout } from './components';
import * as ids from './menuIds';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: ids.SVR,
				parentId: OrgsRouteId,
				path: '/svr',
				exact: true,
				component: SvrLayout
			}
		]);
	}, []);
}
