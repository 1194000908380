const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Favorites": {
        "msgid": "Favorites",
        "msgstr": [
          "Favorites"
        ]
      },
      "Home": {
        "msgid": "Home",
        "msgstr": [
          "Home"
        ]
      }
    }
  }
};
export default json;
