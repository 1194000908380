import styled, { css } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import {
	activeBackground,
	activeLeftBorder,
	navFooterButtonStyle,
	expandedNavWidth,
	collapsedNavWidth,
	shadow3d
} from './constants/style';
import { PinButtonBox } from './PinButton/PinButton.styled';
import { SubList } from './Submenu/Submenu.styled';

interface NavProps {
	isExpanded?: boolean;
	hasNoAccess?: boolean;
	selector?: string;
}

export const NavigationWrapper = styled.aside<NavProps>`
	${({ selector, hasNoAccess }) => {
		return css`
			--flyout-width: 240px;
			--pinned-nav-width: calc(var(--flyout-width) + 54px);
			--width-transition: width 200ms;
			grid-area: Navigation;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			height: 100vh;
			transition: var(--width-transition);
			width: ${hasNoAccess ? `0` : `${expandedNavWidth}`};
			background: #0a1024;
			z-index: 151;
			color: #fff;
			font-family: 'Source Sans Pro', 'Helvetica', Sans-serif;
			font-size: 14px;
			line-height: 18px;
			${shadow3d}
			${PinButtonBox} {
				width: 0px;
			}

			&.collapsed {
				width: ${hasNoAccess ? `0` : `${collapsedNavWidth}`};
			}

			&.pinned {
				transition: var(--width-transition), margin-right 200ms;
				width: ${hasNoAccess ? `0` : `${collapsedNavWidth}`};
				margin-right: var(--flyout-width);
				& li {
					& div.flyout-drawer {
						width: 0px;
						${SubList}, ${PinButtonBox} {
							display: none;
						}
						.resizer-button {
							display: none;
						}
					}
				}
				& li.${selector} {
					background: ${activeBackground};
					border-left: ${activeLeftBorder};
					& div.flyout-drawer {
						z-index: 160;
						width: var(--flyout-width);
						${SubList} {
							display: block;
						}
						${PinButtonBox} {
							display: flex;
						}
						.resizer-button {
							display: block;
						}
					}
				}
				& li.${selector} div.flyout-drawer ${PinButtonBox} {
					width: calc(100% - 2px);
				}
			}
		`;
	}}
`;

export const NavigationLogo = styled.header<NavProps>`
	${({ isExpanded }) => {
		return css`
			display: flex;
			flex-flow: column nowrap;
			transition: var(--width-transition);
			width: ${isExpanded ? `100%` : `${collapsedNavWidth}`};
			a.whitelabel-logo {
				display: flex;
				justify-content: center;
				margin: 20px 0;
			}
			a.flexera-logo {
				display: flex;
				justify-content: center;
				align-items: ${isExpanded ? `baseline` : `center`};
				margin: 20px 0;
				height: 28px;
				img {
					height: 28px;
				}
				span.expanded-logo {
					transition: opacity 200ms linear 200ms, var(--width-transition);
					opacity: 1;
					${!isExpanded &&
					css`
						width: 0px;
						opacity: 0;
					`}
					img {
						margin: 0;
						width: auto;
					}
				}
			}
		`;
	}}
`;

export const NavFooter = styled.footer<NavProps>`
	box-sizing: border-box;
	display: flex;
	align-self: flex-end;
	margin-top: auto;
	justify-content: ${({ isExpanded }) => (isExpanded ? 'flex-end' : 'center')};
	transition: var(--width-transition);
	width: ${(props) =>
		props.isExpanded
			? css`
					${expandedNavWidth}
			  `
			: css`
					${collapsedNavWidth}
			  `};
	${navFooterButtonStyle}

	button {
		right: 0;
		padding: 0 0.7rem;
		svg {
			width: 20px;
			height: 20px;
			transition: transform 200ms;
			transform: ${(props) =>
				props.isExpanded ? css`rotate(-180deg)` : css`unset`};
		}
		&:hover {
			svg {
				path#prefix__Path {
					fill: ${BaseTheme.colors.primaryHover};
				}
			}
		}

		&:disabled {
			svg,
			&:hover {
				path#prefix__Path {
					fill: #23283a;
				}
			}
		}
	}
`;
