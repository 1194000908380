import React, { FC } from 'react';
import {
	Icon,
	IconMdSettings,
	IconMdEmail,
	IconMdCopyDoc,
	IconMdCloud,
	IconMdPayment,
	IconFxConnector,
	IconMdHardware,
	IconMdAdministrator
} from '@flexera/ui.component-library';
import External from '../../assets/external.svg';
import ITVSVG from '../../assets/itv.svg';
import ITManagement from '../../assets/it-management.svg';
import SaasSVG from '../../assets/saas.svg';
import AssetRequestsSVG from '../../assets/it-asset-requests.svg';
import PoliciesSVG from '../../assets/policies.svg';
import TechnologySpend from '../../assets/technology-spend.svg';
import ApiIcon from '../../assets/api.svg';

interface IconPair {
	[key: string]: FC;
}

export const getIcon = (icon: string): JSX.Element => {
	const Icons: IconPair = {
		IconMdSettings,
		IconMdEmail,
		IconMdCopyDoc,
		IconMdCloud,
		IconMdExternal: External,
		IconMdSaas: SaasSVG,
		IconMdITV: ITVSVG,
		IconMdITManagement: ITManagement,
		IconMdAssetRequests: AssetRequestsSVG,
		IconMdPayment,
		IconFxConnector,
		IconMdAdministrator,
		IconMdHardware,
		IconMdPolicies: PoliciesSVG,
		IconMdTechSpend: TechnologySpend,
		IconMdAPI: ApiIcon
	};

	return <Icon id={'icon'} src={Icons[icon]} />;
};
