import { useEffect } from 'react';
import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import {
	PluginsListLayout,
	BulkRegisterLayout
} from '@flexera/cloud-management.plugin-registration';
import { ResourceLayout, PluginCatalogLayout } from './CloudManagementApp';

const packageName = 'cloud-management.bootstrap';
const capabilityPath = '/cloud';
const adminPath = '/admin';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: `${packageName}/resources`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/resources`,
				exact: true,
				component: ResourceLayout
			},
			{
				id: `${packageName}/resources/project`,
				parentId: OrgsRouteId,
				path: `${capabilityPath}/resources/project/:project`,
				exact: true,
				component: ResourceLayout
			},
			{
				id: `${packageName}/plugins/catalog`,
				parentId: OrgsRouteId,
				path: `${adminPath}/plugins/catalog`,
				exact: true,
				component: PluginCatalogLayout
			},
			{
				id: `${packageName}/plugins/register`,
				parentId: OrgsRouteId,
				path: `${adminPath}/plugins/register`,
				exact: false,
				component: BulkRegisterLayout
			},
			{
				id: `${packageName}/plugins/registered`,
				parentId: OrgsRouteId,
				path: `${adminPath}/plugins/registered`,
				exact: true,
				component: PluginCatalogLayout
			},
			{
				id: `${packageName}/plugins/list`,
				parentId: OrgsRouteId,
				path: `${adminPath}/plugins/list`,
				exact: false,
				component: PluginsListLayout
			}
		]);
	}, []);
}
