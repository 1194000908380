import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { useITVExportsv2 } from '@flexera/flexera-one.feature-flags';
import {
	ITVConnections,
	ITVCustomDashboard,
	ITVExportsv3,
	ITVExports,
	ITVDataMashup,
	SoftwareEvidence,
	SoftwareEvidenceDetail,
	HardwareEvidence,
	PowerBIDashboard,
	PrivateCatalogEvidence,
	PrivateCatalogEvidenceFiltered,
	Devices,
	DeviceDetail,
	PrivateCatalogReleases,
	PrivateCatalogReleasesDetail
} from './ITVisibilityApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	const hasITVExportsv3 = useITVExportsv2();

	useEffect(() => {
		return addRoutes([
			{
				id: 'itv.bootstrap/private-catalog-releases-detail',
				parentId: OrgsRouteId,
				path: '/itv/private-catalog-releases/:releaseId/:tabView?',
				exact: true,
				component: PrivateCatalogReleasesDetail
			},
			{
				id: 'itv.bootstrap/device-detail',
				parentId: OrgsRouteId,
				path: '/itv/devices/:deviceId/:tabView?',
				exact: true,
				component: DeviceDetail
			},
			{
				id: 'itv.bootstrap/software-evidence-detail',
				parentId: OrgsRouteId,
				path: '/itv/software-evidence/:evidenceId/:tabView?',
				exact: true,
				component: SoftwareEvidenceDetail
			},
			{
				id: 'itv.bootstrap/custom-dashboard',
				parentId: OrgsRouteId,
				path: '/itv/custom-dashboard/:linked_dashboard_id?',
				exact: false,
				component: ITVCustomDashboard
			},
			{
				id: 'itv.bootstrap/powerbi-dashboard',
				parentId: OrgsRouteId,
				path: '/vis/report/:dashboard_name',
				exact: true,
				component: PowerBIDashboard
			},
			{
				id: 'itv.bootstrap/add-custom-dashboard',
				parentId: OrgsRouteId,
				path: '/itv/custom-dashboard/add',
				exact: false,
				component: ITVCustomDashboard
			},
			{
				id: 'itv.bootstrap/explorer-custom-dashboard',
				parentId: OrgsRouteId,
				path: '/itv/custom-dashboard/explorer',
				exact: false,
				component: ITVCustomDashboard
			},
			{
				id: 'itv.bootstrap/exports',
				parentId: OrgsRouteId,
				path: '/itv/exports',
				exact: false,
				component: hasITVExportsv3 ? ITVExportsv3 : ITVExports
			},
			{
				id: 'itv.bootstrap/connections',
				parentId: OrgsRouteId,
				path: '/itv/connections',
				exact: false,
				component: ITVConnections
			},
			{
				id: 'itv.bootstrap/data-mashup',
				parentId: OrgsRouteId,
				path: '/itv/data-mashup',
				exact: false,
				component: ITVDataMashup
			},
			{
				id: 'itv.bootstrap/software-evidence',
				parentId: OrgsRouteId,
				path: '/itv/software-evidence',
				exact: false,
				component: SoftwareEvidence
			},
			{
				id: 'itv.bootstrap/hardware-evidence',
				parentId: OrgsRouteId,
				path: '/itv/hardware-evidence',
				exact: false,
				component: HardwareEvidence
			},
			{
				id: 'itv.bootstrap/private-catalog-evidence',
				parentId: OrgsRouteId,
				path: '/itv/private-catalog-evidence',
				exact: true,
				component: PrivateCatalogEvidence
			},
			{
				id: 'itv.bootstrap/private-catalog-evidence-type',
				parentId: OrgsRouteId,
				path: '/itv/private-catalog-evidence/:filterType?',
				exact: true,
				component: PrivateCatalogEvidenceFiltered
			},
			{
				id: 'itv.bootstrap/devices',
				parentId: OrgsRouteId,
				path: '/itv/devices',
				exact: false,
				component: Devices
			},
			{
				id: 'itv.bootstrap/private-catalog-releases',
				parentId: OrgsRouteId,
				path: '/itv/private-catalog-releases',
				exact: false,
				component: PrivateCatalogReleases
			}
		]);
	}, [hasITVExportsv3]);
}
