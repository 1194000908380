const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Add Application": {
        "msgid": "Add Application",
        "msgstr": [
          "Add Application"
        ]
      },
      "All SaaS Licenses": {
        "msgid": "All SaaS Licenses",
        "msgstr": [
          "All SaaS Licenses"
        ]
      },
      "All SaaS Users": {
        "msgid": "All SaaS Users",
        "msgstr": [
          "All SaaS Users"
        ]
      },
      "Applications": {
        "msgid": "Applications",
        "msgstr": [
          "Applications"
        ]
      },
      "Audit Logs": {
        "msgid": "Audit Logs",
        "msgstr": [
          "Audit Logs"
        ]
      },
      "Customers": {
        "msgid": "Customers",
        "msgstr": [
          "Customers"
        ]
      },
      "Financial QA": {
        "msgid": "Financial QA",
        "msgstr": [
          "Financial QA"
        ]
      },
      "General": {
        "msgid": "General",
        "msgstr": [
          "General"
        ]
      },
      "Managed SaaS Applications": {
        "msgid": "Managed SaaS Applications",
        "msgstr": [
          "Managed SaaS Applications"
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organization"
        ]
      },
      "Organization SaaS Usage": {
        "msgid": "Organization SaaS Usage",
        "msgstr": [
          "Organization SaaS Usage"
        ]
      },
      "SSO Naming": {
        "msgid": "SSO Naming",
        "msgstr": [
          "SSO Naming"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS License Usage": {
        "msgid": "SaaS License Usage",
        "msgstr": [
          "SaaS License Usage"
        ]
      },
      "Suspicious SaaS Activities": {
        "msgid": "Suspicious SaaS Activities",
        "msgstr": [
          "Suspicious SaaS Activities"
        ]
      },
      "Underutilized SaaS Accounts": {
        "msgid": "Underutilized SaaS Accounts",
        "msgstr": [
          "Underutilized SaaS Accounts"
        ]
      },
      "Unsanctioned SaaS Applications": {
        "msgid": "Unsanctioned SaaS Applications",
        "msgstr": [
          "Unsanctioned SaaS Applications"
        ]
      },
      "Vendors": {
        "msgid": "Vendors",
        "msgstr": [
          "Vendors"
        ]
      }
    }
  }
};
export default json;
