const makeId = (id: string) => `itv.bootstrap${id}`;

export const BASE = makeId('');
export const LOADING = makeId('.loading');

export const CONNECTIONS = makeId('/connections');
export const DASHBOARD = makeId('/dashboard');
export const SKODASHBOARD = makeId('/skodashboard');
export const CUSTOMDASHBOARD = makeId('/customdashboard');
export const POWERBIDASHBOARD = makeId('/powerbidashboard');
export const ADD = makeId('/add');
export const CUSTOMEXPLORER = makeId('/customexplorer');
export const EXPLORER = makeId('/explorer');
export const EXPORTS = makeId('/exports');
export const CSVUPLOADER = makeId('/csvuploader');
export const SOFTWAREEVIDENCE = makeId('/softwareevidence');
export const SOFTWAREEVIDENCEDETAIL = makeId('/softwareevidencedetail');
export const HARDWAREEVIDENCE = makeId('/hardwareevidence');
export const PRIVATECATALOGEVIDENCE = makeId('/privatecatalogevidence');
export const PRIVATECATALOGEVIDENCECOUNTTYPES = makeId(
	'/privatecatalogevidencecounttypes'
);
export const DEVICES = makeId('/devices');
export const DEVICEDETAIL = makeId('/devicedetail');
export const PRIVATECATALOGRELEASES = makeId('/privatecatalogreleases');
export const PRIVATECATALOGRELEASESDETAIL = makeId(
	'/privatecatalogreleasesdetail'
);
