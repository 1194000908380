import { useAuthenticated } from '@flexera/auth.base';
import { usePermissionSummary, Permissions } from '@flexera/lib.permissions';
import { IconMdWorkspaces } from '@flexera/ui.component-library';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	// Permissions
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.commonOrgOwner,
		Permissions.hasVendor,
		Permissions.canSeeVendorSoftware,
		Permissions.canSeeVendorAzureRI,
		Permissions.canSeeVendorLicenses
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (perms) {
			const authorized =
				perms.get(Permissions.commonOrgOwner) ||
				perms.get(Permissions.canSeeVendorSoftware) ||
				perms.get(Permissions.canSeeVendorAzureRI) ||
				perms.get(Permissions.canSeeVendorLicenses);

			if (perms.get(Permissions.hasVendor) && authorized) {
				return addItems([
					{
						id: ids.WORKSPACES,
						label: t`Workspaces`,
						description: t`All workspaces.`,
						priority: 25,
						icon: IconMdWorkspaces,
						path: `/orgs/${orgId}/workspaces`,
						helpHref: `${helpDocBaseUrl}/Workspaces/GettingStarted.htm`,
						urlMatch: /^\/orgs\/\d+\/workspaces$/
					},
					{
						id: ids.WORKSPACE,
						parentId: ids.WORKSPACES,
						description: t`All workspaces.`,
						priority: 25,
						icon: IconMdWorkspaces,
						path: `/orgs/${orgId}/workspaces`,
						helpHref: `${helpDocBaseUrl}/Workspaces/GettingStarted.htm`,
						urlMatch: /^\/orgs\/\d+\/workspaces\/(|$|\?)/,
						hidden: true,
						variableTitle: true
					}
				]);
			}
		}
	}, [org, orgId, authenticated, perms, loading]);
}
