import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const InternalAdmin = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/internal-admin.main');
		return { default: (await mod).InternalAdmin };
	}),
	'iam_internal'
);

export const OrgDetails = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/internal-admin.main');
		return { default: (await mod).OrgDetails };
	}),
	'iam_internal'
);
export const SearchResultsGrid = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/internal-admin.main');
		return { default: (await mod).SearchResultsGrid };
	}),
	'iam_internal'
);
