import styled from 'styled-components';

export const ResizerWrapper = styled.button`
	width: 16px;
	padding: 0px;
	position: absolute;
	left: 100%;
	top: 50%;
	background: transparent;
	outline: none;
	border: none;
`;
