import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { IT_REQUESTS__PORTAL_MANAGEMENT } from '@flexera/shell.base-nav-tree';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasReclamation,
		Permissions.canSeeReclamationOpportunities
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasReclamation)) return;

		const canSeeReclamationOpportunities = perms.get(
			Permissions.canSeeReclamationOpportunities
		);

		if (!canSeeReclamationOpportunities) return;

		return addItems(
			[
				{
					parentId: IT_REQUESTS__PORTAL_MANAGEMENT,
					id: ids.RECLAIM_LICENSES,
					path: `/orgs/${orgId}/recl/license-reclamation`,
					urlMatch: /^\/orgs\/\d+\/recl\/license-reclamation(\/|$|\?)/,
					label: t`License Reclamation`,
					priority: 3
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
