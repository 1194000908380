import { FC } from 'react';
import styled from 'styled-components';
import {
	BaseTheme,
	H2,
	P,
	Box,
	Link,
	LinkProps,
	Flex
} from '@flexera/ui.component-library';

export const GetStartedLink: FC<LinkProps> = styled(Link)`
	text-decoration: none;
	flex: 0;
	margin-right: ${BaseTheme.space.md};
	background-color: ${BaseTheme.colors.light};
	box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
	box-sizing: border-box;
	border: 1px solid transparent;
	border-radius: 10px;
	transition: all 250ms ease-in-out;
	&:hover,
	&:focus,
	&:active {
		box-shadow: 10px 10px 40px rgba(39, 92, 141, 0.25);
		box-sizing: border-box;
		border: ${BaseTheme.borders.sm} ${BaseTheme.colors.blue60};
		text-decoration: none;
		svg#icon {
			transform: scale(2);
		}
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin-right: ${BaseTheme.space.md};
		margin-bottom: ${BaseTheme.space.md};
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

export const GetStartedItem: FC = styled(Box)`
	padding: ${BaseTheme.space.lg};
	min-width: 363px;
	height: fit-content;
	min-height: 194px;
	max-height: 500px;
`;

export const GetStartedTitle: FC = styled(H2)`
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 31px;
	margin-bottom: ${BaseTheme.space.md};
	margin-top: 0;
	margin-right: ${BaseTheme.space.sm};
	letter-spacing: 0.3px;
	color: ${BaseTheme.colors.blue70};
`;

export const GetStartedParagraph: FC = styled(P)`
	font-family: ${BaseTheme.fonts.secondary};
	font-style: normal;
	font-weight: normal;
	font-size: ${BaseTheme.fontSizes.md};
	line-height: ${BaseTheme.lineHeights.mmd};
	letter-spacing: 0.2px;
	color: ${BaseTheme.colors.gray80};
	margin-bottom: 0;
	margin-top: 0;
	width: 80%;
`;

export const GetStartedIconWrapper: FC = styled(Flex)`
	min-width: 70px;
	width: 70px;
	height: 70px;
	background: linear-gradient(106.34deg, #206bb6 37.24%, #051b4c 146.9%);
	border-radius: 50%;
	transition: all 250ms ease-in-out;
	svg#icon {
		transform: scale(1.8);
		margin: 22px 12px 12px 22px;
		g[fill='#000'],
		path[fill='#000'],
		path[fill='#000000'] {
			fill: ${BaseTheme.colors.light};
		}
	}
`;

export const TitleWrapper: FC = styled(Flex)`
	justify-content: space-between;
`;
