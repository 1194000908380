import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import {
	SbomBucketsGrid,
	SbomJobsGrid,
	SbomPreferences,
	SbomPartsGrid
} from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'sbom-management.bootstrap/sbom-management/manage-buckets',
				parentId: OrgsRouteId,
				path: '/sbom-management/manage-buckets',
				exact: true,
				component: SbomBucketsGrid
			},
			{
				id: 'sbom-management.bootstrap/sbom-management/manage-parts',
				parentId: OrgsRouteId,
				path: '/sbom-management/manage-parts',
				exact: true,
				component: SbomPartsGrid
			},
			{
				id: 'sbom-management.bootstrap/sbom-management/jobs',
				parentId: OrgsRouteId,
				path: '/sbom-management/jobs',
				exact: true,
				component: SbomJobsGrid
			},
			{
				id: 'sbom-management.bootstrap/sbom-management/preferences',
				parentId: OrgsRouteId,
				path: '/sbom-management/preferences',
				exact: true,
				component: SbomPreferences
			}
		]);
	}, []);
}
