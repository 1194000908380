import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const Dashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/unified-entitlements.main');
		return { default: (await mod).UnifiedEntitlements };
	}),
	'ent'
);
