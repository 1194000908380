import { useEffect } from 'react';
import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { SettingsInvitations, SettingsAPICreds } from './SettingsApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'settings.bootstrap/invitations',
				path: '/settings/invitations',
				exact: false,
				component: SettingsInvitations
			},
			{
				id: 'settings.bootstrap/api-credentials',
				path: '/settings/api-credentials',
				exact: false,
				component: SettingsAPICreds
			}
		]);
	}, []);
}
