import { HostOverrideWhiteList } from '@flexera/fnms.shared';
import { getUrlParameter } from './getUrlParameter';

/**
 * Sets the src of the iFrame to a different host via a query parameter
 * For FNMS development purposes
 * @param orgId
 */
export const setFNMSCookieRefreshUrl = () => {
	// all fnmsHostOverride logic is for FNMS development purposes only
	const fnmsCookieRefreshUrl = HostOverrideWhiteList.some((regex) =>
		regex.test(getUrlParameter('fnms-cookie-refresh'))
	);

	if (fnmsCookieRefreshUrl) {
		localStorage.setItem(
			'fnmsCookieRefreshUrl',
			getUrlParameter('fnms-cookie-refresh')
		);
		window.location.href = `https://${window.location.host}/orgs/`;
	}
};
