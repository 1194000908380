import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { TECHNOPEDIA } from '@flexera/shell.base-nav-tree';
import { IconMdDashboard } from '@flexera/ui.component-library';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	// Permissions
	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasContent,
		Permissions.canViewTechnopedia
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasContent)) return;

		if (perms.get(Permissions.canViewTechnopedia)) {
			return addItems([
				{
					id: ids.CONTENT,
					parentId: TECHNOPEDIA,
					path: `/orgs/${orgId}/technopedia/dashboard`,
					urlMatch: /^\/orgs\/\d+\/technopedia\/dashboard(\/|$|\?)/,
					label: t`Dashboard`,
					icon: IconMdDashboard,
					hidden: true,
					priority: 40
				}
			]);
		}
	}, [org, orgId, authenticated, perms, loadingPerms]);
}
