import { Loader } from '@flexera/lib.loader';
import { ChildRoutes } from '@flexera/lib.router';
import React from 'react';
import { useHistory } from 'react-router';
import {
	OrgMissing,
	OrgsLoading,
	EmptyOrgs,
	useSetCurrentOrg
} from '@flexera/shell.orgs';
import { OrgsRouteId } from './OrgsRouteId';
import { OrgMissingMessage } from './OrgMissing';

export const OrgsRoot = () => {
	const org = useSetCurrentOrg();
	const history = useHistory();

	switch (org) {
		case OrgsLoading:
			return <Loader isFullScreen />;
		case OrgMissing:
			return <OrgMissingMessage />;
		case EmptyOrgs:
			history.push(`/empty-orgs`);
			return null;
		default:
			return <ChildRoutes routeId={OrgsRouteId} />;
	}
};
