import { NavItem, NavItemChild } from '@flexera/shell.navigation';

export const determineLinkTo = (item: NavItem | NavItemChild) => {
	if (item.component) {
		return item.component;
	}

	if (item.path) {
		return item.path;
	}

	return '/';
};
