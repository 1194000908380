import { TrackJSErrorPayload } from 'trackjs';

const bearerTokenRegex = /Bearer\s[a-zA-Z0-9._-]+/;

/**
 * Sanitizer functions used to ensure we don't publish
 * sensitive information in trackjs messages
 */
export const sanitizers = [
	function sanitizeBearerTokens(payload: TrackJSErrorPayload) {
		// 3 areas may contain console information:
		// - the console array, inside each item
		// - the payload message
		// - the stack trace
		const newPayload = payload;
		(payload.console || []).forEach((entry) => {
			const newEntry = entry;
			if (entry.message) {
				newEntry.message = entry.message.replace(bearerTokenRegex, '');
			}
		});

		if (payload.message) {
			newPayload.message = payload.message.replace(bearerTokenRegex, '');
		}

		if (payload.stack) {
			newPayload.stack = payload.stack.replace(bearerTokenRegex, '');
		}
	}
];
