import { ResponseSchema } from '@flexera/data-visualization.data';
import { MetricsClient } from '@flexera/saas.api-client';

const schema: ResponseSchema[] = [
	{ name: 'name', type: 'string' },
	{ name: 'cost', type: 'number' }
];

export async function getTopDepts(orgId: number) {
	const { items } = await MetricsClient.getTopDeptSpend(orgId);

	// map to a suitable format for the chart
	const data = (items || []).map((pair) => {
		const [name, cost] = pair;
		return { name, cost };
	});

	return {
		data,
		schema
	};
}
