import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const Workspace = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/workspaces.main');
		return { default: (await mod).Workspace };
	}),
	'vendor'
);

export const WorkspacesLayout = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/workspaces.main');
		return { default: (await mod).WorkspacesLayout };
	}),
	'vendor'
);
