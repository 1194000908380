const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Add a SaaS integration": {
        "msgid": "Add a SaaS integration",
        "msgstr": [
          "Ajouter une intégration SaaS"
        ]
      },
      "Add your team and assign roles.": {
        "msgid": "Add your team and assign roles.",
        "msgstr": [
          "Ajoutez votre équipe et affectez des rôles."
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Administration"
        ]
      },
      "Automate governance across your multi-cloud environment.": {
        "msgid": "Automate governance across your multi-cloud environment.",
        "msgstr": [
          "Automatisez la gouvernance dans votre environnement multi-cloud."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisation"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Connect with customers, experts, and thought leaders to find answers and guidance": {
        "msgid": "Connect with customers, experts, and thought leaders to find answers and guidance",
        "msgstr": [
          "Connectez-vous avec des clients, des experts et des leaders d'opinion pour trouver des réponses et des conseils"
        ]
      },
      "Connect your cloud bills": {
        "msgid": "Connect your cloud bills",
        "msgstr": [
          "Connectez vos factures de cloud"
        ]
      },
      "Deploy beacons and start collecting asset data.": {
        "msgid": "Deploy beacons and start collecting asset data.",
        "msgstr": [
          "Déployez des beacons et commencez à collecter des données sur les actifs."
        ]
      },
      "Documentation Library": {
        "msgid": "Documentation Library",
        "msgstr": [
          "Bibliothèque de documentation"
        ]
      },
      "Explore Automation": {
        "msgid": "Explore Automation",
        "msgstr": [
          "Explorer l'automatisation"
        ]
      },
      "Flexera One Forum": {
        "msgid": "Flexera One Forum",
        "msgstr": [
          "Forum Flexera One"
        ]
      },
      "Get Started": {
        "msgid": "Get Started",
        "msgstr": [
          "Commencer"
        ]
      },
      "Hello ${ 0 }": {
        "msgid": "Hello ${ 0 }",
        "msgstr": [
          "Bonjour ${ 0 }"
        ]
      },
      "Helpful Links": {
        "msgid": "Helpful Links",
        "msgstr": [
          "Liens utiles"
        ]
      },
      "IT Asset Management": {
        "msgid": "IT Asset Management",
        "msgstr": [
          "Gestion des biens informatiques"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Demandes de biens informatiques"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "Inventory Beacons": {
        "msgid": "Inventory Beacons",
        "msgstr": [
          "Beacons d'inventaire"
        ]
      },
      "Invite users to Flexera One": {
        "msgid": "Invite users to Flexera One",
        "msgstr": [
          "Inviter des utilisateurs à découvrir Flexera One"
        ]
      },
      "JOIN US TODAY": {
        "msgid": "JOIN US TODAY",
        "msgstr": [
          "REJOIGNEZ-NOUS AUJOURD'HUI"
        ]
      },
      "Learning Center": {
        "msgid": "Learning Center",
        "msgstr": [
          "Centre d'apprentissage"
        ]
      },
      "Manage access to Flexera One using your identity provider.": {
        "msgid": "Manage access to Flexera One using your identity provider.",
        "msgstr": [
          "Gérez l'accès à Flexera One en utilisant votre fournisseur d'identité."
        ]
      },
      "Open A New Case": {
        "msgid": "Open A New Case",
        "msgstr": [
          "Ouvrir un nouveau cas"
        ]
      },
      "Product Discovery": {
        "msgid": "Product Discovery",
        "msgstr": [
          "Découverte du produit"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "Set up device discovery": {
        "msgid": "Set up device discovery",
        "msgstr": [
          "Configurer la découverte de l'équipement"
        ]
      },
      "Set up your identity provider": {
        "msgid": "Set up your identity provider",
        "msgstr": [
          "Configurez votre fournisseur d'identité"
        ]
      },
      "Start importing bill data from your cloud provider.": {
        "msgid": "Start importing bill data from your cloud provider.",
        "msgstr": [
          "Commencez à importer les données de facturation de votre fournisseur de cloud."
        ]
      },
      "Submit Ideas": {
        "msgid": "Submit Ideas",
        "msgstr": [
          "Soumettre des idées"
        ]
      },
      "Support Help": {
        "msgid": "Support Help",
        "msgstr": [
          "Aide"
        ]
      },
      "Support Plan Information": {
        "msgid": "Support Plan Information",
        "msgstr": [
          "Informations sur le plan d'assistance"
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Dépenses en technologie"
        ]
      },
      "Using Flexera One APIs": {
        "msgid": "Using Flexera One APIs",
        "msgstr": [
          "Utilisation des API Flexera One"
        ]
      },
      "Visit the community": {
        "msgid": "Visit the community",
        "msgstr": [
          "Visiter la communauté"
        ]
      },
      "You do not have access to any products within this organization": {
        "msgid": "You do not have access to any products within this organization",
        "msgstr": [
          "Vous n'avez pas accès aux produits de cette organisation"
        ]
      },
      "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.": {
        "msgid": "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.",
        "msgstr": [
          "Vous n'avez pas les privilèges nécessaires pour consulter cette page. Veuillez contacter votre administrateur Flexera pour demander les privilèges appropriés."
        ]
      }
    }
  }
};
export default json;
