import { useEffect } from 'react';
import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import * as Components from './SaasManagerApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'saas.bootstrap/dashboard',
				parentId: OrgsRouteId,
				path: '/saas/dashboard',
				exact: true,
				component: Components.SaasDashboard
			},
			{
				id: 'saas.bootstrap/managed-products',
				parentId: OrgsRouteId,
				path: '/saas/managed-products',
				exact: true,
				component: Components.SaasAppCatalog
			},
			{
				id: 'saas.bootstrap/managed-products/new',
				parentId: OrgsRouteId,
				path: '/saas/managed-products/new',
				exact: true,
				component: Components.SaasAppSetup
			},
			{
				id: 'saas.bootstrap/managed-products/overview/product',
				parentId: OrgsRouteId,
				path: '/saas/managed-products/overview/product/:productId',
				exact: true,
				component: Components.SaasOverview
			},
			{
				id: 'saas.bootstrap/managed-products/product/licenses',
				parentId: OrgsRouteId,
				path: '/saas/managed-products/licenses',
				exact: true,
				component: Components.SaasOverviewLicenses
			},
			{
				id: 'saas.bootstrap/managed-products/detail',
				parentId: OrgsRouteId,
				path: '/saas/managed-products/:saas_id',
				exact: true,
				component: Components.SaasAppDetail
			},
			{
				id: 'saas.bootstrap/licenses',
				parentId: OrgsRouteId,
				path: '/saas/licenses',
				exact: true,
				component: Components.SaasLicenses
			},
			{
				id: 'saas.bootstrap/licenses/detail',
				parentId: OrgsRouteId,
				path: '/saas/licenses/:saas_id',
				exact: true,
				component: Components.SaasAppLicenses
			},
			{
				id: 'saas.bootstrap/discovered-products',
				parentId: OrgsRouteId,
				path: '/saas/discovered-products',
				exact: true,
				component: Components.SaasDiscoveredProducts
			},
			{
				id: 'saas.bootstrap/app-usage',
				parentId: OrgsRouteId,
				path: '/saas/app-usage',
				exact: true,
				component: Components.SaasAppUsage
			},
			{
				id: 'saas.bootstrap/customer-agents',
				parentId: OrgsRouteId,
				path: '/saas/customer-agents',
				exact: true,
				component: Components.SaasCustomAgents
			},
			{
				id: 'saas.bootstrap/customer-agents/new',
				parentId: OrgsRouteId,
				path: '/saas/customer-agents/new',
				exact: true,
				component: Components.SaasCustomAgentCreate
			},
			{
				id: 'saas.bootstrap/customer-agents/detail',
				parentId: OrgsRouteId,
				path: '/saas/customer-agents/:saas_id',
				exact: true,
				component: Components.SaasCustomAgentDetail
			},
			{
				id: 'saas.bootstrap/inactive-users',
				parentId: OrgsRouteId,
				path: '/saas/inactive-users',
				exact: true,
				component: Components.SaasInactiveUsers
			},
			{
				id: 'saas.bootstrap/suspicious-users',
				parentId: OrgsRouteId,
				path: '/saas/suspicious-users',
				exact: true,
				component: Components.SaasSuspiciousUsers
			},
			{
				id: 'saas.bootstrap/user-usage',
				parentId: OrgsRouteId,
				path: '/saas/user-usage',
				exact: true,
				component: Components.SaasUserUsage
			},
			{
				id: 'saas.bootstrap/customer-settings',
				parentId: OrgsRouteId,
				path: '/saas/customer-settings',
				exact: true,
				component: Components.SaasCustomerSettings
			},
			{
				id: 'saas.bootstrap/org-structure-edit',
				parentId: OrgsRouteId,
				path: '/saas/org-structure-edit',
				exact: true,
				component: Components.SaasOrgStructure
			},
			{
				id: 'saas.bootstrap/audit-logs',
				parentId: OrgsRouteId,
				path: '/saas/audit-logs',
				exact: true,
				component: Components.SaasAuditLogs
			},
			{
				id: 'saas.bootstrap/products',
				parentId: OrgsRouteId,
				path: '/saas/products',
				exact: true,
				component: Components.SaasProductList
			},
			{
				id: 'saas.bootstrap/product/detail',
				parentId: OrgsRouteId,
				path: '/saas/product/:saas_id',
				exact: true,
				component: Components.SaasProductDetail
			},
			{
				id: 'saas.bootstrap/product',
				parentId: OrgsRouteId,
				path: '/saas/product',
				exact: true,
				component: Components.SaasProductCreate
			},
			{
				id: 'saas.bootstrap/customers',
				parentId: OrgsRouteId,
				path: '/saas/customers',
				exact: true,
				component: Components.SaasCustomerList
			},
			{
				id: 'saas.bootstrap/customer/detail',
				parentId: OrgsRouteId,
				path: '/saas/customer/:saas_id',
				exact: true,
				component: Components.SaasCustomerDetail
			},
			{
				id: 'saas.bootstrap/discovered-product-qa',
				parentId: OrgsRouteId,
				path: '/saas/discovered-product-qa',
				exact: true,
				component: Components.SaasFinancialDiscovery
			},
			{
				id: 'saas.bootstrap/sso-mapping',
				parentId: OrgsRouteId,
				path: '/saas/sso-mapping',
				exact: true,
				component: Components.SaasSsoMapping
			},
			{
				id: 'saas.bootstrap/vendors',
				parentId: OrgsRouteId,
				path: '/saas/vendors',
				exact: true,
				component: Components.SaasVendorList
			},
			{
				id: 'saas.bootstrap/vendor/detail',
				parentId: OrgsRouteId,
				path: '/saas/vendor/:saas_id',
				exact: true,
				component: Components.SaasVendorDetail
			},
			{
				id: 'saas.bootstrap/vendor',
				parentId: OrgsRouteId,
				path: '/saas/vendor',
				exact: true,
				component: Components.SaasVendorCreate
			},
			{
				id: 'saas.bootstrap/integration-agent',
				parentId: OrgsRouteId,
				path: '/saas/integration-agent',
				exact: true,
				component: Components.SaasIntegrationAgent
			},
			{
				id: 'saas.bootstrap/integration-agent-return',
				parentId: null, // not org scoped
				path: '/saas/integration-agent',
				exact: true,
				component: Components.SaasIntegrationReturn
			},
			{
				id: 'saas.bootstrap/reclamation',
				parentId: null, // not org scoped
				path: '/saas/reclamation',
				exact: true,
				public: true,
				component: Components.SaasReclamationResponse
			}
		]);
	}, []);
}
