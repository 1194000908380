export const filterRecentSearch = (
	lastSearch: string,
	recentSearches: string[]
) => {
	if (lastSearch.trim() !== '') {
		if (recentSearches) {
			const alreadySearched = recentSearches.some(
				(search: string) => search === lastSearch
			);
			if (alreadySearched) {
				return recentSearches;
			}
			const updatedRecentSearches = recentSearches;
			updatedRecentSearches.unshift(lastSearch);
			if (updatedRecentSearches.length > 5) {
				recentSearches.pop();
			}
			return updatedRecentSearches;
		}
		return [lastSearch];
	}
};
