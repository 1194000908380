import { HeaderEnvironmentIndicator } from '@flexera/ui.component-library';
import { t } from 'ttag';
import React from 'react';

// Get the value of the x-flexera-partner cookie, if present.
const partnerCookie =
	document.cookie.match('(^|;)\\s*x-flexera-partner\\s*=\\s*([^;]+)')?.pop() ||
	'';

function clearCookie(domain: string) {
	return `x-flexera-partner=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}`;
}

function clearPartner() {
	if (
		// eslint-disable-next-line no-alert
		!window.confirm(
			t`You are running a white-labelled versioned of Flexera One within the scope of the ${partnerCookie} partner. Do you want to clear this setting and return to the main production build?`
		)
	) {
		return;
	}
	// First, clear the cookie...
	document.cookie = clearCookie(window.location.hostname);
	document.cookie = clearCookie(window.location.hostname.replace('app.', '')); // This is for legacy cookies
	// Then, reload the app. Go to the root because it's possible we're on a page that doesn't exist in the other branch.
	window.location.href = `https://${window.location.host}`;
}

export const PartnerIndicator = () => {
	return (
		<>
			{partnerCookie && (
				<HeaderEnvironmentIndicator
					id={'partner-indicator'}
					environment={partnerCookie}
					onClick={clearPartner}
				/>
			)}
		</>
	);
};
