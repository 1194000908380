const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Annuler"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "Aucun favori à afficher."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "Aucune recherche récente"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "Aucun résultat pour cette recherche"
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Recherche"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Vos termes de recherche récents..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "Obtenir des résultats pour un terme de recherche récent :"
        ]
      }
    }
  }
};
export default json;
