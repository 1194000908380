import { FilterOption, ResponseSchema } from '@flexera/data-visualization.data';
import { MetricsClient, ManagedAppsClient } from '@flexera/saas.api-client';

/**
 * The available SaaS metric types
 */
export enum SaasMetricType {
	CurrentUsage = 1,
	HistoricUsage,
	RangedUsage,
	SpendByDept
}

/**
 * The available SaaS metric scopes
 */
export enum SaasMetricScope {
	App = 1,
	Product
}

export enum SaasMetricFormat {
	None = 0,
	ActivePercent,
	UnderutilizedCount
}

/**
 * The available filters for SaaS metrics data
 */
export interface SaasMetricsFilter {
	/** The type of metric to query */
	type: SaasMetricType;
	/** The scope, either app data or product data */
	scope: SaasMetricScope;
	/** The formatting of the output */
	format?: SaasMetricFormat;
	/** The target app or product to query */
	target: string;
}

const schema: ResponseSchema[] = [];

// map API range values to UX provided labels
const RANGES: { [key: string]: string } = {
	fifteen: '0~15d',
	thirty: '16~30d',
	fortyfive: '31~45d',
	sixty: '46~60d',
	ninety: '61~90d',
	onetwenty: '91~120d',
	onetwentyplus: '120+d',
	never: 'No activity'
};

export async function getSaasMetrics(
	orgId: number,
	filterOption: FilterOption,
	filter: SaasMetricsFilter
) {
	let appIds: string[];
	let data = null;

	// determine which apps to query
	switch (filter.scope) {
		case SaasMetricScope.App:
			appIds = [filter.target];
			break;
		case SaasMetricScope.Product:
			appIds = (
				await ManagedAppsClient.getManagedApps(orgId, {
					active: true,
					product: filter.target
				})
			).values.map((app) => app.id);
			break;
		default:
			throw new Error(
				`Unhandled filter scope => ${SaasMetricScope[filter.scope]}`
			);
	}

	switch (filter.type) {
		case SaasMetricType.CurrentUsage:
			data = await MetricsClient.getCurrentUsage(orgId, appIds);
			if (filter.format === SaasMetricFormat.ActivePercent) {
				// for percentage calculation
				const total = data.reduce((value, next) => value + next.total[0], 0);
				const active = data.reduce((value, next) => value + next.active[0], 0);
				data = [{ result: total ? active / total : 0 }];
			} else if (filter.format === SaasMetricFormat.UnderutilizedCount) {
				// for underutilized totals
				const free = data.reduce((value, next) => value + next.underutilized[0], 0);
				data = [{ result: free }];
			}
			break;
		case SaasMetricType.HistoricUsage:
			// TODO calculate range from filterOption
			data = await MetricsClient.getUsageHistory(orgId, appIds[0], 1);
			break;
		case SaasMetricType.RangedUsage:
			data = (await MetricsClient.getUsageRange(orgId, appIds)).reduce(
				(value, next) => {
					[
						'fifteen',
						'thirty',
						'fortyfive',
						'sixty',
						'ninety',
						'onetwenty',
						'onetwentyplus',
						'never'
					].forEach((group) => {
						value.push({
							id: next.id,
							date: next.date,
							range: group,
							label: RANGES[group],
							count: next[group]
						});
					});
					return value;
				},
				[]
			);
			break;
		case SaasMetricType.SpendByDept:
			data = await MetricsClient.getSpendByDept(orgId, appIds[0]);
			break;
		default:
			throw new Error(`Unhandled filter type => ${SaasMetricType[filter.type]}`);
	}

	return {
		data,
		schema
	};
}
