// sessionStorage

interface PreferencePair {
	[key: string]: string | boolean;
}

/**
 * Return the current preferences storage key
 */
export const getAllSessionPreferences = () => {
	return window.sessionStorage.getItem('preferences')
		? JSON.parse(window.sessionStorage.getItem('preferences'))
		: {};
};

/**
 * Gets preference by key
 * @param preferenceKey
 */
export const getSessionPreference = (preferenceKey: string) => {
	if (window.sessionStorage.getItem('preferences')) {
		const prefs = JSON.parse(window.sessionStorage.getItem('preferences'));
		if (preferenceKey in prefs) {
			return prefs[preferenceKey];
		}
		return undefined;
	}
	return undefined;
};

/**
 * Sets or updates the preference
 * @param preference
 */
export const setSessionPreference = (preference: PreferencePair) => {
	const existingPreferences = getAllSessionPreferences();
	window.sessionStorage.setItem(
		'preferences',
		JSON.stringify({
			...existingPreferences,
			...preference
		})
	);
};
