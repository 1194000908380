import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';

const incidentRegex = /\/orgs\/(\d+)\/policy\/projects\/(.+)\/incidents(?:\/(.+))?/;

function matchIncidents(path: string) {
	const match = path.match(incidentRegex) || [];
	return [match[1], match[2], match[3]];
}

const IncidentsRedirect = () => {
	const { location } = useHistory();
	const [orgId, projectId, itemId] = matchIncidents(location.pathname);
	const isSummary = projectId === 'summary';

	return orgId ? (
		<Redirect
			to={`/orgs/${orgId}/automation/incidents${
				isSummary ? '' : `/projects/${projectId}`
			}${itemId ? `?incidentId=${itemId}&noIndex=1` : ''}`}
		/>
	) : null;
};

const policyRegex = /\/orgs\/(\d+)\/policy\/projects\/(.+)\/applied-policies(?:\/(.+))?/;

function matchPolicies(path: string) {
	const match = path.match(policyRegex) || [];
	return [match[1], match[2], match[3]];
}

const AppliedPoliciesRedirect = () => {
	const { location } = useHistory();
	const [orgId, projectId, itemId] = matchPolicies(location.pathname);
	const isSummary = projectId === 'summary';

	return orgId ? (
		<Redirect
			to={`/orgs/${orgId}/automation/applied-policies${
				isSummary ? '' : `/projects/${projectId}`
			}${itemId ? `?policyId=${itemId}&noIndex=1` : ''}`}
		/>
	) : null;
};

export function useRedirects() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'automation.bootstrap/policy-project-incidents-redirect',
				parentId: OrgsRouteId,
				path: '/policy/projects/:projectId/incidents',
				exact: true,
				component: IncidentsRedirect
			},
			{
				id: 'automation.bootstrap/policy-project-incidents-detail-redirect',
				parentId: OrgsRouteId,
				path: '/policy/projects/:projectId/incidents/:incidentId',
				exact: true,
				component: IncidentsRedirect
			},
			{
				id: 'automation.bootstrap/policy-detail-redirect',
				parentId: OrgsRouteId,
				path: '/policy/projects/:projectId/applied-policies/:policyId',
				exact: true,
				component: AppliedPoliciesRedirect
			},
			{
				id: 'automation.bootstrap/policy-overview-redirect',
				parentId: OrgsRouteId,
				path: '/policy/projects/:projectId/applied-policies',
				exact: true,
				component: AppliedPoliciesRedirect
			}
		]);
	}, []);
}
