import React from 'react';
import { P, H3, Link, Flex } from '@flexera/ui.component-library';
import { t } from 'ttag';
import {
	MarketingItem,
	MarketingWrapper,
	MarketingItemsWrapper
} from './Styled';
import { CardTitle, Subtitle } from '../Styled';

export const Marketing = () => {
	return (
		<MarketingWrapper id={'marketing'}>
			<Flex justifyContent={'space-between'}>
				<Flex flexDirection={'column'}>
					<CardTitle>{t`MARKETING`}</CardTitle>
					<Subtitle>{t`Latest News`}</Subtitle>
				</Flex>
				<Link
					id={'marketing-view-more'}
					title={'View More '}
					href={
						'https://www.flexera.com/?utm_source=flexera&utm_medium=in_product_messaging&utm_campaign=FLX1_In_Product_2022&utm_content=flx1_splash_screen_marketing'
					}
					target={'_blank'}
				>
					{t`View More`}
				</Link>
			</Flex>
			<MarketingItemsWrapper>
				<MarketingItem>
					<H3 mt={'0'} mb={'0'}>
						{t`Flexera One Solutions`}
					</H3>
					<P variant={'large'} mt={'xxs'} mb={'sm'}>
						{t`See the impact Flexera One Solutions can have on your IT estate.`}
					</P>
					<Link
						id={'solution-find-more'}
						title={'Find out more'}
						href={
							'https://www.flexera.com/flexera-one?utm_source=flexera&utm_medium=in_product_messaging&utm_campaign=FLX1_In_Product_2022&utm_content=flx1_splash_screen_marketing'
						}
						target={'_blank'}
					>
						{t`Find out more`}
					</Link>
				</MarketingItem>
				<MarketingItem>
					<H3 mt={'0'} mb={'0'}>
						{t`Case Studies`}
					</H3>
					<P variant={'large'} mt={'xxs'} mb={'sm'}>
						{t`Read how Flexera One solutions have benefited enterprises like yours.`}
					</P>
					<Link
						id={'case-studies-find-more'}
						title={'Find out more'}
						href={
							'https://www.flexera.com/resources/case-studies?utm_source=flexera&utm_medium=in_product_messaging&utm_campaign=FLX1_In_Product_2022&utm_content=flx1_splash_screen_marketing'
						}
						target={'_blank'}
					>
						{t`Find out more`}
					</Link>
				</MarketingItem>
				<MarketingItem>
					<H3 mt={'0'} mb={'0'}>
						{t`Solution Briefs`}
					</H3>
					<P variant={'large'} mt={'xxs'} mb={'sm'}>
						{t`Examining a range of IT challenges and how Flexera One solves them.`}
					</P>
					<Link
						id={'solution-briefs-find-more'}
						title={'Find out more'}
						href={
							'https://www.flexera.com/resources?type%5Bdatasheet%5D=datasheet&utm_source=flexera&utm_medium=in_product_messaging&utm_campaign=FLX1_In_Product_2022&utm_content=flx1_splash_screen_marketing'
						}
						target={'_blank'}
					>
						{t`Find out more`}
					</Link>
				</MarketingItem>
				<MarketingItem>
					<H3 mt={'0'} mb={'0'}>
						{t`Datasheets`}
					</H3>
					<P variant={'large'} mt={'xxs'} mb={'sm'}>
						{t`Deep dives into the features and benefit of Flexere One solutions.`}
					</P>
					<Link
						id={'datasheets-find-more'}
						title={'Find out more'}
						href={
							'https://www.flexera.com/resources?type%5Bdatasheet%5D=datasheet&utm_source=flexera&utm_medium=in_product_messaging&utm_campaign=FLX1_In_Product_2022&utm_content=flx1_splash_screen_marketing'
						}
						target={'_blank'}
					>
						{t`Find out more`}
					</Link>
				</MarketingItem>
			</MarketingItemsWrapper>
		</MarketingWrapper>
	);
};
