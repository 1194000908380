import React, { useContext } from 'react';
import {
	BaseTheme,
	Icon,
	IconMdFavorited,
	IconMdFavorite
} from '@flexera/ui.component-library';
import { t } from 'ttag';
import { FavoriteItem } from '@flexera/shell.favorites';
import { useMenu, NavItemChild } from '@flexera/shell.navigation';
import { useUserPreferences } from '@flexera/shell.user-preferences';
import { AddRemoveFaveButton } from './FavoriteButton.styled';
import { getNavigationFavoriteItemFromId } from '../../../utilities/getNavigationFavoriteItemFromId';
import { NavigationContext } from '../../navigationContext';

export const FavoriteButton = ({
	itemId,
	itemLabel
}: {
	itemId: string | number;
	itemLabel: string;
}) => {
	const menu = useMenu();
	const { orgId, savedFavorites, setSavedFavorites } = useContext(
		NavigationContext
	);
	const { getUserPreference, setUserPreference } = useUserPreferences();
	const prefs = getUserPreference('favorites', orgId);

	const isFavorite =
		savedFavorites?.findIndex((favorite) => favorite?.itemId === itemId) >= 0;

	const removeNavFavorite = async () => {
		if (prefs?.value) {
			const favoriteItems: FavoriteItem[] = JSON.parse(prefs?.value as string);
			const updatedFavorites = favoriteItems.filter(
				(favorite) => favorite?.itemId !== itemId
			);
			setSavedFavorites(updatedFavorites);
			await setUserPreference({
				id: 'favorites',
				orgId: +orgId,
				value: JSON.stringify(updatedFavorites)
			});
		}
	};

	const addNavFavorite = async () => {
		const preferences: FavoriteItem[] = prefs?.value
			? JSON.parse(prefs?.value as string)
			: [];
		const newFavoriteItem = getNavigationFavoriteItemFromId(
			itemId,
			menu as NavItemChild[]
		);
		if (newFavoriteItem) {
			setSavedFavorites([...preferences, newFavoriteItem]);
			await setUserPreference({
				id: 'favorites',
				orgId: +orgId,
				value: JSON.stringify([...preferences, newFavoriteItem])
			});
		}
	};

	const handleFavoriteClick = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (isFavorite) {
			return removeNavFavorite();
		}

		return addNavFavorite();
	};

	return (
		<AddRemoveFaveButton
			onClick={handleFavoriteClick}
			className={isFavorite ? 'favorite-active' : 'favorite-not-active'}
			aria-label={
				isFavorite
					? t`Press here to remove ${itemLabel} from favorite list.`
					: t`Press here to make ${itemLabel} in favorite list.`
			}
		>
			<Icon
				src={isFavorite ? IconMdFavorited : IconMdFavorite}
				cursor={'pointer'}
				color={BaseTheme.colors.light}
			/>
		</AddRemoveFaveButton>
	);
};
