import ssBoldEot from './SourceSansPro-Bold.eot';
import ssBoldTtf from './SourceSansPro-Bold.ttf';
import ssBoldWoff from './SourceSansPro-Bold.woff';
import ssBoldWoff2 from './SourceSansPro-Bold.woff2';

export const SourceSansBold = `
  @font-face {
    font-family: 'Source Sans Pro';
    src: url(${ssBoldEot});
    src: url(${ssBoldTtf}) format('truetype'),
         url(${ssBoldWoff}) format('woff'),
         url(${ssBoldWoff2}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
`;
