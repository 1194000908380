import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	DASHBOARDS,
	IT_REQUESTS__PORTAL,
	IT_REQUESTS__PORTAL_MANAGEMENT
} from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const appBrokerPath = `/orgs/${orgId}/appBroker`;
	const { addItems } = useNavItems();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canViewAppBrokerUser,
		Permissions.canViewAppBrokerAdmin,
		Permissions.hasAppBroker
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const canViewAdmin = perms.get(Permissions.canViewAppBrokerUser);
		const canViewUser = perms.get(Permissions.canViewAppBrokerUser);

		if (!perms.get(Permissions.hasAppBroker)) return;

		return addItems(
			[
				canViewAdmin && {
					parentId: DASHBOARDS,
					id: ids.DASHBOARD,
					path: `${appBrokerPath}/dashboard`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/dashboard(\/|$|\?)/,
					label: t`IT Asset Requests`,
					priority: 50
				},
				canViewAdmin && {
					parentId: IT_REQUESTS__PORTAL_MANAGEMENT,
					id: ids.CATALOG_MANAGEMENT,
					path: `${appBrokerPath}/admin/catalog-management`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/admin\/catalog-management$/,
					label: t`Manage Catalog`,
					priority: 1
				},
				canViewAdmin && {
					parentId: ids.CATALOG_MANAGEMENT,
					id: ids.CATALOG_MANAGEMENT_CREATE,
					path: `${appBrokerPath}/admin/catalog-management`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/admin\/catalog-management\/new$/,
					label: t`New Catalog Form`,
					hidden: true
				},
				canViewAdmin && {
					parentId: ids.CATALOG_MANAGEMENT,
					id: ids.CATALOG_MANAGEMENT_EDIT,
					path: `${appBrokerPath}/admin/catalog-management`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/admin\/catalog-management\/edit\/(|$|\?)/,
					hidden: true,
					label: t`Edit Catalog Form`
				},
				canViewAdmin && {
					parentId: IT_REQUESTS__PORTAL_MANAGEMENT,
					id: ids.REQUEST_MANAGEMENT,
					path: `${appBrokerPath}/admin/manage-requests`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/admin\/manage-requests(\/|$|\?)/,
					label: t`Requests`,
					priority: 2
				},
				canViewAdmin && {
					parentId: IT_REQUESTS__PORTAL_MANAGEMENT,
					id: ids.CONNECTIONS_MANAGEMENT,
					path: `${appBrokerPath}/admin/connections`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/admin\/connections(\/|$|\?)/,
					label: t`Connections`,
					priority: 4
				},
				canViewUser && {
					parentId: IT_REQUESTS__PORTAL,
					id: ids.CATALOG,
					path: `${appBrokerPath}/catalog`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/catalog(\/|$|\?)/,
					label: t`Catalog`
				},
				canViewUser && {
					parentId: IT_REQUESTS__PORTAL,
					id: ids.REQUESTS,
					path: `${appBrokerPath}/my-requests`,
					urlMatch: /^\/orgs\/\d+\/appBroker\/my-requests(\/|$|\?)/,
					label: t`My Requests`
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
