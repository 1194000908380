import { t } from 'ttag';
import { img1, img2, img3, img4 } from './thumbnails';

export interface Video {
	id: number;
	title: string;
	description: string;
	url: string;
	thumbnail: string;
}

export const VideoPlaylistData: Video[] = [
	{
		id: 1,
		title: t`Flexera One Overview`,
		description: t`Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.`,
		url: `https://www.youtube.com/embed/cZryEoCg3gE?si=W6yHrEXiZA54APNo`,
		thumbnail: img1.default
	},
	{
		id: 2,
		title: t`IT Visibility`,
		description: t`Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.`,
		url: `https://www.youtube.com/embed/8Pf7DwlMgtk?si=6e0uNIA7X25yrpON`,
		thumbnail: img2.default
	},
	{
		id: 3,
		title: t`SaaS Manager`,
		description: t`Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.`,
		url: `https://www.youtube.com/embed/Tg2XYf6YBg0?si=vdyHCFHYNwsow43g`,
		thumbnail: img3.default
	},
	{
		id: 4,
		title: t`Cloud Cloud Cost Optimization`,
		description: t`Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.`,
		url: `https://www.youtube.com/embed/wyM9k7xS1nM?si=qL0U8Gwua2tMPbLL`,
		thumbnail: img4.default
	}
];
