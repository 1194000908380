const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add Application": {
        "msgid": "Add Application",
        "msgstr": [
          "Agregar aplicación"
        ]
      },
      "All SaaS Licenses": {
        "msgid": "All SaaS Licenses",
        "msgstr": [
          "Todas las licencias SaaS"
        ]
      },
      "All SaaS Users": {
        "msgid": "All SaaS Users",
        "msgstr": [
          "Todos los usuarios de SaaS"
        ]
      },
      "Applications": {
        "msgid": "Applications",
        "msgstr": [
          "Aplicaciones"
        ]
      },
      "Audit Logs": {
        "msgid": "Audit Logs",
        "msgstr": [
          "Registros de auditorías"
        ]
      },
      "Customers": {
        "msgid": "Customers",
        "msgstr": [
          "Clientes"
        ]
      },
      "Financial QA": {
        "msgid": "Financial QA",
        "msgstr": [
          "Control de calidad financiero"
        ]
      },
      "General": {
        "msgid": "General",
        "msgstr": [
          "General"
        ]
      },
      "Managed SaaS Applications": {
        "msgid": "Managed SaaS Applications",
        "msgstr": [
          "Aplicaciones SaaS administradas"
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organización"
        ]
      },
      "Organization SaaS Usage": {
        "msgid": "Organization SaaS Usage",
        "msgstr": [
          "Uso de SaaS en la organización"
        ]
      },
      "SSO Naming": {
        "msgid": "SSO Naming",
        "msgstr": [
          "Definición del nombre de SSO"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS License Usage": {
        "msgid": "SaaS License Usage",
        "msgstr": [
          "Uso de licencias SaaS"
        ]
      },
      "Suspicious SaaS Activities": {
        "msgid": "Suspicious SaaS Activities",
        "msgstr": [
          "Actividades SaaS sospechosas"
        ]
      },
      "Underutilized SaaS Accounts": {
        "msgid": "Underutilized SaaS Accounts",
        "msgstr": [
          "Cuentas SaaS infrautilizadas"
        ]
      },
      "Unsanctioned SaaS Applications": {
        "msgid": "Unsanctioned SaaS Applications",
        "msgstr": [
          "Aplicaciones SaaS no autorizadas"
        ]
      },
      "Vendors": {
        "msgid": "Vendors",
        "msgstr": [
          "Proveedores"
        ]
      }
    }
  }
};
export default json;
