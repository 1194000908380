import React from 'react';
import { Icon } from '@flexera/ui.component-library';
import { pointerEvents } from '@flexera/lib.utilities';
import { NavigationContext } from '../navigationContext';
import { ResizeHandle } from '../../assets';
import { ResizerWrapper } from './Resizer.styled';

interface props {
	/** resizer needs the ref of an element to have an effect on */
	parentRef: React.Ref<HTMLDivElement>;
	setIsResizing: (boolean) => void;
	deactivateFlyout: () => void;
}

/** Resizer size references */
const DEFAULT_WIDTH = 240;
const MAX_WIDTH = 480;
let startingWidth: number;
let startX: number;

export const Resizer = ({
	parentRef,
	setIsResizing,
	deactivateFlyout
}: props) => {
	const { navWrapperRef } = React.useContext(NavigationContext);
	const resizerRef = React.useRef();

	/** Resize the slideout based on the cursor "x" position and
	the slideout's starting width */
	const resize = (e: MouseEvent) => {
		// Removing pointer events on iframes to allow for resizing
		pointerEvents.disable();
		const newWidth = startingWidth + e.x - startX;
		const setMin = newWidth <= DEFAULT_WIDTH;
		const setMax = newWidth >= MAX_WIDTH;
		const getReturnWidth = () => {
			if (setMin) return DEFAULT_WIDTH;
			if (setMax) return MAX_WIDTH;
			return newWidth;
		};

		const returnWidth = getReturnWidth();
		navWrapperRef.current.style.setProperty('--flyout-width', `${returnWidth}px`);
	};

	const endResize = (e: MouseEvent) => {
		e.preventDefault();

		if (!parentRef.current.contains(e.target)) {
			deactivateFlyout();
		}

		// Adding pointer events to iframes once resizing is done
		pointerEvents.enable();
		setIsResizing(false);
		document.removeEventListener('mousemove', resize);
		document.removeEventListener('mouseup', endResize);
	};

	const setResizeData = (e: MouseEvent) => {
		setIsResizing(true);
		startX = e.x;
		startingWidth = parentRef?.current.offsetWidth;
		document.addEventListener('mousemove', resize);
		document.addEventListener('mouseup', endResize);
	};

	React.useEffect(() => {
		resizerRef?.current?.addEventListener('mousedown', setResizeData);

		return () => {
			resizerRef?.current?.removeEventListener('mousedown', setResizeData);
		};
	}, [window.location.pathname]);

	return (
		<ResizerWrapper
			type={'button'}
			className={'resizer-button'}
			tabIndex={-1}
			ref={resizerRef}
			aria-label={'Press here to resize the sub menu section.'}
		>
			<Icon
				src={ResizeHandle}
				background-color={'#080D1D'}
				cursor={'grab'}
				width={'16px'}
				height={'48px'}
				z-index={'160'}
				tabIndex={-1}
			/>
		</ResizerWrapper>
	);
};
