const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "All Licenses (Beta)": {
        "msgid": "All Licenses (Beta)",
        "msgstr": [
          "Toutes les licences (bêta)"
        ]
      }
    }
  }
};
export default json;
