import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useShowSnowAtlasLink } from '@flexera/flexera-one.feature-flags';
import { useEffect } from 'react';
import { SnowSplash } from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	const showSnowAtlasLink = useShowSnowAtlasLink();

	useEffect(() => {
		if (showSnowAtlasLink) {
			return addRoutes([
				{
					id: 'snow.bootstrap/page',
					parentId: OrgsRouteId,
					path: '/snow',
					exact: false,
					component: SnowSplash
				}
			]);
		}
	}, []);
}
