/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { useVisualizationTypes } from '@flexera/data-visualization.visualization/src/hooks/useVisualizationTypes';
import { Carousel } from './components';

const useRegisterVisualizationTypes = () => {
	const { addVisualizationTypes } = useVisualizationTypes();
	return addVisualizationTypes([
		{
			id: 'Carousel',
			name: 'Carousel',
			getVisualization: () => {
				return <Carousel />;
			}
		}
	]);
};

export const CarouselVisualization: FC = () => {
	useRegisterVisualizationTypes();
	return null;
};
