import { HostOverrideWhiteList } from '@flexera/fnms.shared';
import { getUrlParameter } from './getUrlParameter';

/**
 * Sets the src of the iFrame to a different host via a query parameter
 * For FNMS development purposes
 * @param orgId
 */
export const setFNMSHostOverride = () => {
	// all fnmsHostOverride logic is for FNMS development purposes only
	const fnmsHostOverride = HostOverrideWhiteList.some((regex) =>
		regex.test(getUrlParameter('fnms-host'))
	);

	if (fnmsHostOverride) {
		localStorage.setItem('fnmsHostOverride', getUrlParameter('fnms-host'));
		window.location.href = `https://${window.location.host}/orgs/`;
	}
};
