const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Applied Policies": {
        "msgid": "Applied Policies",
        "msgstr": [
          "Politiques appliquées"
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisation"
        ]
      },
      "Catalog": {
        "msgid": "Catalog",
        "msgstr": [
          "Catalogue"
        ]
      },
      "Credentials": {
        "msgid": "Credentials",
        "msgstr": [
          "Informations d'identification"
        ]
      },
      "Incidents": {
        "msgid": "Incidents",
        "msgstr": [
          "Incidents"
        ]
      },
      "Templates": {
        "msgid": "Templates",
        "msgstr": [
          "Modèles"
        ]
      }
    }
  }
};
export default json;
