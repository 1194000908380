import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import * as ids from './menuIds';
import { ReclaimLicenses } from './ReclamationApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: ids.RECLAIM_LICENSES,
				parentId: OrgsRouteId,
				path: '/recl/license-reclamation',
				exact: true,
				component: ReclaimLicenses
			}
		]);
	}, []);
}
