import React from 'react';
import { Icon } from '@flexera/ui.component-library';
import { PinButtonBox } from './PinButton.styled';
import { Pin } from '../../assets';
import { NavigationContext } from '../navigationContext';

interface PinButtonProps {
	parentSelector: string;
	parentRef: React.Ref<HTMLDivElement>;
	drawerOpen: boolean;
	scrollable: boolean;
}

export const PinButton = ({
	parentSelector,
	parentRef,
	drawerOpen,
	scrollable
}: PinButtonProps) => {
	const {
		setPinnedItem,
		isPinned,
		setIsPinned,
		setIsExpanded
	} = React.useContext(NavigationContext);
	const handlePinning = () => {
		if (isPinned) {
			setPinnedItem('');
			setIsPinned(false);
		} else {
			if (parentRef.current.classList.contains('active')) {
				parentRef.current.classList.remove('active');
			}
			setIsExpanded(isPinned);
			setPinnedItem(parentSelector);
			setIsPinned(true);
		}
	};
	return (
		<PinButtonBox
			isActive={isPinned}
			drawerOpen={drawerOpen}
			scrollable={scrollable}
		>
			<button
				className={'pin-button'}
				title={'Pin Button'}
				aria-label={'Pin Button for keeping the Navigation content visible'}
				type={'button'}
				onClick={() => handlePinning()}
			>
				<Icon src={Pin} />
			</button>
		</PinButtonBox>
	);
};
