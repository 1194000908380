import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { UOMPowerBiDashboard } from './UOMApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'uom.bootstrap/powerbi-dashboard-custom-embed',
				parentId: OrgsRouteId,
				path: '/viz/report/:dashboard_id?',
				exact: true,
				component: UOMPowerBiDashboard
			}
		]);
	}, []);
}
