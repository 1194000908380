import { t } from 'ttag';

export const recentSearchText = t`Your recent search terms...`;

export const recentSearchLinkDescription = t`get search results for recent search term:`;

export const defaultFavoritesMenu = [
	{ label: t`No favorites to display.`, static: true }
];

export const noSearchResults = t`No results for this search`;
