import styled, { css } from 'styled-components';

export interface PlaceholderProps {
	isExpanded?: boolean;
	seed?: number;
}

const loadingKeyframe = css`
	@keyframes loading {
		0% {
			background-position: right;
		}
	}
`;
const loadingAnimation = css`
	background: linear-gradient(270deg, #3b4050 0%, #0a1024 50.18%, #3b4050 100%);
	background-size: 300% 100%;
	animation: loading 1s infinite;
`;

export const Label = styled.div<PlaceholderProps>`
	${loadingKeyframe}

	background: #3b4050;
	height: 14px;
	width: 50%;
	border-radius: 4px;
	${loadingAnimation}

	${({ seed }) => {
		if (seed % 2 === 0) {
			return css`
				 {
					width: 25%;
				}
			`;
		}
	}}
`;

export const Icon = styled.div<PlaceholderProps>`
	${loadingKeyframe}
	background: #3b4050;
	border-radius: 50%;
	height: 24px;
	width: 24px;

	${({ isExpanded }) => {
		if (isExpanded) {
			return css`
				margin: 0 1rem 0 0.5rem;
			`;
		}

		return css`
			margin: 0 4px;
			${loadingAnimation}
		`;
	}};
`;
