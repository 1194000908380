import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { LICENSES__LICENSES_BETA } from '@flexera/shell.base-nav-tree';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useEntitlements } from '@flexera/flexera-one.feature-flags';
import { useEffect } from 'react';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();

	const hasEntitlementsAccess = useEntitlements();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasEntitlement,
		Permissions.canSeeLicenses
	);
	const { addItems } = useNavItems();
	const capabilityPath = `/orgs/${orgId}/unified-entitlements`;

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (
			!perms.get(Permissions.hasEntitlement) ||
			!perms.get(Permissions.canSeeLicenses) ||
			!hasEntitlementsAccess
		)
			return;

		return addItems([
			{
				id: ids.DASHBOARD,
				parentId: LICENSES__LICENSES_BETA,
				helpHref: `${helpDocBaseUrl}/ITAssets/GettingStartedITAssets.htm`,
				label: t`All Licenses (Beta)`,
				path: `${capabilityPath}/dashboard`,
				urlMatch: /^\/orgs\/\d+\/unified-entitlements\/.*(\/|$|\?)/
			}
		]);
	}, [org, orgId, authenticated, loadingPerms, perms, hasEntitlementsAccess]);
}
