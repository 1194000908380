import { NavItemChild } from '@flexera/shell.navigation';
import { NavigationFavoriteItem } from '../types/NavigationFavoriteItem';

export const getNavigationFavoriteItemFromId = (
	favoriteItemId: string,
	navItems: NavItemChild[]
): NavigationFavoriteItem | null => {
	for (const navItem of navItems) {
		for (const navChild of navItem.children) {
			if (navChild.category) {
				const grandchild = navChild.children.find((i) => i.id === favoriteItemId);

				if (grandchild) {
					return {
						primaryNavId: `${grandchild.parentId}`,
						categoryId: `${navChild.id}`,
						itemId: `${grandchild.id}`
					};
				}
			}

			if (navChild.id === favoriteItemId) {
				return {
					primaryNavId: `${navChild.parentId}`,
					itemId: `${navChild.id}`
				};
			}
		}
	}

	return null;
};
