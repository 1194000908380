const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Clear the branch and go back to the production build?": {
        "msgid": "Clear the branch and go back to the production build?",
        "comments": {
          "extracted": "eslint-disable-next-line no-alert"
        },
        "msgstr": [
          "Clear the branch and go back to the production build?"
        ]
      },
      "Click here to get help via our Support Center.": {
        "msgid": "Click here to get help via our Support Center.",
        "msgstr": [
          "Click here to get help via our Support Center."
        ]
      },
      "Error...": {
        "msgid": "Error...",
        "msgstr": [
          "Error..."
        ]
      },
      "Loading...": {
        "msgid": "Loading...",
        "msgstr": [
          "Loading..."
        ]
      },
      "Log out": {
        "msgid": "Log out",
        "msgstr": [
          "Log out"
        ]
      },
      "Organizations": {
        "msgid": "Organizations",
        "msgstr": [
          "Organizations"
        ]
      },
      "Privacy Policy": {
        "msgid": "Privacy Policy",
        "msgstr": [
          "Privacy Policy"
        ]
      },
      "Profile details": {
        "msgid": "Profile details",
        "msgstr": [
          "Profile details"
        ]
      },
      "Select an Organization": {
        "msgid": "Select an Organization",
        "msgstr": [
          "Select an Organization"
        ]
      },
      "User Settings": {
        "msgid": "User Settings",
        "msgstr": [
          "User Settings"
        ]
      },
      "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?": {
        "msgid": "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?",
        "comments": {
          "extracted": "eslint-disable-next-line no-alert",
          "flag": "javascript-format"
        },
        "msgstr": [
          "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?"
        ]
      }
    }
  }
};
export default json;
