import { FC } from 'react';
import { useDataSources } from '@flexera/saas.data';
import { useNav } from './useNav';
import { useRoutes } from './useRoutes';

export const SaasManager: FC = () => {
	useNav();
	useRoutes();
	useDataSources();
	return null;
};
