const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Administration"
        ]
      },
      "All dashboards.": {
        "msgid": "All dashboards.",
        "msgstr": [
          "Tous les tableaux de bord."
        ]
      },
      "Applications & Evidence": {
        "msgid": "Applications & Evidence",
        "msgstr": [
          "Applications et empreintes"
        ]
      },
      "Applications & Evidence.": {
        "msgid": "Applications & Evidence.",
        "msgstr": [
          "Applications et empreintes."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisation"
        ]
      },
      "Automation for enterprise wide governance and self-service.": {
        "msgid": "Automation for enterprise wide governance and self-service.",
        "msgstr": [
          "Automatisation de la gouvernance et du libre-service à l'échelle de l'entreprise."
        ]
      },
      "Automation.": {
        "msgid": "Automation.",
        "msgstr": [
          "Automatisation."
        ]
      },
      "Business Services": {
        "msgid": "Business Services",
        "msgstr": [
          "Services aux entreprises"
        ]
      },
      "Business services.": {
        "msgid": "Business services.",
        "msgstr": [
          "Services aux entreprises."
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Settings": {
        "msgid": "Cloud Settings",
        "msgstr": [
          "Paramètres du cloud"
        ]
      },
      "Contracts (Beta)": {
        "msgid": "Contracts (Beta)",
        "msgstr": [
          "Contrats (bêta)"
        ]
      },
      "Cost Optimization": {
        "msgid": "Cost Optimization",
        "msgstr": [
          "Optimisation des coûts"
        ]
      },
      "Custom Dashboards": {
        "msgid": "Custom Dashboards",
        "msgstr": [
          "Tableaux de bord personnalisés"
        ]
      },
      "Dashboards": {
        "msgid": "Dashboards",
        "msgstr": [
          "Tableaux de bord"
        ]
      },
      "Data Collection": {
        "msgid": "Data Collection",
        "msgstr": [
          "Collecte de données"
        ]
      },
      "Discover and analyze your installed enterprise software.": {
        "msgid": "Discover and analyze your installed enterprise software.",
        "msgstr": [
          "Découvrez et analysez votre logiciel d'entreprise installé."
        ]
      },
      "Hardware": {
        "msgid": "Hardware",
        "msgstr": [
          "Matériel"
        ]
      },
      "Hardware.": {
        "msgid": "Hardware.",
        "msgstr": [
          "Matériel."
        ]
      },
      "IT Asset Management Settings": {
        "msgid": "IT Asset Management Settings",
        "msgstr": [
          "Paramètres de gestion des biens informatiques"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Demandes de biens informatiques"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "IT Visibility Inventory Status": {
        "msgid": "IT Visibility Inventory Status",
        "msgstr": [
          "Statut de l'inventaire de IT Visibility"
        ]
      },
      "IT Visibility Inventory Tasks": {
        "msgid": "IT Visibility Inventory Tasks",
        "msgstr": [
          "Tâches de l'inventaire de IT Visibility"
        ]
      },
      "Identity Management": {
        "msgid": "Identity Management",
        "msgstr": [
          "Gestion de l'identité"
        ]
      },
      "Inventory": {
        "msgid": "Inventory",
        "msgstr": [
          "Inventaire"
        ]
      },
      "Inventory.": {
        "msgid": "Inventory.",
        "msgstr": [
          "Inventaire."
        ]
      },
      "License (Beta)": {
        "msgid": "License (Beta)",
        "msgstr": [
          "Licence (bêta)"
        ]
      },
      "Licenses": {
        "msgid": "Licenses",
        "msgstr": [
          "Licences"
        ]
      },
      "Licenses.": {
        "msgid": "Licenses.",
        "msgstr": [
          "Licences."
        ]
      },
      "Manage Flexera platform for your organization.": {
        "msgid": "Manage Flexera platform for your organization.",
        "msgstr": [
          "Gérez la plateforme Flexera pour votre organisation."
        ]
      },
      "Manage your organization's SaaS spend.": {
        "msgid": "Manage your organization's SaaS spend.",
        "msgstr": [
          "Gérez les dépenses de l'application SaaS de votre organisation."
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organisation"
        ]
      },
      "Organization.": {
        "msgid": "Organization.",
        "msgstr": [
          "Organisation."
        ]
      },
      "Plugins": {
        "msgid": "Plugins",
        "msgstr": [
          "Plugins"
        ]
      },
      "Portal Management": {
        "msgid": "Portal Management",
        "msgstr": [
          "Gestion du portail"
        ]
      },
      "Procurement": {
        "msgid": "Procurement",
        "msgstr": [
          "Achats"
        ]
      },
      "Procurement.": {
        "msgid": "Procurement.",
        "msgstr": [
          "Achats."
        ]
      },
      "Purchases & Vendors (Beta)": {
        "msgid": "Purchases & Vendors (Beta)",
        "msgstr": [
          "Achats et fournisseurs (bêta)"
        ]
      },
      "Reporting": {
        "msgid": "Reporting",
        "msgstr": [
          "Rapports"
        ]
      },
      "Reporting.": {
        "msgid": "Reporting.",
        "msgstr": [
          "Rapports."
        ]
      },
      "Request Portal": {
        "msgid": "Request Portal",
        "msgstr": [
          "Portail de demande"
        ]
      },
      "SBOM Management": {
        "msgid": "SBOM Management",
        "msgstr": [
          "Gestion des SBOM"
        ]
      },
      "SBOM Management.": {
        "msgid": "SBOM Management.",
        "msgstr": [
          "Gestion des SBOM."
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS Applications": {
        "msgid": "SaaS Applications",
        "msgstr": [
          "Applications SaaS"
        ]
      },
      "SaaS License Management": {
        "msgid": "SaaS License Management",
        "msgstr": [
          "Gestion des licences SaaS"
        ]
      },
      "SaaS Settings": {
        "msgid": "SaaS Settings",
        "msgstr": [
          "Paramètres SaaS"
        ]
      },
      "Software Vulnerability Research.": {
        "msgid": "Software Vulnerability Research.",
        "msgstr": [
          "Recherche de vulnérabilités logicielles SVR."
        ]
      },
      "Technopedia": {
        "msgid": "Technopedia",
        "msgstr": [
          "Technopedia"
        ]
      },
      "Technopedia Dashboard.": {
        "msgid": "Technopedia Dashboard.",
        "msgstr": [
          "Tableau de bord Technopedia."
        ]
      },
      "Understand your cloud costs and usage.": {
        "msgid": "Understand your cloud costs and usage.",
        "msgstr": [
          "Cernez vos coûts et votre utilisation du cloud."
        ]
      },
      "Vulnerabilities": {
        "msgid": "Vulnerabilities",
        "msgstr": [
          "Vulnérabilités"
        ]
      }
    }
  }
};
export default json;
