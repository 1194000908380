import { FC } from 'react';
import { useNav } from './useNav';
import { useRoutes } from './useRoutes';
import { useRedirects } from './useRedirects';

export const Automation: FC = () => {
	useNav();
	useRoutes();
	useRedirects();
	return null;
};
