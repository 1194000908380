import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import { navFooterButtonStyle, svgFillDefault } from '../constants/style';

interface PinButtonProps {
	isActive: boolean;
	drawerOpen: boolean;
	scrollable: boolean;
}

export const PinButtonBox = styled.div<PinButtonProps>`
	position: absolute;
	bottom: 0px;
	grid-column: 4 / span 2;
	display: flex;
	justify-content: flex-end;
	overflow: hidden;
	padding-right: 2px;
	padding-top: 2px;
	background: transparent;
	transition: width 200ms;
	width: ${({ drawerOpen }) => (drawerOpen ? 'var(--flyout-width)' : '0px')};
	${navFooterButtonStyle}
	button {
		padding: 0px;
		${({ scrollable }) => scrollable && `padding-right: 10px;`}
		svg {
			path:nth-child(2) {
				fill: ${({ isActive }) =>
					isActive ? BaseTheme.colors.primaryActive : svgFillDefault};
			}
		}
		&:hover {
			svg {
				path:nth-child(2) {
					fill: ${BaseTheme.colors.primaryActive};
				}
			}
		}
	}
`;
