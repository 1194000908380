import { Loader } from '@flexera/lib.loader';
import React, { FC } from 'react';
import { UATContext } from './UATContext';
import { useFetchUAT } from './useFetchUAT';

export const UATProvider: FC = ({ children }: { children: JSX.Element }) => {
	const uat = useFetchUAT();

	return (
		<UATContext.Provider value={uat}>
			{uat.loading ? <Loader isFullScreen /> : children}
		</UATContext.Provider>
	);
};
