const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Agent Configurations": {
        "msgid": "Agent Configurations",
        "msgstr": [
          "Configuraciones de agentes"
        ]
      },
      "Beacon Versions": {
        "msgid": "Beacon Versions",
        "msgstr": [
          "Versiones de estaciones de inventariado "
        ]
      },
      "Create Configuration": {
        "msgid": "Create Configuration",
        "msgstr": [
          "Crear configuración"
        ]
      },
      "Create Group": {
        "msgid": "Create Group",
        "msgstr": [
          "Crear grupo"
        ]
      },
      "Create Task List": {
        "msgid": "Create Task List",
        "msgstr": [
          "Crear lista de tareas"
        ]
      },
      "External Inventory Connections": {
        "msgid": "External Inventory Connections",
        "msgstr": [
          "Conexiones de inventario externas"
        ]
      },
      "IT Visibility Beacons": {
        "msgid": "IT Visibility Beacons",
        "msgstr": [
          "Estaciones de inventariado de visibilidad de TI"
        ]
      },
      "Import Inventory Targets": {
        "msgid": "Import Inventory Targets",
        "msgstr": [
          "Importar objetivos de inventario"
        ]
      },
      "Installers": {
        "msgid": "Installers",
        "msgstr": [
          "Instaladores"
        ]
      },
      "Inventory Groups": {
        "msgid": "Inventory Groups",
        "msgstr": [
          "Grupos de inventarios"
        ]
      },
      "Inventory Normalization Statistics": {
        "msgid": "Inventory Normalization Statistics",
        "msgstr": [
          "Estadísticas de normalización de inventarios"
        ]
      },
      "Inventory Targets": {
        "msgid": "Inventory Targets",
        "msgstr": [
          "Objetivos de inventario"
        ]
      },
      "Task Lists": {
        "msgid": "Task Lists",
        "msgstr": [
          "Listas de tareas"
        ]
      }
    }
  }
};
export default json;
