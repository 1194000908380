import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { FNMSIframe } from '../components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'fnms.bootstrap/dashboard',
				parentId: OrgsRouteId,
				path: '/slo',
				exact: false,
				component: FNMSIframe
			}
		]);
	}, []);
}
