import { useEffect } from 'react';
import { useUserProfile } from '@flexera/auth.base';
import {
	useOrgs,
	useCurrentOrg,
	OrgsLoading,
	OrgMissing
} from '@flexera/shell.orgs';

/**
 * Internal hook used to set values in local storage for Google Tag Manager analytics and Heap tracking
 */
export const useUpdateGTMData = () => {
	const user = useUserProfile();
	const organizations = useOrgs();
	const org = useCurrentOrg();

	useEffect(() => {
		localStorage.setItem('gtm.user', JSON.stringify(user));
		localStorage.setItem('gtm.organizations', JSON.stringify(organizations));
	}, [user, organizations]);

	useEffect(() => {
		if (org !== OrgsLoading && org !== OrgMissing) {
			localStorage.setItem('gtm.currentOrg.id', org?.id?.toString());
			localStorage.setItem('gtm.currentOrg.name', org?.name?.toString());
		}
	}, [org]);
};
