import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { ADMIN__IDENTITY } from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const orgPath = `/orgs/${orgId}`;
	const adminPath = `${orgPath}/admin`;
	const { addItems } = useNavItems();
	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeeIdentityProviders,
		Permissions.canViewIPAccess,
		Permissions.canSeeOrgInvitations,
		Permissions.hasIAM,
		Permissions.userIndex,
		Permissions.userShow,
		Permissions.groupShow,
		Permissions.groupIndex,
		Permissions.orgUserShow,
		Permissions.accessRuleIndex
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasIAM)) return;

		return addItems(
			[
				perms.get(Permissions.canSeeOrgInvitations) && {
					id: ids.INVITATIONS,
					parentId: ADMIN__IDENTITY,
					path: `${adminPath}/invitations`,
					helpHref: `${helpDocBaseUrl}/Administration/flexeraroles.htm`,
					urlMatch: /^\/orgs\/\d+\/admin\/invitations(\/|$|\?)/,
					label: t`Invitations`,
					priority: 4
				},
				perms.get(Permissions.canSeeIdentityProviders) && {
					id: ids.IDPS,
					parentId: ADMIN__IDENTITY,
					path: `${adminPath}/identity-providers`,
					helpHref: `${helpDocBaseUrl}/Administration/IDPConfig.htm`,
					urlMatch: /^\/orgs\/\d+\/admin\/identity-providers(\/|$|\?)/,
					label: t`Identity Providers`,
					priority: 5
				},
				perms.get(Permissions.canViewIPAccess) && {
					id: ids.IPSAFELIST,
					parentId: ADMIN__IDENTITY,
					path: `${adminPath}/ip-access-control`,
					helpHref: `${helpDocBaseUrl}/Administration/IPAccess.htm`,
					urlMatch: /^\/orgs\/\d+\/admin\/ip-access-control(\/|$|\?)/,
					label: t`IP Access Control`,
					priority: 6
				},
				perms.get(Permissions.userIndex) &&
					perms.get(Permissions.userShow) &&
					perms.get(Permissions.orgUserShow) &&
					perms.get(Permissions.accessRuleIndex) && {
						id: ids.USER_MANAGEMENT,
						parentId: ADMIN__IDENTITY,
						path: `${adminPath}/user-management`,
						helpHref: `${helpDocBaseUrl}/Administration/users.htm`,
						urlMatch: /^\/orgs\/\d+\/admin\/user-management(\/|$|\?)/,
						label: t`User Management`,
						priority: 1
					},
				perms.get(Permissions.groupIndex) &&
					perms.get(Permissions.groupShow) && {
						id: ids.GROUP_MANAGEMENT,
						parentId: ADMIN__IDENTITY,
						path: `${adminPath}/group-management`,
						helpHref: `${helpDocBaseUrl}/Administration/managinggroups.htm`,
						urlMatch: /^\/orgs\/\d+\/admin\/group-management(\/|$|\?)/,
						label: t`Group Management`,
						priority: 2
					},
				(perms.get(Permissions.userIndex) || perms.get(Permissions.userShow)) && {
					id: ids.ACCOUNT_MANAGEMENT,
					parentId: ADMIN__IDENTITY,
					path: `${adminPath}/account-management`,
					helpHref: `${helpDocBaseUrl}/Administration/AdminGettingStarted.htm`,
					urlMatch: /^\/orgs\/\d+\/admin\/account-management(\/|$|\?)/,
					label: t`Account Management`,
					priority: 3
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
