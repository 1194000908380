import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { Environment, environment } from '@flexera/lib.environment';
import { useEffect } from 'react';
import * as Pages from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	const isLocalOrStaging = [Environment.Local, Environment.Staging].includes(
		environment
	);

	useEffect(() => {
		if (!isLocalOrStaging) return;
		return addRoutes([
			{
				id: 'vue.bootstrap/vue-demo',
				parentId: OrgsRouteId,
				path: '/vue/demo',
				exact: true,
				component: Pages.VueDemo
			}
		]);
	}, []);
}
