import { BaseTheme } from '@flexera/ui.component-library';
import styled from 'styled-components';

interface PlayListProps {
	left: string;
}

export const Playlist = styled.div<PlayListProps>`
	margin-left: ${BaseTheme.space.lg};
	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin-left: ${BaseTheme.space.md};
	}
`;

export const VideoFrame = styled.div`
	margin-right: ${BaseTheme.space.sm};
	width: 214px;
	height: 128px;
	position: relative;
	display: inline-block;
	box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
	border-radius: 10px;

	button.playButton {
		margin: unset;
		position: relative;
		box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
		border-radius: 10px;

		&:hover {
			background: none;
		}
	}

	p {
		font-size: 14px;
		margin: unset;
		grid-area: description;
		text-align: left;
	}

	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin-bottom: ${BaseTheme.space.sm};
	}
`;

export const Thumbnail = styled.img`
	border-radius: 10px;
	grid-area: thumbnail;
	width: 214px;
	height: 128px;
`;
