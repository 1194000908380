import React, { useEffect, useState } from 'react';
import { P, Cell } from '@flexera/ui.component-library';
import { t } from 'ttag';
import { ImageWrapper, TestimonialsWrapper, TestimonialsGrid } from './Styled';
import { CardTitle, Subtitle } from '../Styled';
import { StarsIcon } from '../assets';
import { TestimonialsData } from './Testimonals.data';

export const Testimonials = () => {
	const [testimonials, setTestimonials] = useState([]);
	useEffect(() => {
		const testimonialArray = [];
		testimonialArray.push(
			TestimonialsData[Math.floor(Math.random() * TestimonialsData.length)]
		);
		let testimonal =
			TestimonialsData[Math.floor(Math.random() * TestimonialsData.length)];

		while (testimonialArray[0].id === testimonal.id) {
			testimonal =
				TestimonialsData[Math.floor(Math.random() * TestimonialsData.length)];
		}

		testimonialArray.push(testimonal);
		setTestimonials(testimonialArray);
	}, []);
	return (
		<TestimonialsWrapper id={'testimonials'}>
			<CardTitle>{t`TESTIMONIALS`}</CardTitle>
			<Subtitle>{t`What our customers say`}</Subtitle>
			<TestimonialsGrid columns={2} columnGap={'8.75rem'}>
				{testimonials.map((testimonal) => (
					<Cell key={testimonal.id}>
						<StarsIcon />
						<P mt={'xxs'} mb={'sm'}>
							{testimonal.testimonial}
						</P>
						<P
							variant={'small'}
							color={'blue70'}
							fontWeight={'semiBold'}
							mt={'xxs'}
							mb={'0'}
						>
							{testimonal.userName}
						</P>
						<P variant={'small'} color={'blue70'} mt={'0'} mb={'xs'}>
							{testimonal.title}
						</P>
						<ImageWrapper>
							<img
								id={`${testimonal.id}-logo`}
								title={'Testimonal'}
								alt={'Testimonal Logo'}
								src={testimonal.logo}
							/>
						</ImageWrapper>
					</Cell>
				))}
			</TestimonialsGrid>
		</TestimonialsWrapper>
	);
};
