import { FC, useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import {
	callTokenRefresh,
	setSessionRefreshHasFailedAction,
	setSessionWillTimeoutAction,
	setSessionHasEndedAction,
	setThirdPartyActive
} from '@flexera/lib.auth';
import {
	useTokenState,
	determineReturnURL,
	useUserSession
} from '@flexera/auth.iam';
import { useDispatch } from 'react-redux';
import { useLogout } from '@flexera/auth.base';

export const SessionTimeout: FC = () => {
	let timer: ReturnType<typeof setTimeout> = null;
	const [, setRemaining] = useState<number>(0);
	const dispatch = useDispatch();
	const tokenState = useTokenState();
	const userSession = useUserSession();
	const logout = useLogout();
	const isCypress =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).Cypress &&
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).Cypress.spec.fileName === 'SessionTimeout';

	const sessionLogout = () => {
		if (isCypress) {
			window.location.href = '/logout';
		} else {
			logout(determineReturnURL());
		}
	};

	const timeout = 1000 * 60 * 29; // 29 mins
	const promptBeforeIdle = 1000 * 60 * 1; // 1min

	const {
		isPrompted,
		isIdle,
		pause,
		reset,
		message,
		isLeader,
		getRemainingTime
	} = useIdleTimer({
		onPrompt: () => {
			dispatch(setSessionWillTimeoutAction('open', message));
		},
		onIdle: () => {
			pause();
			localStorage.removeItem('activeSession');
			dispatch(setSessionHasEndedAction(true, message));
		},
		onActive: () => {
			dispatch(setSessionWillTimeoutAction('close', message));
		},
		onMessage: (receivedMessage: string) => {
			if (receivedMessage === 'still-here') {
				message('close-prompt', true);
				if (isLeader()) {
					dispatch(callTokenRefresh());
				}
				reset();
			}
			if (receivedMessage === 'close-prompt') {
				dispatch(setSessionWillTimeoutAction('close', message));
			}
			if (isPrompted() && receivedMessage === 'warning-logout') {
				dispatch(setSessionWillTimeoutAction('close', message));
				sessionLogout();
			}
			if (receivedMessage === 'idle-logout') {
				sessionLogout();
			}
		},
		timeout,
		promptBeforeIdle,
		events: [
			'mousemove',
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'visibilitychange'
		],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: true,
		crossTab: true,
		name: 'f1-idle-timer',
		syncTimers: 500,
		timers: workerTimers,
		leaderElection: true
	});

	const handleTokenRefreshTimer = () => {
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
		dispatch(callTokenRefresh()); // Leader gets a brand new token to avoid old tokens that expiry in < 20mins or leader status updating
		timer = setInterval(() => {
			if (!isPrompted() && !isIdle()) {
				dispatch(callTokenRefresh());
			}
		}, 1000 * 60 * 20); // Then leader will get a new token every 20 minutes as long as they're active
	};

	useEffect(() => {
		if (userSession?.thirdPartyActive) {
			reset();
			dispatch(setThirdPartyActive(false));
		}
	}, [userSession]);

	useEffect(() => {
		if (tokenState?.tokenRefreshError) {
			dispatch(setSessionRefreshHasFailedAction(true, message));
		}
	}, [tokenState]);

	const leader = isLeader();

	useEffect(() => {
		if (leader) {
			handleTokenRefreshTimer();
		} else {
			clearInterval(timer);
		}
	}, [leader]);

	useEffect(() => {
		// Need something to update the component state
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 1000);

		return () => {
			clearInterval(timer);
			clearInterval(interval);
		};
	}, []);

	return null;
};
