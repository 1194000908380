import { useDataSourceItems } from '@flexera/data-visualization.data';
import { getSoftwareLicensesData } from './getSoftwareLicensesData';
import { getCategoryData } from './getCategoryData';
import { getOperatingSystemData } from './getOperatingSystemData';
import { getProductData } from './getProductData';

export const useDataSources = () => {
	const { addDataSources } = useDataSourceItems();
	return addDataSources([
		{
			id: 'vendor.product',
			name: 'Vendor Product',
			getData: (orgId: number) => getProductData(orgId)
		},
		{
			id: 'vendor.operatingsystem',
			name: 'Vendor Operating System',
			getData: (orgId: number) => getOperatingSystemData(orgId)
		},
		{
			id: 'vendor.category',
			name: 'Vendor Category',
			getData: (orgId: number) => getCategoryData(orgId)
		},
		{
			id: 'vendor.softwarelicenses',
			name: 'Software Licenses',
			getData: (orgId: number, filterOption, filter: string) =>
				getSoftwareLicensesData(orgId, filterOption, filter)
		}
	]);
};
