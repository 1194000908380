import { useEffect } from 'react';
import { t } from 'ttag';

import { useAuthenticated } from '@flexera/auth.base';
import { usePermissionSummary, Permissions } from '@flexera/lib.permissions';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { DASHBOARDS, POLICIES } from '@flexera/shell.base-nav-tree';

import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const org = useCurrentOrg();
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const capabilityPath = `/orgs/${orgId}/automation`;

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeePolicyCatalog,
		Permissions.canViewAppliedPolicies,
		Permissions.canViewIncidents,
		Permissions.canViewPolicyTemplates,
		Permissions.hasPolicies,
		Permissions.cloudCredentialsIndex
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasPolicies)) {
			return;
		}
		return addItems(
			[
				perms.get(Permissions.canViewAppliedPolicies) && {
					id: ids.DASHBOARDS,
					label: t`Automation`,
					parentId: DASHBOARDS,
					path: `${capabilityPath}/dashboards`,
					priority: 40,
					urlMatch: /^\/orgs\/\d+\/automation\/dashboards(\/|$|\?)/
				},
				perms.get(Permissions.hasPolicies) &&
					perms.get(Permissions.canSeePolicyCatalog) && {
						id: ids.CATALOG,
						parentId: POLICIES,
						path: `/orgs/${orgId}/automation/catalog`,
						urlMatch: /^\/orgs\/\d+\/automation\/catalog/,
						label: t`Catalog`
					},
				perms.get(Permissions.canViewAppliedPolicies) && {
					id: ids.APPLIEDPOLICIES,
					parentId: POLICIES,
					label: t`Applied Policies`,
					path: `${capabilityPath}/applied-policies`,
					urlMatch: /^\/orgs\/\d+\/automation\/applied-policies(\/|$|\?)/
				},
				perms.get(Permissions.canViewIncidents) && {
					id: ids.INCIDENTS,
					parentId: POLICIES,
					label: t`Incidents`,
					path: `${capabilityPath}/incidents`,
					urlMatch: /^\/orgs\/\d+\/automation\/incidents(\/|$|\?)/
				},
				perms.get(Permissions.canViewPolicyTemplates) && {
					id: ids.POLICY_TEMPLATES,
					parentId: POLICIES,
					label: t`Templates`,
					path: `${capabilityPath}/policy-templates`,
					urlMatch: /^\/orgs\/\d+\/automation\/projects\/\d+\/policy-templates(\/|$|\?)/
				},
				perms.get(Permissions.cloudCredentialsIndex) && {
					id: ids.CREDENTIALS,
					parentId: POLICIES,
					label: t`Credentials`,
					path: `${capabilityPath}/credentials`,
					urlMatch: /^\/orgs\/\d+\/automation\/projects\/\d+\/credentials(\/|$|\?)/,
					helpHref: `${helpDocBaseUrl}/Automation/CredentialsIntro.htm`
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms]);
}
