import ssSemiboldEot from './SourceSansPro-Semibold.eot';
import ssSemiboldTtf from './SourceSansPro-Semibold.ttf';
import ssSemiboldWoff from './SourceSansPro-Semibold.woff';
import ssSemiboldWoff2 from './SourceSansPro-Semibold.woff2';

export const SourceSansSemiBold = `
  @font-face {
    font-family: 'Source Sans Pro';
    src: url(${ssSemiboldEot});
    src: url(${ssSemiboldTtf}) format('truetype'),
         url(${ssSemiboldWoff}) format('woff'),
         url(${ssSemiboldWoff2}) format('woff2');
    font-weight: 600;
    font-style: normal;
  }
`;
