import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const TechnopediaApp = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/technopedia.main');
		return { default: (await mod).Dashboard };
	}),
	'content'
);
