import React, { FC } from 'react';
import { ChildRoutes } from '@flexera/lib.router';
import { useLocation } from 'react-router-dom';
import { Navigation as NavigationMenu } from '@flexera/ui.navigation';
import { useOrgInfo } from '@flexera/shell.orgs';
import { Loader } from '@flexera/lib.loader';
import { Header } from '@flexera/shell.header';
import { useSessionTimeout } from '@flexera/flexera-one.feature-flags';
import { useMenu, NavItem, usePageTitle } from '@flexera/shell.navigation';
import {
	AsyncMessageStateModule,
	AsyncMessages
} from '@flexera/lib.async-message';
import { SessionTimeout } from '@flexera/lib.inactivity';
import { useWhitelabelPolicy } from '@flexera/shell.whitelabel';
import { GridLayout } from '../styles/AppLayout';

interface props {
	menuItems: NavItem[];
}

const MainAppGrid: FC<props> = ({ menuItems }: props) => {
	const documentTitle = usePageTitle();
	const location = useLocation();
	const orgInfo = useOrgInfo();
	const loadSessionTimeout = useSessionTimeout();
	const { isFetchingAuthPolicy } = useWhitelabelPolicy();

	const flexeraTitle = 'Flexera';
	const isSettings = /^\/settings\/.+/.test(location.pathname);
	const isNoAccess = /^\/orgs\/\d*\/no-access$/.test(location.pathname);

	if (!/^\/orgs\/\d+\/itv\/custom-dashboard\/.+/.test(location.pathname)) {
		document.title = documentTitle
			? `${documentTitle} - ${flexeraTitle}`
			: flexeraTitle;
	}

	if (isNoAccess) {
		return (
			<>
				<>
					<Header />
					<AsyncMessageStateModule>
						<ChildRoutes />
						<AsyncMessages />
					</AsyncMessageStateModule>
				</>
			</>
		);
	}

	return (
		<>
			{!orgInfo && !isSettings && <Loader />}
			<GridLayout
				hideUntilReady={!orgInfo && !isSettings && !isFetchingAuthPolicy}
			>
				{loadSessionTimeout && <SessionTimeout />}
				<Header />
				<NavigationMenu items={menuItems} />
				<AsyncMessageStateModule>
					<ChildRoutes />
					<AsyncMessages />
				</AsyncMessageStateModule>
			</GridLayout>
		</>
	);
};

export const AppLayout: FC = () => {
	const menuItems = useMenu();

	return menuItems && menuItems.length > 0 ? (
		<MainAppGrid menuItems={menuItems as NavItem[]} />
	) : (
		<ChildRoutes />
	);
};
