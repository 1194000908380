import React from 'react';
import { NavItem } from '@flexera/shell.navigation';
import { NavMenu } from './Menu.styled';
import { MenuContent } from './MenuContent';
import { NavigationContext } from '../navigationContext';

interface MenuProps {
	items: NavItem[];
	searchWord?: string;
}

export const Menu = ({ items, searchWord }: MenuProps) => {
	const { isExpanded } = React.useContext(NavigationContext);

	/** Let Navigation container know that resizer is active, so it won't close the flyout */

	// do not render a menu if there are no items
	if (!items) {
		return null;
	}

	return (
		<NavMenu aria-label={'Main Menu'} role={'navigation'} isExpanded={isExpanded}>
			<MenuContent items={items} searchWord={searchWord} />
		</NavMenu>
	);
};
