import React from 'react';
import styled from 'styled-components';
import { IconMenu } from '@flexera/ui.component-library';
import { useHelpHref } from '@flexera/shell.navigation';
import { useFNMSHelpOverride } from '@flexera/fnms.core';
import { useFNMSCapabilityCheck } from '@flexera/fnms.shared';
import { t } from 'ttag';
import { communityHelpUrl } from '@flexera/lib.utilities';
import { HelpIcon } from '../assets';

// @TODO: Overriding the max-width property from IconMenu's wrapper as a temporary fix for preventing overflow.
// The proper fix would involve changing the max-width on the core IconMenu wrapper but this will be a large
// change because the IconMenu is used many times throughout this project. Remove this override when that work is done.
const StyledWrapper = styled.div`
	#styled-icon-menu {
		max-width: max-content;
	}
`;

export const HelpNavLink = ({
	right,
	noWrapping,
	customId
}: HelpNavLinkProps) => {
	const supportHref = useHelpHref();
	const { hasFNMS } = useFNMSCapabilityCheck();
	const fnmsSupportHref = useFNMSHelpOverride();
	const splashPage = window.location.pathname.includes('splash');
	const fnmsView = window.location.pathname.includes('slo');

	const handleSupportClick = () => {
		if (hasFNMS && fnmsView) {
			window.open(fnmsSupportHref, '_blank');
		} else {
			window.open(supportHref, '_blank');
		}
		return true;
	};

	const handleCommunityClick = () => {
		window.open(communityHelpUrl, '_blank');
	};

	const headerHelpNavItems = [
		{
			id: 'view-documentation',
			onClick: handleSupportClick,
			target: '_blank',
			subtitle: t`View Documentation`,
			titleAttribute: t`Press here to view documentation with detailed feature on docs.flexera.com.`,
			itemicon: true,
			hasdivider: false
		},
		{
			id: 'get-help-on-community',
			onClick: handleCommunityClick,
			target: '_blank',
			subtitle: t`Get help in Community`,
			titleAttribute: t`Press here to Get help in Community and discuss this feature on the Flexera Community.`,
			itemicon: true,
			hasdivider: false
		}
	];

	return (
		<StyledWrapper>
			<IconMenu
				iconOnly
				noWrapping={noWrapping}
				right={right}
				icon={HelpIcon}
				customId={customId}
				variant={'minimalSecondary'}
				id={splashPage ? 'help-icon-splash' : 'help-icon'}
				title={t`Press help icon to show all options.`}
				options={
					headerHelpNavItems &&
					headerHelpNavItems.map((option) => {
						return {
							id: option?.id,
							subtitle: option?.subtitle,
							titleAttribute: option?.titleAttribute,
							hasdivider: option?.hasdivider,
							itemicon: option?.itemicon,
							onClick: option?.onClick,
							onKeyPress: option?.onClick,
							...option
						};
					})
				}
			/>
		</StyledWrapper>
	);
};

export interface HelpNavLinkProps {
	/** positions menu to the right of button */
	right?: boolean;
	/** kills word wrapping styles */
	noWrapping?: boolean;
	customId: boolean;
}
