import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { DomainNormalization } from './components';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: 'odm.bootstrap/domain-normalization',
				parentId: OrgsRouteId,
				path: '/odm/domain-normalization',
				exact: true,
				component: DomainNormalization
			}
		]);
	}, []);
}
