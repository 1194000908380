import styled from 'styled-components';
import { BaseTheme, Flex, Grid } from '@flexera/ui.component-library';

export const TestimonialsWrapper = styled(Flex)`
	background: ${BaseTheme.colors.light};
	box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
	margin: 5.5rem ${BaseTheme.space.lg};
	padding: ${BaseTheme.space.lg};
	flex-direction: column;
	border-radius: 10px;
	@media screen and (max-width: ${BaseTheme.breakpoints[1]}px) {
		margin: 5.5rem ${BaseTheme.space.md};
	}
`;

export const TestimonialsGrid = styled(Grid)`
	@media screen and (max-width: ${BaseTheme.breakpoints[2]}px) {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: ${BaseTheme.space.lg};
	}
`;

export const ImageWrapper = styled.div`
	img {
		height: 30px;
	}
`;
