import React, { useCallback, useEffect, useRef } from 'react';
import {
	filterNavItems,
	NavItem,
	useDebounce
} from '@flexera/shell.navigation';
import { useUserPreferences } from '@flexera/shell.user-preferences';
import { t } from 'ttag';
import { Icon, IconMdSearch, IconMdClear } from '@flexera/ui.component-library';
import { useOrgId } from '@flexera/shell.orgs';
import * as DOMPurify from 'dompurify';
import { Menu } from '../Menu/Menu';
import { NavigationLogo } from '../Navigation.styled';
import { NavigationContext } from '../navigationContext';
import {
	SearchForm,
	SearchContainer,
	NoResultsMessage,
	HiddenLabelForSearch
} from './Search.styled';
import { SearchContext } from './SearchContext';
import { RecentSearches } from './RecentSearches/RecentSearches';
import { Logo } from './Logo';
import { filterRecentSearch, toggleEnterKey } from '../../utilities';
import { navSearchId, noSearchResults } from '../constants';

interface SearchProps {
	items: NavItem[];
}

export const Search = ({ items }: SearchProps) => {
	const [searchWord, setSearchWord] = React.useState('');
	const [matchedItems, setMatchedItems] = React.useState([]);
	const [recentSearches, setRecentSearches] = React.useState([]);
	const {
		isExpanded,
		setIsExpanded,
		searchIsActive,
		setSearchIsActive,
		isPinned,
		setIsPinned,
		setPinnedItem
	} = React.useContext(NavigationContext);
	const orgId = useOrgId();
	const inputRef = useRef(null);
	const {
		setUserPreference,
		getUserPreference,
		preferences
	} = useUserPreferences();

	const getMatches = useDebounce(() => {
		if (items && items.length > 0) {
			const results = filterNavItems(searchWord, items);
			if (results.length > 0) {
				const filteredRecentSearches = filterRecentSearch(
					searchWord,
					recentSearches
				);
				setRecentSearches(filteredRecentSearches);
				setUserPreference({
					id: 'recent_searches',
					orgId: Number(orgId),
					value: JSON.stringify(filteredRecentSearches)
				});
			}
			setMatchedItems(results);
		}
	}, 300);

	const handleActivateSearch = () => {
		if (!isExpanded) setIsExpanded(true);
		if (isPinned) {
			setPinnedItem('');
			setIsPinned(!isPinned);
		}
		if (!searchIsActive) setSearchIsActive(true);
	};

	const handleResetSearch = () => {
		inputRef?.current?.focus();
		setSearchWord('');
	};

	const handleCancelSearch = () => {
		setSearchIsActive(false);
		setSearchWord('');
	};

	const matchedMenu = <Menu items={matchedItems} searchWord={searchWord} />;

	useEffect(() => {
		const fetchSearches = async () => {
			const fetchedSearches = await getUserPreference('recent_searches', orgId);
			const searches =
				fetchedSearches && JSON.parse(fetchedSearches?.value as string);
			setRecentSearches(searches);
		};

		fetchSearches();
	}, [orgId, preferences]);

	useEffect(() => {
		if (searchWord !== '') {
			getMatches();
		}
	}, [searchWord]);

	const handleEscapeFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			handleCancelSearch();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', handleEscapeFunction, false);

		return () => {
			document.removeEventListener('keydown', handleEscapeFunction, false);
		};
	}, [handleEscapeFunction]);

	return (
		<SearchContext.Provider value={{ searchWord }}>
			<NavigationLogo id={'nav-logo'} isExpanded={isExpanded}>
				<Logo />
				<SearchForm
					id={navSearchId}
					tabIndex={-1}
					isExpanded={isExpanded}
					role={'search'}
					onSubmit={(e) => e.preventDefault()}
					searchIsActive={searchIsActive}
				>
					<HiddenLabelForSearch
						htmlFor={'search-input'}
					>{t`Navigation Search`}</HiddenLabelForSearch>
					<SearchContainer isExpanded={isExpanded} searchIsActive={searchIsActive}>
						<input
							tabIndex={0}
							ref={inputRef}
							type={'text'}
							id={'search-input'}
							placeholder={t`Search`}
							autoComplete={'off'}
							aria-label={'Navigation Search'}
							value={searchIsActive ? searchWord : ''}
							onFocus={handleActivateSearch}
							onKeyDown={(e) => toggleEnterKey(e, handleActivateSearch)}
							onChange={(e) =>
								setSearchWord(
									DOMPurify.sanitize(e.target.value, { ALLOWED_TAGS: [''] })
								)
							}
							onClick={() => handleActivateSearch()}
						/>
						<button
							type={'button'}
							tabIndex={0}
							className={'searchButton'}
							aria-label={searchIsActive ? t`Clear your search` : t`Begin Searching`}
							onClick={() =>
								searchIsActive
									? handleResetSearch()
									: setSearchIsActive(!searchIsActive)
							}
							onKeyDown={(e) => toggleEnterKey(e, handleActivateSearch)}
						>
							<Icon
								src={searchIsActive ? IconMdClear : IconMdSearch}
								title={searchIsActive ? t`Clear your search` : t`Begin Searching`}
							/>
						</button>
					</SearchContainer>
					<button
						name={'cancel-search'}
						type={'button'}
						aria-label={t`Press here to cancel the navigation search and see the navigation menu list.`}
						disabled={!searchIsActive}
						className={'cancelSearchButton'}
						onClick={() => handleCancelSearch()}
						onKeyDown={(e) => toggleEnterKey(e, handleCancelSearch)}
					>
						{t`Cancel`}
					</button>
				</SearchForm>
			</NavigationLogo>
			{searchIsActive && searchWord === '' && (
				<RecentSearches
					recentSearches={recentSearches}
					setSearchWord={setSearchWord}
				/>
			)}

			{searchWord !== '' && matchedItems.length === 0 && (
				<NoResultsMessage>{noSearchResults}</NoResultsMessage>
			)}

			{searchIsActive &&
				matchedItems.length > 0 &&
				searchWord !== '' &&
				matchedMenu}
		</SearchContext.Provider>
	);
};
