import styled from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

export const RecentSearchList = styled.ul`
	grid-column: 1;
	grid-row: 2;
	list-style: none;
	margin: unset;
	padding: ${BaseTheme.space.sm};

	li:first-child {
		margin-top: ${BaseTheme.space.xs};
	}

	li {
		line-height: 18px;
		padding-bottom: 2px;
		letter-spacing: 0.1px;
	}
`;

export const RecentSearchLink = styled.a`
	text-decoration: underline;
	cursor: pointer;

	&:before,
	&:after {
		display: inline-block;
		white-space: pre-wrap;
	}

	&:before {
		content: open-quote;
	}

	&:after {
		content: close-quote;
	}
`;
