const makeId = (id: string) => `udi.bootstrap${id}`;

export const BASE = makeId('');
export const LOADING = makeId('.loading');
export const UDI = makeId('/udi');
export const TARGETS = makeId('/udi/targets');
export const IMPORT_TARGETS = makeId('/udi/ImportTargets');
export const GROUPS = makeId('/udi/groups');
export const EDIT_GROUPS = makeId('/udi/groups/edit');
export const CREATE_GROUPS = makeId('/udi/groups/create');
export const TASKLIST = makeId('/udi/tasklist');
export const EDIT_TASKLIST = makeId('/udi/tasklist/edit');
export const CREATE_TASKLIST = makeId('/udi/tasklist/create');
export const IMPORTS = makeId('/udi/imports');
export const IMPORT_DETAILS = makeId('/udi/imports/details');
export const BEACONS = makeId('/udi/beacons');
export const BEACON_DETAILS = makeId('/udi/beacons/betails');
export const HISTORY = makeId('/udi/history');
export const INSTALLERS = makeId('/udi/installers');
export const AGENTS = makeId('/udi/agents');
export const AGENTS_CREATE = makeId('/udi/agents/create');
export const AGENTS_EDIT = makeId('/udi/agents/edit');
export const TRANSPARENCY = makeId('/udi/transparency');
export const NORMALIZATION_STATISTICS = makeId('/udi/normalization-statistics');
