const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Agent Configurations": {
        "msgid": "Agent Configurations",
        "msgstr": [
          "Agent Configurations"
        ]
      },
      "Beacon Versions": {
        "msgid": "Beacon Versions",
        "msgstr": [
          "Beacon Versions"
        ]
      },
      "Create Configuration": {
        "msgid": "Create Configuration",
        "msgstr": [
          "Create Configuration"
        ]
      },
      "Create Group": {
        "msgid": "Create Group",
        "msgstr": [
          "Create Group"
        ]
      },
      "Create Task List": {
        "msgid": "Create Task List",
        "msgstr": [
          "Create Task List"
        ]
      },
      "External Inventory Connections": {
        "msgid": "External Inventory Connections",
        "msgstr": [
          "External Inventory Connections"
        ]
      },
      "IT Visibility Beacons": {
        "msgid": "IT Visibility Beacons",
        "msgstr": [
          "IT Visibility Beacons"
        ]
      },
      "Import Inventory Targets": {
        "msgid": "Import Inventory Targets",
        "msgstr": [
          "Import Inventory Targets"
        ]
      },
      "Installers": {
        "msgid": "Installers",
        "msgstr": [
          "Installers"
        ]
      },
      "Inventory Groups": {
        "msgid": "Inventory Groups",
        "msgstr": [
          "Inventory Groups"
        ]
      },
      "Inventory Normalization Statistics": {
        "msgid": "Inventory Normalization Statistics",
        "msgstr": [
          "Inventory Normalization Statistics"
        ]
      },
      "Inventory Targets": {
        "msgid": "Inventory Targets",
        "msgstr": [
          "Inventory Targets"
        ]
      },
      "Task Lists": {
        "msgid": "Task Lists",
        "msgstr": [
          "Task Lists"
        ]
      }
    }
  }
};
export default json;
