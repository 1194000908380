const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Catalog": {
        "msgid": "Catalog",
        "msgstr": [
          "Catalog"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Connections"
        ]
      },
      "Edit Catalog Form": {
        "msgid": "Edit Catalog Form",
        "msgstr": [
          "Edit Catalog Form"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "Manage Catalog": {
        "msgid": "Manage Catalog",
        "msgstr": [
          "Manage Catalog"
        ]
      },
      "My Requests": {
        "msgid": "My Requests",
        "msgstr": [
          "My Requests"
        ]
      },
      "New Catalog Form": {
        "msgid": "New Catalog Form",
        "msgstr": [
          "New Catalog Form"
        ]
      },
      "Requests": {
        "msgid": "Requests",
        "msgstr": [
          "Requests"
        ]
      }
    }
  }
};
export default json;
