const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Cloud Inventory": {
        "msgid": "Cloud Inventory",
        "msgstr": [
          "Cloud-Inventar"
        ]
      },
      "Plugin Catalog": {
        "msgid": "Plugin Catalog",
        "msgstr": [
          "Plugin-Katalog"
        ]
      },
      "Registered Plugins": {
        "msgid": "Registered Plugins",
        "msgstr": [
          "Registrierte Plugins"
        ]
      }
    }
  }
};
export default json;
