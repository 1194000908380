import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { PROCUREMENT__CONTRACTS_BETA } from '@flexera/shell.base-nav-tree';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useEntitlements } from '@flexera/flexera-one.feature-flags';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const hasEntitlementsAccess = useEntitlements();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasEntitlement,
		Permissions.canSeeContracts,
		Permissions.canCreateContract
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasEntitlement)) return;

		if (!hasEntitlementsAccess) return;

		const canSeeContracts = perms.get(Permissions.canSeeContracts);
		const canCreateContract = perms.get(Permissions.canCreateContract);

		if (!canSeeContracts) return;

		return addItems(
			[
				{
					parentId: PROCUREMENT__CONTRACTS_BETA,
					id: ids.DASHBOARD,
					helpHref: `${helpDocBaseUrl}/ITAssets/GettingStartedITAssets.htm`,
					path: `/orgs/${orgId}/contracts/dashboard`,
					urlMatch: /^\/orgs\/\d+\/contracts\/dashboard(\/|$|\?)/,
					label: t`All Contracts (Beta)`,
					priority: 50
				},
				canCreateContract && {
					parentId: PROCUREMENT__CONTRACTS_BETA,
					id: ids.NEW,
					urlMatch: /^\/orgs\/\d+\/contracts\/new(\/|$|\?)/,
					label: t`New Contract (Beta)`,
					priority: 55,
					hide: true
				},
				{
					parentId: PROCUREMENT__CONTRACTS_BETA,
					id: ids.DETAILS,
					urlMatch: /^\/orgs\/\d+\/contracts\/(?!.*dashboard|new).*$/,
					label: t`Contract Details`,
					priority: 60,
					hide: true
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loadingPerms, perms, hasEntitlementsAccess]);
}
