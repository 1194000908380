import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const PlatformReports = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/platform.main');
		return { default: (await mod).PBIReportsTable };
	}),
	'uom'
);
