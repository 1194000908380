import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const ReclaimLicenses = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/reclamation.main');
		return { default: (await mod).LicensesView };
	}),
	'recl'
);
