import { TrackJS } from 'trackjs';
import { AnyAction, Dispatch } from 'redux';
import { IExtension } from 'redux-dynamic-modules';

const trackJsLogger = () => (next: Dispatch<AnyAction>) => (
	action: AnyAction
) => {
	try {
		// log every action so they appear in the TrackJS telemetry timeline
		TrackJS.console.log(action.type);

		return next(action);
	} catch (err) {
		// NOTE: this assumes TrackJS was initialized previously, at app startup.
		if (window.TrackJS) {
			TrackJS.track(err);
		}
	}
};

const getTrackJsExtension = (): IExtension => {
	return {
		middleware: [trackJsLogger]
	};
};

export { getTrackJsExtension };
