import React, { FC } from 'react';
import { UATContext } from './UATContext';

export const DummyUATProvider: FC = ({
	children
}: {
	children: JSX.Element;
}) => {
	const uat = {
		loading: false,
		isUAT: false
	};

	return <UATContext.Provider value={uat}>{children}</UATContext.Provider>;
};
