import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const Contracts = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/contracts.main');
		return { default: (await mod).ContractsWrapper };
	}),
	'ent'
);

export const NewContract = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/contracts.main');
		return { default: (await mod).NewContract };
	}),
	'ent'
);

export const ContractDetails = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/contracts.main');
		return { default: (await mod).ContractDetails };
	}),
	'ent'
);
