import { useRoutes } from '@flexera/lib.router';
import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router';
import { OrgsRoot } from './OrgsRoot';
import { OrgsRouteId } from './OrgsRouteId';

export const OrgRoutes: FC = () => {
	const { addRoutes } = useRoutes();
	useEffect(() => {
		return addRoutes([
			{
				id: 'shell.orgs/root',
				path: '',
				exact: true,
				priority: 1000,
				// eslint-disable-next-line react/display-name
				component: () => <Redirect to={'/orgs/'} />
			},
			{
				id: OrgsRouteId,
				path: '/orgs/:orgId?',
				exact: false,
				component: OrgsRoot
			}
		]);
	}, []);

	return null;
};
