import { css } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';

/** colors in spec but not in the ui-react-component BaseTheme */
export const hoverBackground = '#3B4050';
export const activeBackground = '#23283a';

export const activeLeftBorder = `solid ${BaseTheme.colors.primaryActive} 2px`;
export const svgFillDefault = '#DDDDDD';
export const defaultFontColor = '#DDDDDD';
/** */

/** ETC SHARED CSS */

/** several components need this info but it is static,
 * unlike the flyout, so it is a css variable, not component state */
export const expandedNavWidth = '240px';
export const collapsedNavWidth = `54px`;

/** will show that one element is on top of another in a satisfying way */
export const shadow3d = css`
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
	filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.8));
`;

/** this shadow inside an element will make another element next to it
 * on the right-hand side
 * appear to be floating above it
 *  left-sided inset shadow */
export const insetShadowLeft = css`
	box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.8);
`;

export const activeLiStyle = css`
	background: ${activeBackground};
	border-left: ${activeLeftBorder};
`;

export const svgStyle = css`
	margin: unset;
	path[fill='#000'],
	path[fill='#000000'],
	path[fill='#E6E6E6'] {
		fill: ${svgFillDefault};
	}
`;

export const navFooterButtonStyle = css`
	button {
		background: unset;
		height: 32px;
		cursor: pointer;
		border: none;
	}
`;

export const hoverStyle = css`
	background: ${hoverBackground};
`;

/** makes a link look like the text around it */
export const linkStyle = css`
	a {
		overflow: hidden;
		color: inherit;
		text-decoration: none;

		&:visited {
			color: inherit;
		}
	}
`;

/** every list item has this in common */
export const listItemStyle = css`
	box-sizing: border-box;
	line-height: 18px;
	font-size: 14px;
	letter-spacing: 0.1px;
	color: ${defaultFontColor};
	${linkStyle}
	cursor: pointer;
`;

export const hiddenStyle = css`
	display: none;
`;

// 'hides' scrollbars, esp the white background, but still allows scrolling
export const hiddenScrollBarStyleAllBrowsers = css`
	&::-webkit-scrollbar {
		${hiddenStyle}
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`;
/** */
