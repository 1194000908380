import { FC, useEffect } from 'react';
import { useRoutes } from '@flexera/lib.router';
import { Loader } from '@flexera/lib.loader';

export const DefaultRoute: FC = () => {
	const { addRoutes } = useRoutes();

	useEffect(() => {
		return addRoutes([
			{
				id: 'shell-root',
				path: '',
				component: Loader,
				priority: 10000
			}
		]);
	}, []);
	return null;
};
