import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	DASHBOARDS,
	DATA_COLLECTION__SPEND__ANALYTICS
} from '@flexera/shell.base-nav-tree';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	// Permissions
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeeSpendAnalyticsDashboard,
		Permissions.hasSpendAnalytics,
		Permissions.canUploadSpendAnalyticsData
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasSpendAnalytics)) return;

		const items = [];

		if (perms.get(Permissions.canSeeSpendAnalyticsDashboard)) {
			items.push({
				id: ids.ANALYTICS,
				parentId: DASHBOARDS,
				path: `/orgs/${orgId}/spend/analytics`,
				helpHref: `${helpDocBaseUrl}/TechnologySpend/spenddata.htm`,
				urlMatch: /^\/orgs\/\d+\/spend\/analytics(\/|$|\?)/,
				label: t`Technology Spend`,
				priority: 10
			});
		}

		if (perms.get(Permissions.canUploadSpendAnalyticsData)) {
			items.push({
				id: ids.DATA_MASHUP,
				parentId: DATA_COLLECTION__SPEND__ANALYTICS,
				path: `/orgs/${orgId}/spend/data-mashup`,
				urlMatch: /^\/orgs\/\d+\/spend\/data-mashup(\/|$|\?)/,
				label: t`Data Mashup`
			});
		}
		return addItems(items);
	}, [org, orgId, authenticated, perms, loading]);
}
