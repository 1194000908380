import React, { useState, useEffect } from 'react';
import {
	Button,
	Icon,
	Modal,
	ModalContent,
	ModalFooter,
	IconMdArrowLeft,
	Flex,
	IconMdArrowRight
} from '@flexera/ui.component-library';
import { useDebounce } from '@flexera/shell.navigation';
import { t } from 'ttag';
import { Playlist, Thumbnail, VideoFrame } from './Styled';
import { CardTitle, IconWithHover, Subtitle } from '../Styled';
import { Video, VideoPlaylistData } from './VideoPlaylist.data';
import { PlayIcon } from '../assets';
import {
	Actions,
	Carousel,
	CarouselItemsWrapper,
	IconButton,
	isCarouselOverflown,
	scrollCarousel
} from '../Carousel';

interface Props {
	left: string;
}

export const VideoPlaylist = ({ left }: Props) => {
	const [modalIsOpen, setModalIsOpen] = useState(true);
	const [activeVideo, setActiveVideo] = useState<Video | null>(null);
	const [showGetStartedActions, setShowGetStartedActions] = useState(false);
	const [getStartedCarouselPosition, setGetStartedCarouselPosition] = useState({
		isLeft: true,
		isRight: false
	});

	const setActionsVisibility = useDebounce(() => {
		setShowGetStartedActions(isCarouselOverflown('video-playlist-carousel'));
	}, 250);

	useEffect(() => {
		setActionsVisibility();
		window.addEventListener('resize', setActionsVisibility);
		return () => window.removeEventListener('resize', setActionsVisibility);
	}, []);

	const handleActivateModal = (video: Video) => {
		setModalIsOpen(true);
		setActiveVideo(video);
	};

	const handleDeactivateModal = () => {
		setModalIsOpen(false);
		setActiveVideo(null);
	};

	return (
		<Playlist id={'video-playlist'} left={left}>
			<Flex alignItems={'flex-end'} justifyContent={'space-between'}>
				<Flex flexDirection={'column'}>
					<CardTitle>{t`VIDEO LIBRARY`}</CardTitle>
					<Subtitle>{t`Learn more about Flexera One`}</Subtitle>
				</Flex>
				{showGetStartedActions && (
					<Actions>
						<IconButton
							id={'scroll-left'}
							title={'Scroll Left'}
							p={'xxs'}
							type={'button'}
							zIndex={1}
							variant={'secondary'}
							onClick={() =>
								scrollCarousel('video-playlist-carousel', 'left', (data) => {
									setGetStartedCarouselPosition(data);
								})
							}
							disabled={getStartedCarouselPosition.isLeft}
						>
							<Icon src={IconMdArrowLeft} />
						</IconButton>
						<IconButton
							id={'scroll-right'}
							title={'Scroll right'}
							p={'xxs'}
							type={'button'}
							zIndex={1}
							variant={'secondary'}
							onClick={() =>
								scrollCarousel('video-playlist-carousel', 'right', (data) => {
									setGetStartedCarouselPosition(data);
								})
							}
							disabled={getStartedCarouselPosition.isRight}
						>
							<Icon src={IconMdArrowRight} />
						</IconButton>
					</Actions>
				)}
			</Flex>
			<Carousel id={'video-playlist-carousel'}>
				<CarouselItemsWrapper isStacked>
					{VideoPlaylistData.map((video, index) => (
						<VideoFrame
							key={video.id}
							tabIndex={0}
							className={' playButton'}
							onClick={() => handleActivateModal(video)}
							aria-haspopup
							aria-label={'View modal'}
							aria-expanded={modalIsOpen}
							title={`Play ${video.title} via YouTube`}
							id={`learn-more-video-play-button-${index}`}
						>
							<Thumbnail src={video.thumbnail} alt={video.title} />
							<IconWithHover src={PlayIcon} />
						</VideoFrame>
					))}
				</CarouselItemsWrapper>
			</Carousel>
			{modalIsOpen && activeVideo && (
				<Modal
					width={'600px'}
					title={activeVideo.title}
					zIndex={1001}
					id={'video-playlist-modal'}
					isOpen={modalIsOpen}
					setIsOpen={setModalIsOpen}
					onClose={handleDeactivateModal}
				>
					<ModalContent>
						<iframe
							title={'modal-iframe'}
							width={'536'}
							height={'356'}
							src={`${activeVideo.url}?rel=0`}
							allow={
								'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							}
							frameBorder={'0'}
							allowFullScreen
						/>
						<p>{activeVideo.description}</p>
					</ModalContent>
					<ModalFooter>
						<Button
							type={'button'}
							id={'video-playlist-modal-close'}
							title={'close modal'}
							onClick={handleDeactivateModal}
							variant={'outline'}
						>
							{t`Close`}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</Playlist>
	);
};
