// localStorage

import { retrieveItem, storeItem } from '@flexera/lib.setting-store';

interface PreferencePair {
	[key: string]: string | boolean | Record<string, unknown>;
}

/**
 * Return the current preferences storage key
 */
export const getAllPreferences = async () => {
	const preferences = await retrieveItem({ key: 'preferences' });
	return preferences;
};

/**
 * Gets preference by key
 * @param preferenceKey
 */
export const getPreference = async (preferenceKey: string) => {
	const preferences = await getAllPreferences();
	if (preferences && preferenceKey in preferences) {
		return preferences[preferenceKey];
	}
	return undefined;
};

/**
 * Sets or updates the preference
 * @param preference
 */
export const setPreference = (preference: PreferencePair) => {
	const existingPreferences = getAllPreferences();
	storeItem({
		key: 'preferences',
		data: {
			...existingPreferences,
			...preference
		}
	});
};
