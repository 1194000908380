const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add a SaaS integration": {
        "msgid": "Add a SaaS integration",
        "msgstr": [
          "SaaS-Integration hinzufügen"
        ]
      },
      "Add your team and assign roles.": {
        "msgid": "Add your team and assign roles.",
        "msgstr": [
          "Fügen Sie Ihr Team hinzu und weisen Sie Rollen zu."
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Verwaltung"
        ]
      },
      "Automate governance across your multi-cloud environment.": {
        "msgid": "Automate governance across your multi-cloud environment.",
        "msgstr": [
          "Automatisieren Sie die Governance in Ihrer Multi-Cloud-Umgebung."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisierung"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Connect with customers, experts, and thought leaders to find answers and guidance": {
        "msgid": "Connect with customers, experts, and thought leaders to find answers and guidance",
        "msgstr": [
          "Nehmen Sie Kontakt zu Kunden, Experten und Vordenkern auf, um Antworten und Ratschläge zu erhalten."
        ]
      },
      "Connect your cloud bills": {
        "msgid": "Connect your cloud bills",
        "msgstr": [
          "Ihre Cloud-Rechnungen verbinden"
        ]
      },
      "Deploy beacons and start collecting asset data.": {
        "msgid": "Deploy beacons and start collecting asset data.",
        "msgstr": [
          "Richten Sie Beacon-Server ein und beginnen Sie mit der Erfassung von Asset-Daten."
        ]
      },
      "Documentation Library": {
        "msgid": "Documentation Library",
        "msgstr": [
          "Online-Dokumentation"
        ]
      },
      "Explore Automation": {
        "msgid": "Explore Automation",
        "msgstr": [
          "Automatisierung erkunden"
        ]
      },
      "Flexera One Forum": {
        "msgid": "Flexera One Forum",
        "msgstr": [
          "Flexera-One-Forum"
        ]
      },
      "Get Started": {
        "msgid": "Get Started",
        "msgstr": [
          "Erste Schritte"
        ]
      },
      "Hello ${ 0 }": {
        "msgid": "Hello ${ 0 }",
        "msgstr": [
          "Hallo ${ 0 }"
        ]
      },
      "Helpful Links": {
        "msgid": "Helpful Links",
        "msgstr": [
          "Hilfreiche Links"
        ]
      },
      "IT Asset Management": {
        "msgid": "IT Asset Management",
        "msgstr": [
          "IT Asset Management"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "Inventory Beacons": {
        "msgid": "Inventory Beacons",
        "msgstr": [
          "Beacon-Server"
        ]
      },
      "Invite users to Flexera One": {
        "msgid": "Invite users to Flexera One",
        "msgstr": [
          "Nutzer zu Flexera One einladen"
        ]
      },
      "JOIN US TODAY": {
        "msgid": "JOIN US TODAY",
        "msgstr": [
          "MELDEN SIE SICH HEUTE NOCH AN"
        ]
      },
      "Learning Center": {
        "msgid": "Learning Center",
        "msgstr": [
          "Lerncenter"
        ]
      },
      "Manage access to Flexera One using your identity provider.": {
        "msgid": "Manage access to Flexera One using your identity provider.",
        "msgstr": [
          "Verwalten Sie den Zugang zu Flexera One über Ihren Identitätsanbieter."
        ]
      },
      "Open A New Case": {
        "msgid": "Open A New Case",
        "msgstr": [
          "Ein Support Ticket erstellen"
        ]
      },
      "Product Discovery": {
        "msgid": "Product Discovery",
        "msgstr": [
          "Produkterkennung"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "Set up device discovery": {
        "msgid": "Set up device discovery",
        "msgstr": [
          "Geräteerkennung einrichten"
        ]
      },
      "Set up your identity provider": {
        "msgid": "Set up your identity provider",
        "msgstr": [
          "Identitätsanbieter einrichten"
        ]
      },
      "Start importing bill data from your cloud provider.": {
        "msgid": "Start importing bill data from your cloud provider.",
        "msgstr": [
          "Beginnen Sie mit dem Import von Rechnungsdaten von Ihrem Cloud-Anbieter."
        ]
      },
      "Submit Ideas": {
        "msgid": "Submit Ideas",
        "msgstr": [
          "Ideen einsenden"
        ]
      },
      "Support Help": {
        "msgid": "Support Help",
        "msgstr": [
          "Support-Hilfe"
        ]
      },
      "Support Plan Information": {
        "msgid": "Support Plan Information",
        "msgstr": [
          "Informationen zum Support-Plan"
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Technologie-Ausgaben"
        ]
      },
      "Using Flexera One APIs": {
        "msgid": "Using Flexera One APIs",
        "msgstr": [
          "Flexera-One-APIs verwenden"
        ]
      },
      "Visit the community": {
        "msgid": "Visit the community",
        "msgstr": [
          "Besuchen Sie unsere Community "
        ]
      },
      "You do not have access to any products within this organization": {
        "msgid": "You do not have access to any products within this organization",
        "msgstr": [
          "Sie haben keinen Zugang zu Produkten in diesem Unternehmen"
        ]
      },
      "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.": {
        "msgid": "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.",
        "msgstr": [
          "Sie haben nicht die richtigen Berechtigungen, um diese Seite anzuzeigen. Bitte wenden Sie sich an Ihren Flexera-Administrator, um die richtigen Berechtigungen anzufordern."
        ]
      }
    }
  }
};
export default json;
