import styled from 'styled-components';
import { BaseTheme, Flex, Link } from '@flexera/ui.component-library';

export const HelpfulLinkSection = styled(Flex)`
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 3rem;
`;

export const HelpfulLinkWapper = styled(Flex)`
	@media screen and (max-width: ${BaseTheme.breakpoints[0]}px) {
		border-left: none;
	}
`;

export const HelpfulLink = styled(Link)`
	font-family: ${BaseTheme.fonts.secondary};
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.375rem;
	letter-spacing: 0.2px;
	box-sizing: border-box;
	margin: 0 ${BaseTheme.space.sm} ${BaseTheme.space.sm} 0;

	svg {
		transform: scale(1);
		margin-left: ${BaseTheme.space.sm};
	}

	&:focus {
		outline: 0;

		> div {
			border: ${BaseTheme.borders.md} ${BaseTheme.colors.primaryActive};
			padding: ${BaseTheme.space.sm} 23px;
		}
	}

	&:hover {
		text-decoration: none;
		> div {
			border: ${BaseTheme.borders.sm} ${BaseTheme.colors.primaryHover};
		}
	}

	&:active {
		> div {
			border: ${BaseTheme.borders.md} ${BaseTheme.colors.primaryActive};
			padding: ${BaseTheme.space.sm} 23px;
		}
	}
`;
