import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const UOMPowerBiDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/uom.main');
		return { default: (await mod).UOMPowerBiDashboard };
	}),
	'uom'
);
