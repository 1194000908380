const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "All Contracts (Beta)": {
        "msgid": "All Contracts (Beta)",
        "msgstr": [
          "Alle Verträge (Beta)"
        ]
      },
      "Contract Details": {
        "msgid": "Contract Details",
        "msgstr": [
          "Vertragsdetails"
        ]
      },
      "New Contract (Beta)": {
        "msgid": "New Contract (Beta)",
        "msgstr": [
          "Neuer Vertrag (Beta)"
        ]
      }
    }
  }
};
export default json;
