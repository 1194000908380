import React from 'react';
import { NavItemChild } from '@flexera/shell.navigation';

interface NavigationContext {
	isExpanded: boolean;
	setIsExpanded: (x: boolean) => void;
	favoritesMenu: NavItemChild[] | void | null;
	searchIsActive: boolean;
	setSearchIsActive: (x: boolean) => void;
	isPinned: boolean;
	setIsPinned: (x: boolean) => void;
	setPinnedItem: (x: string) => void;
	resizerActive: boolean;
	setResizerActive: (x: boolean) => void;
	navWrapperRef: React.Ref<HTMLElement>;
	orgId: string;
	savedFavorites: FavoriteItem[];
	setSavedFavorites: (favorites: FavoriteItem[]) => void;
	hasFlyout: boolean;
	setHasFlyout: (hasFlyout: boolean) => void;
}

export const NavigationContext = React.createContext<NavigationContext>(null);
