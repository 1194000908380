import { NavProvider } from '@flexera/shell.navigation';
import React, { FC } from 'react';
import { SetupNavMenu } from './SetupNavMenu';

export const Nav: FC = ({ children }) => (
	<NavProvider>
		<SetupNavMenu />
		{children}
	</NavProvider>
);
