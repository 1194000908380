import { createGlobalStyle } from 'styled-components';
import { SourceSansPro } from './source-sans';

/**
 * Primary font distributed for use in Flexera apps, exported as a GlobalStyleComponent
 * for easy injection at an application's root.
 */
export const PrimaryFont = createGlobalStyle`${SourceSansPro}`;

/**
 * Secondary font distributed for use in Flexera apps, exported as a GlobalStyleComponent
 * for easy injection at an application's root.
 */
export const SecondaryFont = createGlobalStyle`${SourceSansPro}`;

/**
 * Bundled primary and secondary fonts distributed for use in Flexera apps, exported
 * as a GlobalStyleComponent for easy injection at an application's root.
 */
export const FlexeraFonts = createGlobalStyle`
    ${SourceSansPro}
`;
